import {
	BaseStoreAssets,
	EntityStoreAssets,
	createBaseStoreAssets,
	createEntityAdapterStoreAssets,
	createStoreAssets,
	NgxStore
} from '@studiohyperdrive/ngx-store';

import { FacetEntity, FacetFilter } from '@vlaio/shared/types';

export type FacetsStoreAssets = NgxStore<FacetsStoreSliceType>;

type FacetsStoreSliceType = {
	facets: EntityStoreAssets<FacetEntity>;
	selectedFacets: BaseStoreAssets<FacetFilter>;
};

/**
 * Generates a facet store assets
 *
 * @param slice - The slice we wish to save the assets to
 */
export const createFacetStoreAssets = (slice: string): FacetsStoreAssets => {
	return createStoreAssets<FacetsStoreSliceType>(slice, [
		{
			subSlice: 'facets',
			generator: createEntityAdapterStoreAssets<FacetEntity>
		},
		{
			subSlice: 'selectedFacets',
			generator: createBaseStoreAssets<FacetFilter>
		}
	]);
};
