import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ObservableArray } from '@studiohyperdrive/rxjs-utils';
import { combineLatest, of } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

import { environment } from 'environments';

import { AbstractTranslationProviderService } from '../abstracts';

import { BrowserService } from './browser.service';

export interface MetaSettings {
	title?: string;
	description?: string;
}

@Injectable()
export class MetaService {
	constructor(
		private readonly titleService: Title,
		private readonly metaService: Meta,
		private readonly translationProviderService: AbstractTranslationProviderService,
		private readonly browserService: BrowserService
	) {}

	/**
	 * Sets a meta data tag on the current HTML document
	 *
	 * @param name - Name of the tag
	 * @param value - Value of the tag
	 */
	private setTag(name: string, content: string): void {
		if (this.metaService.getTag(name)) {
			this.metaService.removeTag(name);
		}

		this.metaService.addTag({ name, content });
	}

	/**
	 * Updates the current HTML document meta data based on translations
	 *
	 * @param meta - Title and description of HTML document
	 */
	public updateMetaDataWithTranslations(meta: MetaSettings): ObservableArray<string> {
		// Bram: Early exit if meta does note exist
		if (!meta) {
			return;
		}

		// Iben: Set translations
		return combineLatest([
			meta.title ? this.translationProviderService.getTranslation(meta.title) : of(''),
			meta.description ? this.translationProviderService.getTranslation(meta.description) : of('')
		]).pipe(
			filter<[string, string]>((result) => Boolean(result[0]) && Boolean(result[1])),
			take(1),
			tap(([title, description]) => {
				this.updateMetaData({ title, description });
			})
		);
	}

	/**
	 * Updates the current HTML document meta data
	 *
	 * @param meta - Title and description of HTML document
	 */
	public updateMetaData(meta: MetaSettings): void {
		if (!meta) {
			return;
		}

		const { title, description } = meta;

		this.titleService.setTitle(`${title} - ${environment.metaDomain}`);
		this.setTag('description', description);
	}

	/**
	 * Adds a canonical url to the dom
	 */
	public addCanonicalLink(): void {
		this.browserService.runInBrowser(({ browserDocument }) => {
			// Iben: Construct canonical url
			const url = browserDocument.URL;

			// Iben: Create link element if not existing, or replace current one
			const link =
				browserDocument.querySelectorAll('[rel="canonical"]')[0] || browserDocument.createElement('link');
			link.setAttribute('rel', 'canonical');
			link.setAttribute('href', url);

			// Iben: Append element to dom
			browserDocument.head.appendChild(link);
		});
	}

	/**
	 *	Add meta tag to the head of the DOM
	 *
	 * @param name Name of the tag
	 * @param content Value of the tag
	 */
	public addTag(name: string, content: string): void {
		this.setTag(name, content);
	}

	/**
	 * Remove a tag by name
	 *
	 * @param name Name of the tag to be removed
	 */
	public removeTagByName(name: string): void {
		this.metaService.removeTag(`name="${name}"`);
	}
}
