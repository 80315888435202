/**
 * The Dutch entity of the fetched data from the API.
 */
export interface PartnerResultEntity {
	naam: string;
	id: string;
	aantalProducten: number;
	website: string;
	niveau: string;
	niveauLabel: string;
	isBeschikbaarInHetAanbod: boolean;
	isBeschikbaarInAanbevelingenOpMaat: boolean;
	isBeschikbaarInLopendeAanvragen: boolean;
	isDigitaleAanvraag?: boolean;
	isDossieropvolging?: boolean;
	isHeaderIntegration?: boolean;
}

/**
 * The translated entity of the fetched data from the API.
 */
export interface PartnerEntity {
	name: string;
	id: string;
	amountOfProducts: number;
	level: PartnerLevelEnum;
	levelLabel: string;
	url: string;
	isAvailableIn: {
		cases: boolean;
		products: boolean;
		recommendations: boolean;
	};
	isAtLeastAvailableInOne: boolean;
	isDigital?: boolean;
	isCaseMonitoring?: boolean;
	isHeaderIntegration?: boolean;
}

/**
 * The type used to store and display an array of partners by their PartnerLevel as key.
 */
export type PartnersByLevelEntity = Partial<Record<PartnerLevelEnum, PartnerEntity[]>>;

/**
 * The possible governing bodies assigned to the products.
 */
export enum PartnerLevelEnum {
	Local = 'local',
	Flemish = 'flemish',
	Belgian = 'belgian',
	Federal = 'federal',
	European = 'european',
	Other = 'other',
	ProviderFull = 'service_provider',
	Provider = 'provider'
}
