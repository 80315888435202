import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { ProductPartnerEntity } from '@vlaio/shared/types';

@Component({
	selector: 'vlaio-product-partner',
	templateUrl: './product-partner.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class ProductPartnerComponent {
	@HostBinding('class') private readonly rootClass = 'c-product__partner';
	@Input() public set partners(partners: Omit<ProductPartnerEntity, 'url'>[]) {
		if (partners.length === 0) {
			this.stringifiedPartners = 'Geen partners';
			return;
		}

		this.stringifiedPartners = partners.map((partner) => partner.name).join(' & ');
	}

	public stringifiedPartners: string;
}
