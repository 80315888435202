<vlaio-detail-content cypressTitleTag="Permits.Title">
	<header *mediaQuery="mediaQueryMax.Desktop">
		{{ permit().title }}

		@if (!readOnly && permit().isRenewable) {
			<small class="u-block u-margin-top">
				<vlaio-icon icon="fa-triangle-exclamation" />
				<strong>
					{{ i18nKeys.Permits.Table.isRenewable | translate }}
				</strong>
			</small>
		}
	</header>

	<section>
		@if (permit().description) {
			<vlaio-value-tile cypressTag="Permits.Description">
				<ng-container content>
					{{ permit().description }}
				</ng-container>
			</vlaio-value-tile>

			<!-- Wouter: Always display a rule when a description is available -->
			<hr />
		} @else {
			<!-- Wouter: If no description is available, only display the rule when the detail is a card. -->
			<hr *mediaQuery="mediaQueryMax.Desktop" class="no-margin-top" />
		}

		@if (view === 'all') {
			<div class="date-containers">
				<vlaio-value-tile cypressTag="Permits.Dates.Start">
					<ng-container title>{{ i18nKeys.Permits.Card.Dates.Start | translate }}</ng-container>
					<ng-container content>
						@if (permit().status.startDate) {
							{{ permit().status.startDate | date }}
						} @else {
							<i>{{ i18nKeys.Common.Empty.NoData | translate }}</i>
						}
					</ng-container>
				</vlaio-value-tile>

				<vlaio-value-tile cypressTag="Permits.Dates.End">
					<ng-container title>{{ i18nKeys.Permits.Card.Dates.End | translate }}</ng-container>
					<ng-container content>
						@if (permit().status.endDate) {
							{{ permit().status.endDate | date }}
						} @else {
							<i>{{ i18nKeys.Common.Empty.NoEndDate | translate }}</i>
						}
					</ng-container>
				</vlaio-value-tile>

				@if (!readOnly) {
					<vlaio-value-tile cypressTag="Permits.Dates.Renewal">
						<ng-container title>{{ i18nKeys.Permits.Card.Dates.Renewal | translate }}</ng-container>
						<ng-container content>
							@if (permit().status.renewalDate) {
								{{ permit().status.renewalDate | date }}
							} @else {
								<i>{{ i18nKeys.Permits.Card.Dates.NoRenewal | translate }}</i>
							}
						</ng-container>
					</vlaio-value-tile>
				}
			</div>

			@if (permit().assignedBy) {
				<vlaio-partner-detail
					cypressTag="Permits.AssignedBy"
					partner="{{ permit().assignedBy.name || permit().assignedBy.organizationCode }}"
					[contactItems]="permit().assignedBy | contactItemsMap"
				/>
			}
			<hr class="card-only no-margin-bottom" />
		} @else {
			<vlaio-value-tile>
				<ng-container title>
					{{ i18nKeys.Permits.Table.FileNumber | translate }}
				</ng-container>

				<ng-container content>
					@if (permit().referenceNumber) {
						{{ permit().referenceNumber }}
					} @else {
						<i>{{ i18nKeys.Common.Empty.NoData | translate }}</i>
					}
				</ng-container>
			</vlaio-value-tile>

			<vlaio-value-tile class="u-margin-top">
				<ng-container title>
					{{ i18nKeys.Permits.Table.RecentChange | translate }}
				</ng-container>

				<ng-container content>
					@if (permit().status.recentChangeDate) {
						{{ permit().status.recentChangeDate | date }}
					} @else {
						<i>{{ i18nKeys.Common.Empty.NoData | translate }}</i>
					}
				</ng-container>
			</vlaio-value-tile>
		}
	</section>

	@if (view === 'all') {
		<aside>
			<div class="value-tiles-wrapper">
				<!-- Wouter: Only display number in detail when in card view -->
				<vlaio-value-tile *mediaQuery="mediaQueryMax.Desktop" cypressTag="Permits.Number">
					<ng-container title>{{ i18nKeys.Permits.Table.FileNumber | translate }}</ng-container>
					<ng-container content>
						@if (permit().referenceNumber) {
							{{ permit().referenceNumber }}
						} @else {
							<i>{{ i18nKeys.Common.Empty.NoData | translate }}</i>
						}
					</ng-container>
				</vlaio-value-tile>

				<vlaio-value-tile cypressTag="Permits.Product">
					<ng-container title>{{ i18nKeys.Permits.Card.Product | translate }}</ng-container>
					<ng-container content>
						@if (permit().product) {
							{{ permit().product }}
						} @else {
							<i>{{ i18nKeys.Common.Empty.NoData | translate }}</i>
						}
					</ng-container>
				</vlaio-value-tile>

				<vlaio-value-tile cypressTag="Permits.Address">
					<ng-container title>{{ i18nKeys.Permits.Card.Address | translate }}</ng-container>
					<ng-container content>
						@if (addresses) {
							<vlaio-address format="multi-line" [address]="addresses[permit().assignedTo]" />
						} @else {
							<i>{{ i18nKeys.Common.Empty.NoData | translate }}</i>
						}
					</ng-container>
				</vlaio-value-tile>
			</div>

			@if (!readOnly) {
				<div class="button-wrapper u-margin-top">
					<vlaio-button
						cypressTag="Permits.Actions.Permit"
						icon="fa-up-right-from-square"
						layout="spread"
						[fullWidth]="true"
						[title]="
							(this.permit().referralUrl
								? i18nKeys.Permits.Card.PermitButton.Title
								: i18nKeys.Permits.Card.PermitButton.Disabled
							) | translate
						"
						[isDisabled]="!this.permit().referralUrl"
						(click)="handlePermitButtonClicked()"
					>
						{{ i18nKeys.Permits.Card.PermitButton.Text | translate }}
					</vlaio-button>

					<!-- Wouter: Only show the case button if a file number was provided -->
					@if (permit().status.fileNumber) {
						<vlaio-button
							*hasFeature="'Cases'"
							class="u-margin-top-sm"
							cypressTag="Permits.Actions.File"
							icon="fa-up-right-from-square"
							layout="spread"
							[fullWidth]="true"
							[title]="i18nKeys.Permits.Card.CaseButton.Title | translate"
							(click)="handleCaseButtonClicked()"
						>
							{{ i18nKeys.Permits.Card.CaseButton.Text | translate }}
						</vlaio-button>
					}

					<!-- Wouter: Only show the renewal button if a file number was provided -->
					@if (permit().status.renewalUrl) {
						<vlaio-button
							class="u-margin-top-sm"
							cypressTag="Permits.Actions.Renewal"
							icon="fa-up-right-from-square"
							layout="spread"
							[fullWidth]="true"
							[title]="i18nKeys.Permits.Card.RenewalButton.Title | translate"
							(click)="handleRenewalButtonClicked()"
						>
							{{ i18nKeys.Permits.Card.RenewalButton.Text | translate }}
						</vlaio-button>
					}
				</div>
			}
		</aside>
	}
</vlaio-detail-content>
