import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
	AbstractStatusProviderService,
	AppStatusEntity,
	AppStatusResultEntity,
	VlaioHttpClientService
} from '@vlaio/shared/core';
import { ELoketEndpoints } from '@vlaio/shared/endpoints';

@Injectable()
export class StatusProviderService extends AbstractStatusProviderService {
	constructor(private readonly httpClient: VlaioHttpClientService) {
		super();
	}
	public getApplicationStatus(): Observable<AppStatusEntity> {
		return this.httpClient.get<AppStatusResultEntity>(ELoketEndpoints.Status.Status()).pipe(
			map((result) => {
				// Iben: If the call succeeds, the application is available
				return {
					isActive: !result.onderhoudsmodus,
					message: result.onderhoudsmodusBericht
				};
			})
		);
	}
}
