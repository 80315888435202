import { FormControl } from '@angular/forms';
import { isValid, format, isBefore } from 'date-fns';

interface InvalidDateEntity {
	invalidMinimumDate: {
		minimumDate: string;
		date: string;
		dateFormat: string;
	};
}
/**
 * Validate if the value is set **after** a certain minimum date.
 * Returns an error if this condition is not met.
 *
 * @param minDate The minimum date set for the value of the control
 *
 * @param dateFormat The format to compare the dates. Default is set to
 * `yyyy-MM-dd`
 *
 * @returns An object with the value of the control and the minimum date
 * if an error occured or `null` if the conditions are met.
 */
export function minimumDateValidator(
	minDate: string,
	dateFormat: string = 'yyyy-MM-dd'
): (control: FormControl<string>) => InvalidDateEntity | null {
	return (control: FormControl<string>) => {
		/**
		 * The value of the formControl
		 */
		const controlDate: string = control.value;

		/**
		 * The error that will be returned if any condition is
		 * not met
		 */
		const error: InvalidDateEntity = {
			invalidMinimumDate: {
				minimumDate: minDate,
				date: controlDate,
				dateFormat
			}
		};

		// If the value is unset, early return to allow optional fields
		// and to stay within the scope of the validator
		if (!controlDate) {
			return null;
		}

		// Error if minValue is empty
		if (!minDate) {
			return error;
		}

		/**
		 * The date passed as value of the control, formatted to
		 * the desired dateformat
		 */
		let formatDate: string;
		/**
		 * The minimum date, formatted to the desired dateformat
		 */
		let formatMinDate: string;

		// Error if value is not a valid date
		// Error if minValue is not a valid date
		try {
			formatDate = format(new Date(controlDate), dateFormat);
			formatMinDate = format(new Date(minDate), dateFormat);
		} catch (err: unknown) {
			return error;
		}

		if (!isValid(new Date(formatDate)) || !isValid(new Date(formatMinDate))) {
			return error;
		}

		// Error if value is before the minValue (in time)
		if (isBefore(new Date(formatDate), new Date(formatMinDate))) {
			return error;
		}

		return null;
	};
}
