import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxCookieService } from '@studiohyperdrive/ngx-cookies';
import { NgxI18nRootService } from '@studiohyperdrive/ngx-i18n';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { isNumber } from 'lodash';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { authenticationCookie, savedKBOCookie, spotlightProductsCallbackQueryParam } from '@vlaio/shared/cookies';
import { BrowserService } from '@vlaio/shared/core';
import { ELoketEndpoints } from '@vlaio/shared/endpoints';
import { AppRoutePaths } from '@vlaio/shared/route-paths';
import { ACMTargetGroups } from '@vlaio/shared/types';
import { isValidHint } from '@vlaio/shared/utils';
import { environment, EnvironmentType } from 'environments';

import { AuthenticationFailedTypes } from '../enums';
import { LoginAsEconomicActorOptionsEntity, LoginOptionsEntity, SessionChangeEntity } from '../interfaces';

import { AuthenticationApiService } from './authentication.api.service';
import { NgxBroadcastChannelService } from './broadcastchannel.service';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {
	/**
	 * Subject to store the type of authentication failure.
	 */
	private readonly authenticationFailedTypeSubject$ = new BehaviorSubject<AuthenticationFailedTypes | undefined>(
		undefined
	);

	/**
	 * When the session has been fetched
	 */
	private sessionFetchedSubject: Subject<number> = new Subject<number>();

	/**
	 * The broadcast channel token
	 */
	private readonly acmSessionToken: string = 'vlaio-acm-session';

	/**
	 * Observable to check if the user is authenticated at the moment.
	 */
	public readonly isAuthenticated$: ObservableBoolean = this.cookieService
		.getCookieObservable<number | undefined>(authenticationCookie)
		.pipe(
			map((timestamp) => {
				// Wouter: If the timestamp is not set, the user is not authenticated
				if (!timestamp) {
					return false;
				}

				// Wouter: If the current time is less than the expiration date of the cookie, the user is authenticated
				return new Date().getTime() <= timestamp;
			})
		);

	/**
	 * Observable to check whether and how the authentication failed.
	 */

	public readonly authenticationFailed$: Observable<AuthenticationFailedTypes | undefined> =
		this.authenticationFailedTypeSubject$.asObservable();

	constructor(
		private readonly apiService: AuthenticationApiService,
		private readonly i18nRootService: NgxI18nRootService,
		private readonly cookieService: NgxCookieService,
		private readonly browserService: BrowserService,
		private readonly broadcastService: NgxBroadcastChannelService
	) {
		// Iben: Create a channel where we listen to the messages from the broadcast channel.
		// We only listen to the previous messages if they are less than 5 minutes old
		this.broadcastService.initChannel(Infinity, 300000, this.acmSessionToken);
	}

	/**
	 * Get whether the user is authenticated
	 *
	 * @deprecated Use `isAuthenticated$` observable instead
	 */
	get authenticated(): boolean {
		const cookieTimestamp: number = this.cookieService.getCookie<number>(authenticationCookie);

		// Wouter: If the timestamp is not set, the user is not authenticated
		if (!cookieTimestamp) {
			return false;
		}

		// Wouter: If the current time is less than the expiration date of the cookie, the user is authenticated
		return new Date().getTime() <= cookieTimestamp;
	}

	/**
	 * Remove all authentication cookies
	 */
	public dropAuthentication() {
		// Iben: Remove the cookies
		this.cookieService.removeCookie(authenticationCookie);
		this.cookieService.removeCookie(savedKBOCookie);
	}

	public logout() {
		// Clear the cookies before requesting logout on the server
		this.dropAuthentication();

		// Kaat: Reset the authenticated failed state
		this.authenticationFailedTypeSubject$.next(undefined);

		return this.apiService.logOut();
	}

	/**
	 * Login the user using the ACM login flow
	 *
	 * @param options - Options we wish to use to log in the user
	 */
	public login(options: LoginOptionsEntity) {
		// Iben: Split the options into separate variables
		const { spotlightProducts, customCallBack, capHint } = options;

		// Brecht: protocol + host
		const baseUrl: string = `${environment.acmidm.protocol}://${environment.acmidm.hostname}`;
		// Brecht: loginPath
		const loginPathUrl: string = `${environment.acmidm.loginPath}`;

		// Wouter: Construct the base callback url
		const callbackUrl = customCallBack.url || `/${this.i18nRootService.currentLanguage}/${AppRoutePaths.Loket}`;

		// Wouter: Specify the query parameters for the callback property in the login url
		const callbackQueryParams: Record<string, any> = {
			// Wouter: allow to add custom callback params
			...(options.customCallBack?.params || {}),
			// Iben: Add the auth success param
			remoteAuthSuccess: true,
			// Iben: If a spotlight product was passed, we add them
			...(spotlightProducts ? { [spotlightProductsCallbackQueryParam]: spotlightProducts } : {})
		};

		// Wouter: Since the callback query parameters are an object, we need to convert them to a string
		const callbackQueryParamsString = Object.entries(callbackQueryParams)
			.map(([key, value]) => `${key}=${value}`)
			.join('&');

		// Wouter: Construct the query parameters for the login url
		const uriQueryParams: Record<string, string> = {
			// Wouter: Encode the callback url as to regard it as the value of the callback param.
			// Otherwise, they would all be registered as separate query parameters
			callback: encodeURIComponent(`${callbackUrl}?${callbackQueryParamsString}`),
			// Iben: If we are in the local application, we attach the redirect param
			...this.getLocalRedirectRecord()
		};

		// Iben: If there's a code hint, we add it to the queryParams
		if (capHint) {
			const loginHint = JSON.stringify(
				// TODO: Iben: Find better way to type this to prevent the cast
				capHint === ACMTargetGroups.BUR
					? { cap_hint: capHint }
					: { cap_hint: capHint, code_hint: (options as LoginAsEconomicActorOptionsEntity).codeHint }
			);

			if (isValidHint(btoa(loginHint))) {
				// Brecht: base64 encode loginHint
				uriQueryParams['login_hint'] = btoa(loginHint);
			}
		}

		// Iben: Login the user when the user is not logged in, else log-out the user and login again
		// with the correct queryParams as directed-switch does not exist
		return this.isAuthenticated$.pipe(
			take(1),
			switchMap((isAuthenticated) => {
				return isAuthenticated ? this.logout() : of(isAuthenticated);
			}),
			tap(() => {
				this.redirectToLogin(uriQueryParams, loginPathUrl, baseUrl);
			}),
			catchError((err: HttpErrorResponse) => {
				/* Wouter: If the user has forcefully quit the entire browser session, the authenticationCookie
				 * will not have been cleared. In this scenario, there is no way of knowing if the user is still
				 * authenticated or not. We assume the user is still signed in, but the logout call will always
				 * fail. In this case, we redirect to ACM login page either way.
				 */
				if (err.url.includes(ELoketEndpoints.Authentication.Logout()) && err.status === 403) {
					this.redirectToLogin(uriQueryParams, loginPathUrl, baseUrl);
				}
				return of(false);
			})
		);
	}

	/**
	 * Log out with EID
	 */
	public logoutEID() {
		// Iben: Drop authentication cookies
		this.dropAuthentication();

		// Kaat: Reset the authenticated failed state
		this.authenticationFailedTypeSubject$.next(undefined);

		return this.apiService.logOutWithEid(this.getLocalRedirectUrl());
	}

	/**
	 * Redirect the user to the login page with the required query parameters
	 *
	 * @param uriQueryParams A Record of encoded URI query parameters to pass to the login page
	 * @param loginPathUrl The domain of the ACM login page that is dependent on the environment
	 * @param baseUrl The base URL of the ACM login page that is dependent on the environment
	 */
	public redirectToLogin(uriQueryParams: Record<string, string>, loginPathUrl: string, baseUrl: string) {
		this.browserService.runInBrowser(({ browserWindow }) => {
			// Wouter: Construct the full login url. No need to encode the query parameters, as
			// the `encodeURIComponent` function already did this and should not be encoded twice.
			const queryParams = Object.entries(uriQueryParams)
				.map(([key, value]) => `${key}=${value}`)
				.join('&');

			browserWindow.location.href = `${baseUrl}${loginPathUrl}?${queryParams}`;
		});
	}

	/**
	 * Set authentication cookie for the user
	 *
	 * @param value - The timestamp when the cookie has expired
	 */
	public setAuthenticationCookie(value: number): void {
		// Wouter: All numbers are valid timestamps
		if (!isNumber(value)) {
			return;
		}

		// Iben: Set a cookie that expires when the Drupal Session cookie expires
		this.cookieService.setCookie({ name: authenticationCookie, value });
	}

	/**
	 * Returns the authenticated cookie
	 */
	public getAuthorizationCookie(): string {
		return this.cookieService.getCookie(authenticationCookie);
	}

	/**
	 * Indicate that the authentication process has failed
	 */
	public setAuthenticationFailed(failType: AuthenticationFailedTypes): void {
		this.authenticationFailedTypeSubject$.next(failType);
	}

	/**
	 * Returns the redirect url if needed
	 */
	public getLocalRedirectUrl(connector: '&' | '?' = '&'): string {
		// Iben: To connect with the dev environment, we pass the domain when we are in the local version of the app
		return environment.environment === EnvironmentType.LOCAL
			? `${connector}redirect_url=${environment.domain}`
			: '';
	}

	/**
	 * Notify a change in the session
	 *
	 * @param  change - The change to the session
	 */
	public sessionChanged(change: SessionChangeEntity): void {
		this.broadcastService.postMessage(this.acmSessionToken, change);
	}

	/**
	 * Notifies when there has been a change in the session of the user
	 */
	public get acmSessionChanged$(): Observable<SessionChangeEntity> {
		// Iben: Check if the session was already fetched
		return this.sessionFetchedSubject.asObservable().pipe(
			filter(Boolean),
			// Iben: Switch to the message, but only emit when there's an actual change in the session
			switchMap((date) => {
				return this.broadcastService.selectChannelMessages<SessionChangeEntity>(this.acmSessionToken).pipe(
					filter((message) => message.data.date !== date),
					map((message) => message.data)
				);
			})
		);
	}

	/**
	 * Sets the session as fetched
	 *
	 * @param  date - The date to the session
	 */
	public sessionFetched(date: number): void {
		this.sessionFetchedSubject.next(date);
	}

	/**
	 * Returns the redirect record if needed.
	 *
	 * @see {@link getLocalRedirectUrl}
	 *
	 * @returns An object that will contain a `redirect_url` parameter when using the local domain
	 */
	private getLocalRedirectRecord(): Record<string, string> {
		// Iben: To connect with the dev environment, we pass the domain when we are in the local version of the app
		if (environment.environment === EnvironmentType.LOCAL) {
			return {
				redirect_url: environment.domain
			};
		}

		return {};
	}
}
