import { ObservableArray, ObservableBoolean } from '@studiohyperdrive/rxjs-utils';

import { LeafletMarkerEntity, TileLayerConfig } from '../../types';

export abstract class LeafletService {
	/**
	 * Function that returns the leaflet markers for the leaflet map
	 */
	public abstract get leafletMarkers$(): ObservableArray<LeafletMarkerEntity>;

	/**
	 * Function that returns whether one or more markers is found in Walloons
	 */
	public abstract get hasWalloonsLocation$(): ObservableBoolean;

	/**
	 * Function that returns base layers
	 */
	public abstract get baseLayers$(): ObservableArray<TileLayerConfig>;

	/**
	 * Function that returns ortho layers
	 */
	public abstract get orthoLayers$(): ObservableArray<TileLayerConfig>;
}
