import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { StoreSlice } from '@vlaio/shared/store';
import { LeafletService } from '@vlaio/shared/types';

import { reducers } from './company.store';
import { CompanyApiService, CompanyLeafletService, CompanyService } from './services';

@NgModule({
	providers: [
		CompanyService,
		CompanyApiService,
		CompanyLeafletService,
		{ provide: LeafletService, useClass: CompanyLeafletService }
	],
	imports: [StoreModule.forFeature(StoreSlice.Company, reducers)]
})
export class CompanyDataModule {}
