import { Directive, OnInit } from '@angular/core';
import { ObservableArray } from '@studiohyperdrive/rxjs-utils';
import { of } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';

import { OnDestroyComponent } from './on-destroy.component';

@Directive()
export abstract class InitialLoadComponent<EntityInterface> extends OnDestroyComponent implements OnInit {
	/**
	 * Method to get the data source we need to listen to for an initial load
	 */
	protected abstract getDataSource(): ObservableArray<EntityInterface>;
	/**
	 * Method to load and populate the data source
	 */
	protected abstract loadData(): ObservableArray<EntityInterface>;

	/**
	 * Load the data if the source doesn't exist or is not yet populated
	 */
	private loadInitialData(): ObservableArray<EntityInterface> {
		return this.getDataSource().pipe(
			take(1),
			switchMap((data) => {
				if (!data || (data && data.length === 0)) {
					return this.loadData();
				}

				return of(data);
			}),
			takeUntil(this.destroyed$)
		);
	}

	public ngOnInit() {
		// Iben: Do an initial load of the data
		this.loadInitialData().subscribe();
	}
}
