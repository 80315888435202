import { PermitsEntity, PermitsSummaryEntity, RenewablePermitsEntity } from '../interfaces';

export const PermitsMock: PermitsEntity = {
	id: 'Referentie 001',
	assignedBy: {
		name: 'Andere voorbeeldorganisatie',
		organizationCode: '13',
		contactform: 'www.website.be/contact',
		email: 'mail@email.com',
		phone: '0123456789',
		postalCode: '9000',
		streetAndNumber: 'Straat 1',
		website: 'www.website.be',
		city: 'Gent'
	},
	assignedTo: '0481673913',
	createdAt: '2019-08-24T14:15:22Z',
	description:
		'Dit is een beschrijving van de toelating. Dit bewijst dat je effectief mag wat je zegt dat je mag doen, maar natuurlijk enkel zolang deze toelaging geldig is.',
	product: '1',
	isPublic: true,
	reference: 'Referentie 001',
	referenceNumber: '2',
	referralUrl: 'www.website.be/doorverwijzing',
	isNew: true,
	status: {
		endDate: '2026-01-01T00:00:00Z',
		fileNumber: 'E/20210123/546',
		recentChangeDate: '2019-09-06T14:15:22Z',
		renewalDate: '2026-01-02T00:00:00Z',
		startDate: '2021-01-01T00:00:00Z',
		renewalUrl: 'www.website.be/hernieuwen'
	},
	title: 'Toekenningsvoorbeeld'
};

export const PermitsSummaryMock: PermitsSummaryEntity = {
	lastChangeDateLink: 'link/naar/toelatingen/gesorteerd/op/wijzigingsdatum',
	newPermitsCount: 1,
	renewalLink: 'link/naar/te-hernieuwen/toelatingen',
	totalPermitsCount: 2,
	permits: [],
	renewablePermits: []
};

const { product, ...PermitsRest } = PermitsMock;

export const RenewablePermitsMock: RenewablePermitsEntity = {
	...PermitsRest,
	reference: 'referentie 001',
	requestedBy: 'aanvrager',
	id: 'referentie 001'
};
