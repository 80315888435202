<!-- Wouter: Only show buttons when the carrousel contains more than 1 item -->
@if (data.length > 1) {
	<button class="previous-btn u-outline" title="Previous" (click)="move('left')">
		<vlaio-icon icon="fa-chevron-left" />
	</button>
}

<drag-scroll #carrousel [scrollbar-hidden]="true">
	@for (item of data; track item) {
		<div class="c-carrousel-item" drag-scroll-item>
			<ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: item }">
			</ng-template>
		</div>
	}
</drag-scroll>

@if (data.length > 1) {
	<button class="next-btn u-outline" title="Next" (click)="move('right')">
		<vlaio-icon icon="fa-chevron-right" />
	</button>
}
