import { ChangeDetectionStrategy, Component, computed, input, InputSignal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { StringSignal } from '@studiohyperdrive/ngx-utils';

import { AddressEntity } from '@vlaio/shared/types';

import { I18nKeys } from '../../i18n';

@Component({
	selector: 'vlaio-address',
	template: `
		@if (computedAddress().length) {
			<address [innerText]="computedAddress()"></address>
		} @else {
			<i>
				{{ emptyLabel | translate }}
			</i>
		}
	`,
	styleUrls: ['./address.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [TranslateModule]
})
export class VlaioAddressComponent {
	/**
	 * The empty label in case no address is provided
	 */
	public readonly emptyLabel = I18nKeys.Common.Empty.Address;

	/**
	 * Whether or not we want to render the address in a multi-liner or one-liner mode
	 */
	public readonly format: InputSignal<'multi-line' | 'one-line'> = input('multi-line');

	/**
	 * The provided address.
	 *
	 * Default value is `undefined` to allow for the template to render the empty label.
	 */
	public readonly address: InputSignal<AddressEntity | undefined> = input();

	/**
	 * The computed value of the address. This allows the template to be freed from string literal logic.
	 */
	public readonly computedAddress: StringSignal = computed(() => {
		// Wouter: If there is no address, return an empty string
		if (!this.address()) {
			return '';
		}

		// Wouter: Extract the required properties from the address object
		const { street = '', number = '', box = '', zip = '', municipality = '' } = this.address();

		if (this.format() === 'multi-line') {
			return `${street} ${number}${box ? ` bus ${box}` : ''}\n${zip} ${municipality}`.trim();
		}

		return `${street} ${number}${box ? ` bus ${box}` : ''}${zip || municipality ? ', ' : ''} ${zip} ${municipality}`.trim();
	});
}
