import { CallToActionEntity, CallToActionEnum, CallToActionResult, CallToActionResultEnum } from '@vlaio/shared/types';

export const CallToActionEntityMock: CallToActionEntity = {
	name: 'Test',
	action: CallToActionEnum.MoreInfo,
	link: 'www.test.be',
	annotations: []
};

export const CallToActionResultMock: CallToActionResult = {
	naam: 'Test',
	actieLink: 'www.test.be',
	doelVanDeActie: CallToActionResultEnum.MeerInfoBekijken,
	annotaties: []
};
