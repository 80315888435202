import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { StoreService, dispatchDataToStore } from '@studiohyperdrive/ngx-store';
import { ObservableArray, ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { Observable } from 'rxjs';

import { FacetEntity, FacetFilter } from '@vlaio/shared/types';

import { FacetsStoreAssetsInjectionToken } from '../abstracts';
import { FacetsStoreAssets } from '../facets.store';

import { FacetsApiService } from './facets-api.service';

@Injectable()
export class FacetsService extends StoreService {
	/**
	 * Injectable store assets so they can be set through the forChild
	 */
	private readonly storeAssets: FacetsStoreAssets = inject(FacetsStoreAssetsInjectionToken);
	/**
	 * Api service to fetch the facets
	 */
	private readonly apiService: FacetsApiService = inject(FacetsApiService);

	/**
	 * Selectors needed for the facets store
	 */
	private readonly selectors = this.storeAssets.selectors;
	/**
	 * actions needed for the facets store
	 */
	private readonly actions = this.storeAssets.actions;

	/**
	 * Array of facets and their subjects
	 */
	public readonly facets$: ObservableArray<FacetEntity> = this.selectFromStore<FacetEntity[]>(this.selectors.facets);
	/**
	 * Loading state of the facets
	 */
	public readonly facetsLoading$: ObservableBoolean = this.selectLoadingFromStore(this.selectors.facets);
	/**
	 * Error state of the facets
	 */
	public readonly facetsError$: ObservableBoolean = this.selectErrorFromStore(this.selectors.facets);

	/**
	 * The selected facets in API compatible format
	 */
	public readonly selectedFacets$: Observable<FacetFilter> = this.selectFromStore<FacetFilter>(
		this.selectors.selectedFacets
	);

	constructor(public readonly store: Store) {
		super(store);
	}

	/**
	 * Dispatch the selected facets to the store
	 *
	 * @param payload - The selected facets
	 */
	public setSelectedFacets(payload: FacetFilter): void {
		this.store.dispatch(this.actions.selectedFacets.set({ payload }));
	}

	/**
	 * Fetch the facets from the api and dispatch them to the store
	 *
	 * @param filters - The selected facets
	 */
	public getFacets(filters: FacetFilter = {}, searchQuery: string = undefined): ObservableArray<FacetEntity> {
		return dispatchDataToStore<FacetEntity[]>(
			this.actions.facets,
			this.apiService.getFacets(filters, searchQuery),
			this.store
		);
	}
}
