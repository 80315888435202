import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { dispatchDataToStore } from '@studiohyperdrive/ngx-store';
import { pluck, ObservableBoolean, ObservableString } from '@studiohyperdrive/rxjs-utils';
import { UUID } from 'angular2-uuid';
import CryptoES from 'crypto-es';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';

import { AuthenticationService } from '@vlaio/shared/authentication/auth';
import { Features, FeatureService } from '@vlaio/shared/features';
import { UserTargetCodeEntityKeys, UserTargetCodeEntity } from '@vlaio/shared/types';
import { environment } from 'environments';

import { UserContextKey, UserEntity, UserMetaEntity, UserSessionEntity } from '../interfaces';
import { actions, selectors } from '../user.store';

import { UserCompanyPropertyEntity, UserMandateInfoEntity } from './../interfaces/user.interface';
import { UserApiService } from './user.api.service';

@Injectable()
export class UserService {
	/**
	 * Subject that holds whether the user context call has been made.
	 */
	private readonly userContextCallMadeSubject$ = new BehaviorSubject<boolean>(false);
	/**
	 * Subject that holds whether the user session has been created.
	 */
	private readonly userSessionCreatedSubject$ = new BehaviorSubject<boolean>(false);
	/**
	 * Subject that holds the user company hash.
	 */
	private readonly companyNumberHashSubject$ = new BehaviorSubject<string>('');

	/**
	 * The user fetched from the user session store.
	 */
	public readonly user$: Observable<UserEntity> = this.store.pipe(
		select(selectors.select),
		map((session) => (session ? session.user : null))
	);
	/**
	 * The metadata assigned to the current signed-in user.
	 */
	public readonly userMetaData$: Observable<UserMetaEntity> = this.store.pipe(
		select(selectors.select),
		filter<UserSessionEntity>(Boolean),
		map((session) => session.metaData || null)
	);
	public readonly userMandateInfo$: Observable<UserMandateInfoEntity> = this.store.pipe(
		select(selectors.select),
		filter<UserSessionEntity>(Boolean),
		map((session) => session.mandateInfo || null)
	);
	public readonly loading$: ObservableBoolean = this.store.pipe(select(selectors.selectLoading));
	/**
	 * The observable of whether the user context call was made.
	 */
	public readonly userContextCallMade$: ObservableBoolean = this.userContextCallMadeSubject$.asObservable();
	/**
	 * The observable of whether the user session was created.
	 */
	public readonly userSessionCreated$: ObservableBoolean = this.userSessionCreatedSubject$.asObservable();
	/**
	 * A random UUID.
	 */
	public readonly uuid: string = UUID.UUID();
	/**
	 * The observable of the company number hash.
	 */
	public readonly companyNumberHash$: ObservableString = this.companyNumberHashSubject$.asObservable();
	/**
	 * Whether the user is signed in as a company
	 */
	public readonly isCompany$: ObservableBoolean = this.user$.pipe(
		map((user) => user?.isCompany && !user?.isCivilian)
	);
	/**
	 * Whether the user is current a Legal Represenative (WV)
	 */
	public readonly isLegalRepresentative$: ObservableBoolean = this.user$.pipe(
		map((user) => user.company?.isLegalRepresentative)
	);
	/**
	 * Whether the user is signed in as an executor.
	 */
	public readonly isExecutor$: ObservableBoolean = this.store.pipe(
		select(selectors.select),
		map((userSession) => Boolean(Object.keys(userSession?.mandateInfo || {}).length))
	);

	constructor(
		private readonly apiService: UserApiService,
		private readonly store: Store,
		private readonly authenticationService: AuthenticationService,
		private readonly featureService: FeatureService
	) {}

	/**
	 * Create a new user session
	 */
	public createUserSession(): Observable<UserSessionEntity> {
		// Iben: Reset the userRefreshCall Observable
		this.userSessionCreatedSubject$.next(false);

		// Iben: Dispatch the user session to the store
		return dispatchDataToStore(
			actions,
			this.apiService.getUserSession().pipe(
				tap((session) => {
					// Wouter: Firstly, always set the features.
					const enabledFeatures = Object.keys(session.features).filter(
						(feature) => session.features[feature]
					);

					// Iben: Set the features
					this.featureService.setFeatures(enabledFeatures as Features[]);
				}),
				map((session) => {
					// Wouter: Return if the session is empty or if only the features were provided
					if (!Object.keys(session).length || (Object.keys(session).length === 1 && session.features)) {
						return;
					}

					return session;
				}),
				// Wouter: Set the user session.
				tap((session: UserSessionEntity) => {
					// Iben: If no session was included, early exit
					if (!session) {
						return;
					}

					// Iben: Let the application know that the user session create call has happened
					this.userSessionCreatedSubject$.next(true);

					// Iben: Set the session cookie
					this.authenticationService.setAuthenticationCookie(session.cookie.expiresAt);

					// Iben: Set active company number hash
					if (session.user.company) {
						this.companyNumberHashSubject$.next(
							CryptoES.SHA3(session.user.company.number, { outputLength: 224 }).toString()
						);
					}
				}),
				catchError((err) => {
					this.destroyUserSession();
					return throwError(err);
				})
			),
			this.store,
			'set'
		);
	}

	/**
	 * Sets the sas context based on the company number
	 *
	 * @param companyNumber - Number of the company
	 */
	public setSasContext(companyNumber: string): Observable<void> {
		// Iben: If SAS is not enabled and there's no company number we early exit
		if (!environment.sas.enabled || !companyNumber) {
			return;
		}

		// Iben: Set sas context
		return this.apiService.setSasContext(companyNumber);
	}

	/**
	 * Destroy the current user session
	 */
	public destroyUserSession(): void {
		// Iben: Reset the current hash for the companyNumber
		this.companyNumberHashSubject$.next('');

		// Iben: Drop the authentication state
		this.authenticationService.dropAuthentication();

		// Iben: Remove the user from the store
		this.store.dispatch(actions.set({ payload: null }));
	}

	/**
	 * Switch organization
	 */
	public switchOrganisation(): Observable<void> {
		return this.apiService.switchCompany();
	}

	public logOut(): ObservableString {
		return this.authenticationService.logout().pipe(
			tap(() => {
				this.destroyUserSession();
			})
		);
	}

	/**
	 * The hashed company number
	 */
	public get companyNumberHash() {
		return this.companyNumberHashSubject$.getValue();
	}

	/**
	 * Sets the user context for a specific key
	 *
	 * @param key - The key for which we wish to save data
	 * @param value - The vale we which to save
	 * @param limit - The amount of time the data should be saved for, when left empty the data will be kept indefinitely
	 */
	public setUserContext(key: UserContextKey, value: any, limit?: number): Observable<unknown> {
		return this.apiService.setUserContext(key, value, limit);
	}

	/**
	 * Returns the user context for a specific key
	 *
	 * @template Context - The type of the data
	 * @param key - The key for which we fetch the data,
	 * @param defaultValue - The default value we wish to get if the fetch does not return anything
	 */
	public getUserContext<Context>(key: UserContextKey, defaultValue: Context = undefined): Observable<Context> {
		return this.apiService.getUserContext<Context>(key, defaultValue);
	}

	/**
	 * Returns the user context for a specific key
	 *
	 * @template Context - The type of the data
	 * @param key - The key for which we fetch the data
	 */
	public deleteUserContext(key: UserContextKey): Observable<void> {
		return this.apiService.deleteUserContext(key);
	}

	/**
	 * Whether or not a user has a specific role
	 *
	 * @param allowedRoles - An array of roles the user is allowed to have
	 */
	public hasRole(allowedRoles: UserTargetCodeEntityKeys[]): ObservableBoolean {
		// Wouter: If this service is used without any roles, we allow the user to pass
		if (allowedRoles.length === 0) {
			return of(true);
		}
		return this.user$.pipe(
			pluck<UserEntity, UserTargetCodeEntity>('targetCode'),
			map((targetCode) => new Set(allowedRoles.map((item) => UserTargetCodeEntity[item])).has(targetCode))
		);
	}

	/**
	 * Returns whether or not a user has (a) certain company property/properties
	 *
	 * @param properties The different roles that are checked against the user's roles
	 * @param [shouldHaveAllRoles=true] Whether the user should have all roles, or if only one of them is enough. Default is true.
	 * @return  {ObservableBoolean}
	 */
	public userHasCompanyProperties(
		properties: UserCompanyPropertyEntity[],
		shouldHaveAllRoles: boolean = true
	): ObservableBoolean {
		// Wouter: If this service is used without any roles, we allow the user to pass
		if (properties.length === 0) {
			return of(true);
		}

		return this.user$.pipe(
			filter(Boolean),
			pluck('company'),
			map((company) => {
				// Iben: If the user has no company, we automatically return false
				if (!company) {
					return false;
				}

				if (shouldHaveAllRoles) {
					// Benoit: if shouldHaveAllRoles = true, the user must have every property of the properties array, and they all must be set to true
					return properties.every(
						(property) => company.hasOwnProperty(property) && company[property] === true
					);
				} else {
					// Benoit: if shouldHaveAllRoles = false, the user must have at least one property of the properties array set to true
					return properties.some(
						(property) => company.hasOwnProperty(property) && company[property] === true
					);
				}
			})
		);
	}
}
