import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { StoreSlice } from '@vlaio/shared/store';

import { reducers } from './permits.store';
import { PermitsApiService } from './services';
import { PermitsService } from './services/permits.service';

@NgModule({
	imports: [StoreModule.forFeature(StoreSlice.Permits, reducers)],
	providers: [PermitsService, PermitsApiService]
})
export class PermitsDataModule {}
