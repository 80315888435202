import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'vlaio-toggle-open-table-cell',
	templateUrl: './toggle-open-cell.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class VlaioToggleOpenCellComponent {
	@Input({ required: true }) public isRowOpen: boolean;
}
