import { Injectable } from '@angular/core';
import { NgxI18nService } from '@studiohyperdrive/ngx-i18n';
import { NgxTourAction, NgxTourService, NgxTourStep } from '@studiohyperdrive/ngx-tour';
import { Observable, filter, switchMap, take } from 'rxjs';

import { UserService } from '@vlaio/shared/user';

import { VlaioIntroductionStepComponent } from '../components';
import { IntroductionFeaturesKeys } from '../interfaces';

@Injectable({ providedIn: 'root' })
export class IntroductionService {
	constructor(
		private readonly tourService: NgxTourService,
		private readonly userService: UserService
	) {}

	/**
	 * Starts an introduction tour if the current user has never visited the feature yet.
	 * Once the tour is ended, by the user or by reaching the end of the tour, the feature is marked as seen.
	 *
	 * @param feature - The feature the user is visiting for the first time
	 * @param introduction - A title and a introduction message informing the user they will be going through an automatic tour.
	 * @param steps - The steps that are used for the help tour
	 * @param onClose - An optional onClose function
	 */
	public startIntroduction(
		i18nService: NgxI18nService,
		feature: IntroductionFeaturesKeys,
		introduction: { title: string; content: string },
		steps: NgxTourStep[],
		onClose?: NgxTourAction
	): Observable<unknown> {
		// Iben: Fetch the visited features of the user
		return this.userService.getUserContext<string[]>('features', []).pipe(
			// Iben: Only continue if the feature has not been visited yet
			filter((features) => {
				return !(features || []).includes(feature);
			}),
			// Iben: Start the tour and handle the tour ended flow
			switchMap((features) => {
				// Iben: Start the tour but add the introduction step
				const { title, content } = introduction;
				return this.tourService
					.startTour(
						[
							{
								title,
								content,
								component: VlaioIntroductionStepComponent
							},
							...steps
						].map((item) => {
							return {
								...item,
								title: i18nService.getTranslation(item.title),
								content: i18nService.getTranslation(item.content)
							};
						}),
						onClose
					)
					.pipe(
						take(1),
						switchMap(() => {
							return this.userService.setUserContext('features', [...(features || []), feature]);
						})
					);
			})
		);
	}

	/**
	 * End the tour introduction manually
	 */
	public endIntroduction() {
		this.tourService.closeTour().subscribe();
	}
}
