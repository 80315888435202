import { Injectable } from '@angular/core';
import { ObservableArray } from '@studiohyperdrive/rxjs-utils';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { VlaioHttpClientService } from '@vlaio/shared/core';
import { VlaioResult } from '@vlaio/shared/types';
import { convertVlaioResult } from '@vlaio/shared/utils';

import { NacebelResultEntity, NaceBelEntity, NacebelSearchResultEntity, NacebelSearchEntity } from '../interfaces';
import { convertNaceBelResultToNacebel, convertNaceBelSearchResultToNacebelSearch } from '../utils';

@Injectable()
export class NacebelApiService {
	private readonly BASE_URL = 'nacebelcodes';

	constructor(private readonly http: VlaioHttpClientService) {}

	public getNacebelCodes(): ObservableArray<NaceBelEntity> {
		return this.http.get<VlaioResult<NacebelResultEntity, 'Nacebel'>>(this.BASE_URL).pipe(
			convertVlaioResult<'Nacebel', NacebelResultEntity, NaceBelEntity>((item) =>
				convertNaceBelResultToNacebel(item)
			),
			catchError(() => {
				return of([]);
			})
		);
	}

	public searchNacebelCodes(searchValue: string): ObservableArray<NacebelSearchEntity> {
		return this.http
			.get<VlaioResult<NacebelSearchResultEntity, 'Nacebel'>>(this.BASE_URL + '/zoeken', {
				zoek: searchValue
			})
			.pipe(
				convertVlaioResult<'Nacebel', NacebelSearchResultEntity, NacebelSearchEntity>((item) =>
					convertNaceBelSearchResultToNacebelSearch(item)
				),
				catchError(() => {
					return of([]);
				})
			);
	}
}
