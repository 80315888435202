import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { StoreSlice } from '@vlaio/shared/store';

import { UserResolver } from './resolvers';
import { UserApiService, UserService } from './services';
import { reducers } from './user.store';

@NgModule({
	imports: [StoreModule.forFeature(StoreSlice.User, reducers)],
	providers: [UserApiService, UserService, UserResolver]
})

// Exports
// -------------------------------------------------------------------------- /
export class UserDataModule {}
