import { NgxI18nTranslationLoaderGuard, provideWithTranslations } from '@studiohyperdrive/ngx-i18n';

import { AppRoutePaths, PartnerPaths, VlaioRoutes } from '@vlaio/shared/route-paths';

import { PartnersSharedTranslationLoader } from './translate.loader';

export const PartnersRoutes: VlaioRoutes = [
	provideWithTranslations(
		{
			path: AppRoutePaths.Empty,
			loadComponent: () => import('./pages/landing/landing.component').then((m) => m.PartnersLandingComponent),
			canActivate: [NgxI18nTranslationLoaderGuard],
			children: [
				{
					path: PartnerPaths.Partner,
					loadComponent: () => import('./pages/partners/partners.component').then((m) => m.PartnersComponent)
				},
				{
					path: PartnerPaths.About,
					loadComponent: () => import('@vlaio/e-loket/single-page').then((m) => m.SinglePageComponent)
				},
				{
					path: AppRoutePaths.Empty,
					pathMatch: 'full',
					redirectTo: PartnerPaths.Partner
				}
			]
		},
		PartnersSharedTranslationLoader
	)
];
