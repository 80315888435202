import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { VlaioHttpClientService } from '@vlaio/shared/core';
import { ELoketEndpoints } from '@vlaio/shared/endpoints';
import { environment } from 'environments';

import { AnonymousUserSessionEntity, UserContextKey, UserSessionEntity, UserSessionEntityResult } from '../interfaces';
import { convertUserSession } from '../utils';

@Injectable()
export class UserApiService {
	constructor(private readonly httpClient: VlaioHttpClientService) {}

	/**
	 * Get information on the user and their session
	 */
	public getUserSession(): Observable<UserSessionEntity | AnonymousUserSessionEntity> {
		return this.httpClient.get<UserSessionEntityResult>(ELoketEndpoints.User.UserSession(), {}, true).pipe(
			map((session) => {
				// Iben: Return only the features if we're an anonymous user
				if (session['@type'] === 'AnoniemeGebruiker') {
					return { features: session.voorzieningen };
				}
				// Iben: Convert the user
				return convertUserSession(session);
			})
		);
	}

	/**
	 * Switch company of user
	 */
	public switchCompany(): Observable<void> {
		return this.httpClient.get('vlaio/switch', {}, true);
	}

	/**
	 * Set the user context for a user
	 *
	 * @param key - The key for the user context
	 * @param waarde - The value we want to save in the user context
	 * @param geldigheid - The amount of time the value should be set in the user context
	 */
	public setUserContext(key: UserContextKey, waarde: unknown, geldigheid: number) {
		// Iben: Set the context of the spotlight cookie
		return this.httpClient.put(ELoketEndpoints.User.UserContext(key), { waarde, geldigheid }).pipe(
			map(() => waarde),
			// Iben: Ignore the error as it shouldn't stop users in their flow
			catchError(() => of())
		);
	}

	/**
	 * Returns the user context for a specific key
	 *
	 * @template Context - The type of the response
	 * @param key - The key for which you get the data
	 * @param defaultValue - The default value we wish to get if the fetch does not return anything
	 */
	public getUserContext<Context>(key: UserContextKey, defaultValue: Context = undefined): Observable<Context> {
		return this.httpClient.get<{ waarde: Context }>(ELoketEndpoints.User.UserContext(key), {}, true).pipe(
			map(({ waarde }) => waarde),
			// Iben: Ignore the error as it shouldn't stop users in their flow
			catchError(() => of(defaultValue))
		);
	}

	/**
	 * Delete the user context for a specific key
	 *
	 * @param key - The key for which you get the data
	 */
	public deleteUserContext(key: UserContextKey): Observable<void> {
		return this.httpClient.delete<void>(ELoketEndpoints.User.UserContext(key), {}, true).pipe(
			// Iben: Ignore the error as it shouldn't stop users in their flow
			catchError(() => of(undefined))
		);
	}

	public setSasContext(companyNumber: string): Observable<void> {
		const payload = {
			doelgroep: 'EA', // "Economische actoren".
			organisatie: companyNumber,
			brontoepassing: environment.sas.sourceUuid,
			doeltoepassing: environment.sas.targetUuid
		};

		return this.httpClient.directPost(environment.sas.url, payload);
	}
}
