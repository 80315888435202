import { ActivatedRoute } from '@angular/router';
import { merge } from '@studiohyperdrive/utils';

import { PageNavItemEntity } from '@vlaio/shared/types';

import { AsideNavigationEntity } from '../interfaces';

/**
 * Converts page items to aside navigation items
 *
 * @param items - An array of page items
 * @param route - The provided route
 */
export const convertAsideNavUtil = (items: PageNavItemEntity[], route: ActivatedRoute): AsideNavigationEntity[] => {
	return [...items].map((item) => {
		return merge<AsideNavigationEntity>(
			{
				label: item.title,
				routerLink: [],
				queryParams: { id: item.id.split('/').pop() },
				route
			},
			['items', item.items ? convertAsideNavUtil(item.items, route) : undefined]
		);
	});
};
