import { StoreModule } from '@ngrx/store';

import { createDataProviderFromModule } from '@vlaio/shared/core';
import { StoreSlice } from '@vlaio/shared/store';

import { reducers } from './forms-data.store';
import { ZipCodeApiService, ZipCodeService } from './services';

export const provideSharedUiFormsData = createDataProviderFromModule({
	imports: [StoreModule.forFeature(StoreSlice.Forms, reducers)],
	providers: [ZipCodeApiService, ZipCodeService]
});
