import { CallToActionEntity, CallToActionEnum, CallToActionResult, CallToActionResultEnum } from '@vlaio/shared/types';

const CallToActionEnumMap = {
	[CallToActionResultEnum.AanvraagStarten]: CallToActionEnum.StartCase,
	[CallToActionResultEnum.MeerInfoBekijken]: CallToActionEnum.MoreInfo,
	[CallToActionResultEnum.ToegangVragen]: CallToActionEnum.RequestAccess,
	[CallToActionResultEnum.Aanmelden]: CallToActionEnum.Login,
	[CallToActionResultEnum.DuoBezwaar]: CallToActionEnum.DuoObjection,
	[CallToActionResultEnum.DuoZelfRealisatie]: CallToActionEnum.DuoSelfRealisation,
	[CallToActionResultEnum.ToegangVragenEloket]: CallToActionEnum.RequestAccessEloket
};

export function convertToCTA(ctaResult: CallToActionResult): CallToActionEntity {
	return {
		name: ctaResult.naam,
		annotations: ctaResult.annotaties,
		link: ctaResult.actieLink,
		action: CallToActionEnumMap[ctaResult.doelVanDeActie]
	};
}
