import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { map } from 'rxjs/operators';

import { StatusService } from '@vlaio/shared/core';
import { PreventNavigationGuard } from '@vlaio/shared/types';

/**
 * Allows navigation in case the maintenance mode is active
 */
export const MaintenanceGuard: CanActivateFn = (): ObservableBoolean => {
	// Iben: Fetch all injectables
	const statusService: StatusService = inject(StatusService);

	return PreventNavigationGuard(statusService.status$.pipe(map((isAvailable) => !isAvailable)));
};
