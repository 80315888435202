import { convertAddressResultToAddress } from '@vlaio/shared/utils';

import {
	CompanyActivityEntity,
	CompanyActivityResultEntity,
	CompanyAddressEntity,
	CompanyAddressResultEntity,
	CompanyBranchEntity,
	CompanyBranchResultEntity,
	CompanyEntity,
	CompanyNamesEntity,
	CompanyNamesResultEntity,
	CompanyResultEntity
} from '../interfaces';

function convertCompanyNameResultToCompanyName(names: CompanyNamesResultEntity): CompanyNamesEntity {
	return {
		short: names.afgekorteNaam,
		public: names.maatschappelijkeNaam,
		commercial: names.commercieleNaam
	};
}

function convertCompanyBranchResultToCompanyBranch(branch: CompanyBranchResultEntity): CompanyBranchEntity {
	return {
		names: convertCompanyNameResultToCompanyName(branch.namen),
		number: branch.vestigingseenheidsnummer.nummer,
		address: branch.adres ? convertCompanyAddressResultToCompanyAddress(branch.adres) : undefined
	};
}

function convertCompanyAddressResultToCompanyAddress(address: CompanyAddressResultEntity): CompanyAddressEntity {
	return {
		...convertAddressResultToAddress(address),
		coordinates: {
			long: address.coordinaten?.lon,
			lat: address.coordinaten?.lat
		}
	};
}

export function convertCompanyActivityResultToCompanyActivity(
	activity: CompanyActivityResultEntity
): CompanyActivityEntity {
	return {
		name: activity.naam,
		start: activity.begin,
		version: activity.versie,
		code: activity.code,
		group: activity.groep,
		type: activity.type
	};
}

export function convertCompanyResultToCompany(company: CompanyResultEntity): CompanyEntity {
	return {
		number: company.ondernemingsnummer.nummer,
		names: convertCompanyNameResultToCompanyName(company.namen),
		branches: (company.vestigingen || []).map((item) => convertCompanyBranchResultToCompanyBranch(item)),
		activities: (company.activiteiten || []).map((item) => convertCompanyActivityResultToCompanyActivity(item)),
		registeredOffice: company.maatschappelijkeZetel
			? convertCompanyAddressResultToCompanyAddress(company.maatschappelijkeZetel)
			: undefined,
		form: company.vennootschapsvorm,
		startDate: company.start,
		registrationDate: company.inschrijving,
		legalStatus: company.rechtstoestand,
		links: (company?.ondernemingslinks?.elementen || []).map((value) => {
			return {
				uri: value.uri,
				title: value.titel,
				limited: value.beperktTotEigenOnderneming
			};
		})
	};
}
