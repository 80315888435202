import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

/**
 * getFormArrayControls
 *
 * This pipe can be used to select a FormArray in a FormGroup,
 * and returns its controls.
 *
 * This allows for easier ngFor loops on FormArray controls in templates.
 */
@Pipe({
	name: 'getFormArrayControls',
	standalone: true
})
export class GetFormArrayControlsPipe implements PipeTransform {
	transform(form: FormGroup, path: string): AbstractControl[] {
		if (!form) {
			return [];
		}

		return (form.get(path) as FormArray).controls;
	}
}
