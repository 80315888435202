export enum MandatesRoutePaths {
	Give = 'geven',
	Take = 'nemen',
	Overview = 'overzicht',
	Wizard = 'wizard',
	AddCompany = 'voeg-onderneming-toe',
	AddDuration = 'voeg-looptijd-toe',
	AddProducts = 'voeg-producten-toe',
	SendRequest = 'aanvraag-versturen',
	Success = 'aanvraag-verstuurd',
	Landing = 'landing',
	Executors = 'uitvoerders',
	Validation = 'validatie',
	Applications = 'aanvragen'
}
