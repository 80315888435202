import { Pipe, PipeTransform } from '@angular/core';
const naceGroups = {
	'001': 'BTW-activiteiten',
	'002': 'EDRL-activiteiten',
	'003': 'Activiteiten via ondernemersloket',
	'004': 'Activiteiten federaal openbaar ambt',
	'005': 'RSZPPO-activiteiten',
	'006': 'RSZ-activiteiten',
	'007': 'Activiteiten gesubsidieerd onderwijs'
};

@Pipe({
	name: 'activityGroup',
	standalone: true
})
export class ActivityGroupPipe implements PipeTransform {
	/**
	 * Transforms an activity group id to the correct label
	 *
	 * @param id - The id of the group
	 */
	transform(value: string): string {
		if (!value || typeof value !== 'string') {
			return '';
		}

		return naceGroups[value] || '';
	}
}
