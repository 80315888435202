/**
 * Provides a list of general scripts and GA setup scripts we wish to attach to the dom
 */
export abstract class AbstractScriptsProviderService {
	/**
	 * Returns a list of scripts we wish to attach to the dom
	 */
	public abstract get scripts(): Record<'url' | 'name', string>[];
	/**
	 * Returns the init and gtag manager scripts to be attached to the dom
	 */
	public abstract get gaScripts(): Record<'initScript' | 'gaScript', string>;
}
