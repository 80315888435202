import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObservableBlob } from '@studiohyperdrive/rxjs-utils';
import { map, Observable } from 'rxjs';

import { environment } from 'environments';

import { AbstractLanguageProviderService } from '../abstracts';

/**
 * Service for making HTTP requests to the VLAIO API. This is the VLAIO wrapper around the Angular HttpClient.
 */
@Injectable({ providedIn: 'root' })
export class VlaioHttpClientService {
	/**
	 * The base URL for the API. This is the URL that will be prepended to all API requests.
	 */
	private baseUrl: string;

	/**
	 * Whether or not to include the language in the URL. This is useful for API calls that require the language to be set.
	 */
	private includeLanguage = false;

	constructor(
		private readonly httpClient: HttpClient,
		private readonly i18nService: AbstractLanguageProviderService
	) {
		// [Fabian]: Trim trailing slash, just in case
		const { protocol, hostname } = environment.api;
		this.baseUrl = `${protocol}://${hostname}`.replace(/\/$/, '');
	}

	/**
	 * Perform a DELETE request to the API.
	 *
	 * @param url The URL to send the request to.
	 * @param params The parameters to send with the request.
	 * @param body The body to send with the request.
	 * @returns An observable that resolves to the response.
	 */
	public delete<T>(
		url: string,
		params: Parameters<HttpClient['delete']>[1]['params'] = {},
		body?: any
	): Observable<T> {
		return this.httpClient.delete<T>(this.composeUrl(url), {
			params,
			withCredentials: true,
			body
		});
	}

	/**
	 * Perform a GET request to the API.
	 *
	 * @param url The URL to send the request to.
	 * @param params The parameters to send with the request.
	 * @param withCredentials Whether or not to include credentials in the request. By default, this is set to `false`.
	 * @returns An observable that resolves to the response.
	 */
	public get<T>(
		url: string,
		params?: Parameters<HttpClient['get']>[1]['params'],
		withCredentials: boolean = false
	): Observable<T> {
		return this.httpClient.get<T>(this.composeUrl(url), {
			params,
			withCredentials
		});
	}

	/**
	 * Perform a GET request to the API that returns a blob.
	 *
	 * @param url The URL to send the request to.
	 *
	 * @returns An observable that resolves to the response.
	 */
	public download(url: string): ObservableBlob {
		return this.httpClient
			.get(this.composeUrl(url), {
				withCredentials: true,
				responseType: 'blob',
				observe: 'response'
			})
			.pipe(
				map((response) => {
					return {
						// Wouter: Either take the inferred file type or nothing.
						fileType: response.headers.get('content-disposition')?.split('.')?.[1] || '',
						blob: response.body
					};
				})
			);
	}

	/**
	 * Perform a GET request to the API without composing the URL. The provided URL will be used as-is.
	 *
	 * @param url The URL to send the request to.
	 * @param params The parameters to send with the request.
	 * @param withCredentials Whether or not to include credentials in the request.
	 *
	 * @returns An observable that resolves to the response.
	 */
	public directGet<T>(
		url: string,
		params?: Parameters<HttpClient['get']>[1]['params'],
		withCredentials: boolean = false
	) {
		return this.httpClient.get<T>(url, {
			params,
			withCredentials
		});
	}

	/**
	 * Perform a POST request to the API.
	 *
	 * @param url The URL to send the request to.
	 * @param data The data to send with the request.
	 * @param context The context to send with the request.
	 *
	 * @returns An observable that resolves to the response.
	 */
	public post<T>(url: string, data: any, context?: HttpContext): Observable<T> {
		return this.httpClient.post<T>(this.composeUrl(url), data, {
			withCredentials: true,
			...(context ? { context } : {})
		});
	}

	/**
	 * Perform a POST request to the API without composing the URL. The provided URL will be used as-is.
	 *
	 * @param url The URL to send the request to.
	 * @param data The data to send with the request.
	 *
	 * @returns An observable that resolves to the response.
	 */
	public directPost<T>(url: string, data: any): Observable<T> {
		return this.httpClient.post<T>(url, data, {
			withCredentials: true
		});
	}

	/**
	 * Perform a PUT request to the API.
	 *
	 * @param url The URL to send the request to.
	 * @param data The data to send with the request.
	 *
	 * @returns An observable that resolves to the response.
	 */
	public put<T>(url: string, data: any): Observable<T> {
		return this.httpClient.put<T>(this.composeUrl(url), data, {
			withCredentials: true
		});
	}

	/**
	 * Perform a PATCH request to the API.
	 *
	 * @param url The URL to send the request to.
	 * @param data The data to send with the request.
	 *
	 * @returns An observable that resolves to the response.
	 */
	public patch<T>(url: string, data: any): Observable<T> {
		return this.httpClient.patch<T>(this.composeUrl(url), data, {
			withCredentials: true
		});
	}

	/**
	 * Whether or not to include the language in the URL.
	 *
	 * @param includeLanguage
	 */
	public setIncludeLanguage(includeLanguage: boolean) {
		this.includeLanguage = includeLanguage;
	}

	/**
	 * Compose the URL for the API request. This includes the base URL, the language (if required) and the provided path.
	 *
	 * @param url The URL to compose.
	 * @returns The composed URL.
	 */
	private composeUrl(url: string): string {
		const { apiPath } = environment.api;

		return [this.baseUrl, ...(this.includeLanguage ? [this.i18nService.currentLanguage] : []), apiPath, url].join(
			'/'
		);
	}
}
