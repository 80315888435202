import { Injectable } from '@angular/core';
import Cookies from 'js-cookie';
import { MatomoTracker } from 'ngx-matomo-client';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { BrowserService } from '@vlaio/shared/core';
import { ProductActionType } from '@vlaio/shared/products';
import { UserEntity, UserService } from '@vlaio/shared/user';
import { environment } from 'environments';

import { GTagCategory, GTagCategoryKey, GtagType, GtagTypeKey } from '../interfaces';

enum GoogleAnalyticsDimension {
	dimension1 = 'Maatregelnaam',
	dimension3 = 'Product',
	dimension4 = 'SearchQuery',
	dimension5 = 'Ondernemingsidentificatie',
	dimension6 = 'Hoedanigheid',
	dimension7 = 'AangemeldeGebruikers'
}

enum MatomoDimension {
	Ondernemingsidentificatie = 1,
	Maatregelnaam = 2,
	Product = 3,
	SearchQuery = 4,
	AangemeldeGebruikers = 5
}

/**
 * The GTag Service which handles all the tracking events for the application.
 */
@Injectable({
	providedIn: 'root'
})
export class GTagService {
	/**
	 * Whether tracking is currently active.
	 */
	private trackingActive: boolean;

	/**
	 * The Google Tag Manager instance defined onto the Window object.
	 */
	private gtagManager;

	/**
	 * The subject which holds whether the component is to be destroyed.
	 */
	private readonly destroy$: Subject<void> = new Subject();

	/**
	 * The unique tracking code for each environment.
	 */
	private readonly gTagTrackingCode: string = environment.gtag.trackingCode;

	constructor(
		private readonly userService: UserService,
		private readonly browserService: BrowserService,
		private readonly matomoService: MatomoTracker
	) {
		this.trackingActive = false;
		/**
		 * Up until February 2025, we used Google Analytics to track user data and behavior.
		 * This has since been replaced by Matomo, but some users still have the GA cookie set.
		 * To ensure that every user is compliant with the new European Tracking and Privacy
		 * Regulations, we remove all traces of Google Analytics in the Cookies and in the
		 * network behavior.
		 * It is possible for users to keep the GA cookie, but they will not be tracked by GA.
		 * Therefore, we may remove the line below and its associated methods after about a year.
		 * The same goes for {@link handleGATracking()}.
		 */
		this.removeGA();
	}

	/**
	 * Initialize Matomo Tracker
	 */
	public initMatomo(): void {
		// Iben: Mark Matomo as requiring consent for all tracking
		this.matomoService.requireConsent();

		// Iben: Set the consent as given
		this.matomoService.rememberConsentGiven();
	}

	/**
	 * Remove Matomo Tracker
	 */
	public removeMatomo(): void {
		// Iben: Remove the consent for Matomo
		this.matomoService.forgetConsentGiven();
	}

	// Track a page event
	public trackPage(title: string, location: string, path: string): void {
		// Iben: Handle GTAG tracking
		this.handleGATracking((manager) => {
			manager('config', this.gTagTrackingCode, {
				custom_map: {
					dimension5: GoogleAnalyticsDimension.dimension5
				},
				anonymize_ip: true
			});

			manager('config', this.gTagTrackingCode, {
				type: 'page',
				page_title: title,
				page_location: location,
				page_path: path,
				anonymize_ip: true,
				[GoogleAnalyticsDimension.dimension5]: this.userService.getUserIdentification()
			});
		});

		// Iben: Handle Matomo tracking
		this.trackMatomoEventsWithDimensions(
			{ [MatomoDimension.Ondernemingsidentificatie]: this.userService.getUserIdentification() },
			() => {
				this.matomoService.setCustomUrl(location.replace('/path', path));
				this.matomoService.trackPageView(title);
			}
		);
	}

	// Track an event
	public trackEvent(type: string, category: string, label: string): void {
		this.handleGATracking((manager) => {
			manager('config', this.gTagTrackingCode, {
				custom_map: {
					dimension5: GoogleAnalyticsDimension.dimension5
				},
				anonymize_ip: true
			});

			manager('event', type, {
				event_category: category,
				event_label: label,
				anonymize_ip: true,
				[GoogleAnalyticsDimension.dimension5]: this.userService.getUserIdentification()
			});
		});

		// Iben: Handle Matomo tracking
		this.trackMatomoEventsWithDimensions(
			{ [MatomoDimension.Ondernemingsidentificatie]: this.userService.getUserIdentification() },
			() => {
				this.matomoService.trackEvent(type, category, label);
			}
		);
	}

	//TODO: Iben: At one point we need to replace the regular track event with this one, so we know exactly what is tracked throughout the application
	/**
	 * Track an event in GTAG with predefined tags
	 *
	 * @param type - The type of the event
	 * @param category - The subcategory of the type
	 * @param label - A free label to add to the event, by default it's 'Klik'
	 * @memberof GTagService
	 */
	public trackListedEvent(type: GtagTypeKey, category: GTagCategoryKey, label?: string): void {
		this.handleGATracking((manager) => {
			manager('config', this.gTagTrackingCode, {
				custom_map: {
					dimension5: GoogleAnalyticsDimension.dimension5
				},
				anonymize_ip: true
			});

			manager('event', GtagType[type], {
				event_category: GTagCategory[category],
				// Iben: We don't use a prefilled value in the function here, as it can be passed down as undefined by the directives
				event_label: label || 'Klik',
				anonymize_ip: true,
				[GoogleAnalyticsDimension.dimension5]: this.userService.getUserIdentification()
			});
		});

		// Iben: Handle Matomo tracking
		this.trackMatomoEventsWithDimensions(
			{ [MatomoDimension.Ondernemingsidentificatie]: this.userService.getUserIdentification() },
			() => {
				// Iben: We don't use a prefilled value in the function here, as it can be passed down as undefined by the directives
				this.matomoService.trackEvent(GtagType[type], GTagCategory[category], label || 'Klik');
			}
		);
	}

	/**
	 * Track a case link referral
	 *
	 * @param type - The type of case
	 * @param product - The product of the case
	 * @memberof GTagService
	 */
	public trackCase(type: string, product: string): void {
		this.trackEvent(type, 'Lopende aanvragen', product);
	}

	/**
	 * Track the target of the user
	 *
	 * @param targetCode - Code of the target of the user
	 */
	public trackTarget(targetCode: string): void {
		this.handleGATracking((manager) => {
			manager('config', this.gTagTrackingCode, {
				custom_map: {
					dimension6: GoogleAnalyticsDimension.dimension6,
					dimension7: GoogleAnalyticsDimension.dimension7
				},
				anonymize_ip: true
			});

			manager('event', 'doelGroep', {
				[GoogleAnalyticsDimension.dimension6]: targetCode,
				[GoogleAnalyticsDimension.dimension7]: '1',
				anonymize_ip: true
			});
		});

		this.handleMatomoTracking(() => {
			this.trackMatomoEventsWithDimensions({ [MatomoDimension.AangemeldeGebruikers]: '1' }, () => {
				this.matomoService.trackEvent(GtagType.TargetGroup, targetCode);
			});
		});
	}

	/**
	 * Track the E-Box button click.
	 *
	 * @param label Whether the user is going to the EBox or activating it
	 */
	public trackEboxButton(label: 'Naar EBox' | 'Activeer EBox' = 'Naar EBox'): void {
		this.handleGATracking((manager) => {
			manager('config', this.gTagTrackingCode, {
				custom_map: {
					dimension5: GoogleAnalyticsDimension.dimension5
				},
				anonymize_ip: true
			});

			manager('event', GtagType.EBox, {
				event_category: 'ebox',
				event_label: label,
				anonymize_ip: true,
				[GoogleAnalyticsDimension.dimension5]: this.userService.getUserIdentification()
			});
		});

		this.handleMatomoTracking(() => {
			this.trackMatomoEventsWithDimensions(
				{ [MatomoDimension.Ondernemingsidentificatie]: this.userService.getUserIdentification() },
				() => {
					this.matomoService.trackEvent(GtagType.EBox, label);
				}
			);
		});
	}

	/**
	 * Track a filter click
	 *
	 * @param type - Type of item
	 * @param label - Label of the event
	 * @memberof GTagService
	 */
	public trackFilterClick(type: string, label: string): void {
		this.handleGATracking((manager) => {
			manager('config', this.gTagTrackingCode, {
				anonymize_ip: true
			});

			manager('event', type, {
				event_category: 'Filter Click',
				event_label: label,
				anonymize_ip: true
			});
		});

		this.handleMatomoTracking(() => {
			this.matomoService.trackEvent(type, 'Filter Click', label);
		});
	}

	// Track an event
	public trackProduct(type: string, category: string, label: string, productTitle: string): void {
		this.handleGATracking((manager) => {
			manager('config', this.gTagTrackingCode, {
				custom_map: {
					dimension1: GoogleAnalyticsDimension.dimension1,
					dimension5: GoogleAnalyticsDimension.dimension5
				},
				anonymize_ip: true,
				transport_type: 'beacon'
			});

			manager('event', type, {
				event_category: category,
				event_label: label,
				[GoogleAnalyticsDimension.dimension1]: productTitle,
				[GoogleAnalyticsDimension.dimension5]: this.userService.getUserIdentification(),
				anonymize_ip: true
			});
		});

		this.handleMatomoTracking(() => {
			this.trackMatomoEventsWithDimensions(
				{
					[MatomoDimension.Maatregelnaam]: productTitle,
					[MatomoDimension.Ondernemingsidentificatie]: this.userService.getUserIdentification()
				},
				() => {
					this.matomoService.trackEvent(type, category, label);
				}
			);
		});
	}

	// Track an event
	/**
	 * Handles the tracking of a direct link event. This omits the GtagType and GtagCategory and
	 * allows for custom values.
	 *
	 * @param type The type of the event
	 * @param category The subcategory of the type
	 * @param label The label associated with the event
	 * @param products The products that need to be tracked
	 * @param fireMatomoEvent Whether the Matomo event should be fired. Default value is `true`.
	 */
	public trackDirectLink(
		type: string,
		category: string,
		label: string,
		products: string[],
		fireMatomoEvent: boolean = true
	): void {
		this.handleGATracking((manager) => {
			manager('config', this.gTagTrackingCode, {
				custom_map: {
					dimension3: GoogleAnalyticsDimension.dimension3,
					dimension5: GoogleAnalyticsDimension.dimension5
				},
				anonymize_ip: true,
				transport_type: 'beacon'
			});

			manager('event', type, {
				event_category: category,
				event_label: label,
				[GoogleAnalyticsDimension.dimension3]: products.join(','),
				[GoogleAnalyticsDimension.dimension5]: this.userService.getUserIdentification(),
				anonymize_ip: true
			});
		});

		// Wouter: If Matomo is handled separately, we don't need to track it here
		if (!fireMatomoEvent) {
			return;
		}

		this.handleMatomoTracking(() => {
			this.trackMatomoEventsWithDimensions(
				{
					[MatomoDimension.Product]: products.join(','),
					[MatomoDimension.Ondernemingsidentificatie]: this.userService.getUserIdentification()
				},
				() => {
					this.matomoService.trackEvent(type, category, label);
				}
			);
		});
	}

	// Track an event
	public trackPageSearch(category: GTagCategory, query: string): void {
		this.handleGATracking((manager) => {
			manager('config', this.gTagTrackingCode, {
				custom_map: {
					dimension4: GoogleAnalyticsDimension.dimension4,
					dimension5: GoogleAnalyticsDimension.dimension5
				},
				anonymize_ip: true,
				transport_type: 'beacon'
			});

			manager('event', 'zoek_maatregelen', {
				event_category: 'maatregelen',
				event_label: 'Zoek maatregelen',
				[GoogleAnalyticsDimension.dimension4]: query,
				[GoogleAnalyticsDimension.dimension5]: this.userService.getUserIdentification(),
				anonymize_ip: true
			});
		});

		this.handleMatomoTracking(() => {
			this.trackMatomoEventsWithDimensions(
				{
					[MatomoDimension.SearchQuery]: query,
					[MatomoDimension.Ondernemingsidentificatie]: this.userService.getUserIdentification()
				},
				() => {
					this.matomoService.trackEvent(GtagType.SiteSearch, category, query);
				}
			);
		});
	}

	/**
	 * Handles the tracking of the product sorting.
	 *
	 * @warning This method has no GA tracking, only Matomo tracking
	 * @param sorting The filter that was applied to rearrange the products
	 */
	public trackProductSort(sorting: string): void {
		this.handleMatomoTracking(() => {
			this.trackMatomoEventsWithDimensions(
				{
					[MatomoDimension.Ondernemingsidentificatie]: this.userService.getUserIdentification()
				},
				() => {
					this.matomoService.trackEvent(GtagType.Filter, GTagCategory.Offers, sorting);
				}
			);
		});
	}

	/**
	 * Handles the tracking of a spotlighted product.
	 *
	 * @param product The name of the product that has been spotlighted
	 */
	public trackSpotlightProduct(category: `${ProductActionType}`, product: string[]): void {
		this.trackDirectLink('dashboardLink', 'maatregelen', 'Dashboard link', product, false);

		// Wouter: There can only be one spotlight product
		const spotlightProduct: string = product[0];

		this.handleMatomoTracking(() => {
			this.trackMatomoEventsWithDimensions(
				{
					[MatomoDimension.Maatregelnaam]: spotlightProduct,
					[MatomoDimension.Product]: spotlightProduct,
					[MatomoDimension.Ondernemingsidentificatie]: this.userService.getUserIdentification()
				},
				() => {
					this.matomoService.trackEvent(GtagType.Spotlight, category, spotlightProduct);
				}
			);
		});
	}

	// Tracking was activated.
	public init(): void {
		this.browserService.runInBrowser(({ browserWindow }) => {
			this.trackingActive = true;

			this.gtagManager = browserWindow['gtag'];

			if (!this.gtagManager) {
				console.error('No GTagManager was found');

				return;
			}

			this.gtagManager('js', new Date());
			this.gtagManager('config', this.gTagTrackingCode, {
				anonymize_ip: true,
				transport_type: 'beacon'
			});

			this.userService.user$
				.pipe(
					filter<UserEntity>(Boolean),
					tap(({ targetCode }) => {
						this.trackTarget(targetCode);
					}),
					takeUntil(this.destroy$)
				)
				.subscribe();
		});
	}

	/**
	 * Removes all the necessary Google Analytics data
	 *
	 * @memberof GTagService
	 */
	public removeGA(): void {
		this.browserService.runInBrowser(({ browserDocument }) => {
			// Iben: Removes the script tag
			browserDocument.getElementById('GTAG-SCRIPT')?.remove();
			browserDocument.getElementById('GTAG-INIT-SCRIPT')?.remove();

			// Iben: Loop over all cookies and remove the Google Analytics ones
			const cookies = browserDocument.cookie?.split('; ') || [];

			cookies.forEach((cookie) => {
				// Iben: If it's not a GA cookie, we early exit
				if (!cookie.includes('_ga')) {
					return;
				}

				// Iben: Remove the cookie
				Cookies.remove(cookie.split('=')[0], { path: '', domain: environment.cookieDomain });
			});
		});
	}

	/**
	 * Wraps the tracking into a handler that will only apply the tracking when we're in the browser and when the tracking is activated
	 *
	 * @param action - The tracking function we wish to perform (**legacy**)
	 */
	private handleGATracking(_action: (manager) => void): void {
		// Iben: Run the tracking only in the browser
		this.browserService.runInBrowser(() => {
			this.removeGA();
		});
	}

	/**
	 * Handles a Matomo action only when consent is given
	 *
	 * @param action - The action we wish to perform
	 */
	private handleMatomoTracking(action: () => void): void {
		this.browserService.runInBrowser(() => {
			// Iben: If no consent was given, we early exit
			if (!this.matomoService.hasRememberedConsent) {
				return;
			}

			// Iben: Perform tracking event
			action();
		});
	}

	/**
	 * Sets the dimensions for the Matomo tracking event and removes them afterwards so they are only send with the provided event
	 *
	 * @param dimensions - The custom dimensions and their values we wish to add to the event
	 * @param action - The action we wish to perform
	 */
	private trackMatomoEventsWithDimensions(
		dimensions: Partial<Record<MatomoDimension, string>>,
		action: () => void
	): void {
		this.handleMatomoTracking(() => {
			// Iben: Set the custom dimensions we want to add to the next track event
			Object.entries(dimensions || {}).forEach(([dimension, value]) => {
				this.matomoService.setCustomDimension(Number(dimension), value);
			});

			// Iben: Perform the next track event
			action();

			// Iben: Remove the custom dimensions
			Object.keys(dimensions || {}).forEach((dimension) => {
				this.matomoService.deleteCustomDimension(Number(dimension));
			});
		});
	}
}
