import { Pipe, PipeTransform } from '@angular/core';

import { environment } from 'environments';

@Pipe({
	name: 'imageUrl',
	standalone: true
})
export class ImageUrlPipe implements PipeTransform {
	/**
	 * Trims an image path string and joins it with the full base path string
	 *
	 * @param value - image path string
	 * @returns joined full base path and image path string
	 */
	transform(value: string) {
		value = value.trim();

		return environment.assets.fullPath + value;
	}
}
