import {
	EntityStoreAssets,
	StoreFlowAssets,
	createEntityAdapterStoreAssets,
	createStoreAssets
} from '@studiohyperdrive/ngx-store';

import { StoreSlice } from '@vlaio/shared/store';

import { NaceBelEntity, NacebelSearchEntity } from './interfaces';

interface NacebelCodeStore extends StoreFlowAssets {
	nacebelCodes: EntityStoreAssets<NaceBelEntity>;
	nacebelSearchResults: EntityStoreAssets<NacebelSearchEntity>;
}

export const { actions, reducers, selectors } = createStoreAssets<NacebelCodeStore>(StoreSlice.NaceBel, [
	{
		subSlice: 'nacebelCodes',
		generator: createEntityAdapterStoreAssets<NaceBelEntity>
	},
	{
		subSlice: 'nacebelSearchResults',
		generator: createEntityAdapterStoreAssets<NacebelSearchEntity>,
		selectId: (item) => item.code
	}
]);
