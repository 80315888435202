import {
	BaseStoreAssets,
	EntityStoreAssets,
	StoreFlowAssets,
	createBaseStoreAssets,
	createEntityAdapterStoreAssets,
	createStoreAssets
} from '@studiohyperdrive/ngx-store';

import { StoreSlice } from '@vlaio/shared/store';

import { CompanyActivitiesByBranchEntity, CompanyEntity, PartialCompanySearchEntity } from './interfaces';

interface CompanyStore extends StoreFlowAssets {
	company: BaseStoreAssets<CompanyEntity>;
	companies: EntityStoreAssets<CompanyEntity>;
	activities: EntityStoreAssets<CompanyActivitiesByBranchEntity>;
	filters: BaseStoreAssets<PartialCompanySearchEntity>;
	searchResults: EntityStoreAssets<Partial<CompanyEntity>>;
	detail: BaseStoreAssets<string>;
	companyDetails: BaseStoreAssets<Record<string, CompanyEntity>>;
}

export const { actions, reducers, selectors } = createStoreAssets<CompanyStore>(StoreSlice.Company, [
	{
		subSlice: 'company',
		generator: createBaseStoreAssets<CompanyEntity>
	},
	{
		subSlice: 'companies',
		generator: createEntityAdapterStoreAssets<CompanyEntity>,
		selectId: ({ number }) => number
	},
	{
		subSlice: 'activities',
		generator: createEntityAdapterStoreAssets<CompanyActivitiesByBranchEntity>,
		selectId: ({ branch }) => branch
	},
	{
		subSlice: 'filters',
		generator: createBaseStoreAssets<PartialCompanySearchEntity>
	},
	{
		subSlice: 'detail',
		generator: createBaseStoreAssets<string>
	},
	{
		subSlice: 'searchResults',
		generator: createEntityAdapterStoreAssets<Partial<CompanyEntity>>,
		selectId: ({ number }) => number
	},
	{
		subSlice: 'companyDetails',
		generator: createBaseStoreAssets<Record<string, CompanyEntity>>
	}
]);
