import { Pipe, PipeTransform } from '@angular/core';
import { merge } from '@studiohyperdrive/utils';
import { concat } from 'lodash';

import { AddressEntity, ContactItemsEntity } from '@vlaio/shared/types';

/**
 * This pipe is used to transform a notification to the corresponding contact details.
 * It will return an object that adheres to the `ContactItemsEntity` interface
 */
@Pipe({
	name: 'contactItemsMap',
	standalone: true
})
export class VlaioContactItemsMapPipe implements PipeTransform {
	/**
	 * Transforms a notification to the corresponding contact details
	 *
	 * @param value - The provided notification
	 */
	transform(value: any): ContactItemsEntity {
		if (!value) {
			return {};
		}

		return merge<ContactItemsEntity>(
			{},
			['phoneNumbers', this.handleArray(value.phoneNumbers || value.phone)],
			[
				'websites',
				this.handleArray(
					concat(value.websites, value.url, value.website, value.contactform).filter(Boolean),
					(cbValue) => ({
						url: cbValue.url || cbValue,
						text: cbValue.text || cbValue.url || cbValue
					})
				)
			],
			['emailAddresses', this.handleArray(value.emailAddresses || value.email)],
			[
				'addresses',
				this.handleArray(
					value.addresses ||
						value.address ||
						merge<AddressEntity>(
							{},
							['box', value.box],
							['street', value.street],
							['number', value.number || value.streetAndNumber],
							['zip', value.zip],
							['municipality', value.municipality],
							['country', value.country],
							['isPrimary', value.isPrimary],
							['isWalloons', value.isWalloons]
						)
				)
			],
			['personNames', this.handleArray(value.personNames)],
			['serviceNames', this.handleArray(value.serviceNames || value.serviceName)]
		);
	}

	public handleArray(value: any, callback?: (cbValue: any) => any): any[] {
		// Wouter: If no value was provided, or the value is an empty array, we return
		if (!value || !(value as any)?.length) {
			return;
		}

		// Wouter: If a callback is defined, we map over the array and call the callback with the item as argument
		if (callback) {
			// Wouter: In case the value is an array, we map over it and call the callback with the item as argument
			if (Array.isArray(value)) {
				return value.map((item) => callback(item));
			}
			// Wouter: Otherwise, we return the callback in an array
			return [callback(value)];
		}

		// Wouter: If no callback is defined, we wrap the value in an array if it isn't one already
		return Array.isArray(value) ? value : [value];
	}
}
