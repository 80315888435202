import { FormControl, FormGroup } from '@angular/forms';

import { NaceBelEntity } from '../../data';

export const createNaceBelForm = (codes: NaceBelEntity[]): FormGroup => {
	// Iben: Create a new FormGroup
	const result = new FormGroup({});

	// Iben: Loop over the codes

	codes.forEach((code) => {
		// Iben: Make a string of the code id, as we form keys can only be strings
		const id = `${code.id}`;

		// Iben: If the code has no subCodes and it is selectable, we add the code as a FormControl
		if (!code.subcodes && code.selectable) {
			result.addControl(id, new FormControl(''));

			return;
		}

		// Iben: If the code has subCodes, we create a FormGroup for said code
		const formGroup = createNaceBelForm(code.subcodes);

		// Iben: If the code itself is selectable, we also add a control for the code to the FormGroup
		if (code.selectable) {
			formGroup.addControl(id, new FormControl(''));
		}

		// Iben: Add the formGroup to the overarching formGroup
		result.addControl(id, formGroup);
	});

	// Iben: Return the FormGroup
	return result;
};
