import { Injectable } from '@angular/core';
import { ObservableArray } from '@studiohyperdrive/rxjs-utils';
import { map } from 'rxjs/operators';

import { VlaioHttpClientService } from '@vlaio/shared/core';
import { ELoketEndpoints } from '@vlaio/shared/endpoints';
import { VlaioResult } from '@vlaio/shared/types';
import { convertToCTA } from '@vlaio/shared/utils';

import { NotificationResultEntity, Notification } from '../interfaces';

@Injectable()
export class NotificationsApiService {
	constructor(private httpClient: VlaioHttpClientService) {}

	/**
	 * Get notifications from the API
	 */
	public getNotifications(): ObservableArray<Notification> {
		return this.httpClient
			.get<
				VlaioResult<NotificationResultEntity, 'Notificaties'>
			>(ELoketEndpoints.Notifications.GetNotifications())
			.pipe(
				map(({ elementen }) => {
					return elementen.map((item) => {
						return {
							title: item.naam,
							message: item.bericht,
							severity: item.ernst,
							...(item.link ? { link: convertToCTA(item.link) } : {}),
							id: item.id
						};
					});
				})
			);
	}
}
