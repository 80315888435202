<div class="c-dashboard__header">
	<ng-content select="[title]"></ng-content>

	<div class="c-dashboard__actions">
		<ng-content select="[actions]"></ng-content>
	</div>

	<div class="c-dashboard__description">
		<ng-content select="[description]"></ng-content>
	</div>
</div>

<div class="c-dashboard-block">
	<ng-content select="[content]"></ng-content>
</div>
