import { createBaseStoreAssets, createEntityAdapterStoreAssets, createStoreAssets } from '@studiohyperdrive/ngx-store';
import { BaseStoreAssets, EntityStoreAssets } from '@studiohyperdrive/ngx-store/lib/store/interfaces';

import { StoreSlice } from '@vlaio/shared/store';

import { UserContextEntity, UserSessionEntity } from './interfaces';

export type UserStoreSliceType = {
	session: BaseStoreAssets<UserSessionEntity>;
	context: EntityStoreAssets<UserContextEntity>;
};

export const { reducers, actions, selectors } = createStoreAssets<UserStoreSliceType>(StoreSlice.User, [
	{
		subSlice: 'session',
		generator: createBaseStoreAssets<UserSessionEntity>
	},
	{
		subSlice: 'context',
		generator: createEntityAdapterStoreAssets<UserContextEntity>,
		selectId: (entity: UserContextEntity) => entity.name
	}
]);
