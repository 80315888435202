import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot } from '@angular/router';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { of } from 'rxjs';

import { SaveOnExitAbstractService, SaveOnExitComponent } from '../abstracts';
import { isWizardSubroute } from '../helpers';
/**
 * Checks whether or not we can navigate away from the page
 *
 * @param {SaveOnExitComponent} component
 * @return {*}  {ObservableBoolean}
 * @memberof SaveOnExitGuard
 */
export const SaveOnExitGuard: CanDeactivateFn<SaveOnExitComponent> = (
	component: SaveOnExitComponent,
	currentRoute: ActivatedRouteSnapshot,
	currentState: RouterStateSnapshot,
	nextState: RouterStateSnapshot
): ObservableBoolean => {
	// Iben: Fetch all injectables
	const saveOnExitService: SaveOnExitAbstractService = inject(SaveOnExitAbstractService);

	// Iben: In case the component is not dirty, we can route without problems
	if (!component.isDirty()) {
		return of(true);
	}

	// Wouter: If we navigate outside of the wizard, the can-exit guard will run so we allow navigation.
	if (!isWizardSubroute(currentRoute, nextState)) {
		return of(true);
	}

	// Iben: In case the component is dirty, we will let the SaveOnExitService know so they can take the appropriate action
	return saveOnExitService.handleDirtyState(component);
};
