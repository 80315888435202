import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'vlaio-product-annotation',
	templateUrl: './product-annotation.component.html',
	styleUrls: ['./product-annotation.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class ProductAnnotationComponent {
	@Input() public annotation: string;
}
