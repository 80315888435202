import { NgxErrorsModule } from '@ngspot/ngx-errors';
import { NgxI18nModule } from '@studiohyperdrive/ngx-i18n';

import { createDataProviderFromModule } from '@vlaio/shared/core';

import { providerServices, SpotlightService } from './services';

export const provideEloketCoreData = createDataProviderFromModule({
	imports: [NgxErrorsModule, NgxI18nModule.forChild()],
	providers: [...providerServices, SpotlightService]
});
