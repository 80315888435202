export const startBlockFrame = `<div class="c-iframe" style="left: 0; width: 100%; height: 0; position: relative; padding-bottom: 56.25%;"><iframe src="`;
export const endBlockFrame = `" style="top: 0; left: 0; width: 100%; height: 100%; position: absolute; border: 0;" allowfullscreen scrolling="no" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"></iframe></div>`;

/**
 * Converts a HTML string with oembeds to iframe
 *
 * @export
 * @param htmlString - the HTML string
 */
export function convertOEmbedToIFrame(htmlString: string): string {
	// Iben: Setup an array for all oembed and all iframe items
	const oembedItems = [];
	const iframeItems = [];

	// Iben: Make a copy of the string for immutability
	let result = `${htmlString}`;

	// Iben: Loop over all oembed matches
	Array.from(htmlString.matchAll(/<oembed>.*?<\/oembed>/g)).forEach(([oembed]) => {
		// Iben: Set oembed item into the array for replacing later on
		oembedItems.push(oembed);

		// Iben: Get url from oembed item
		const url = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/.exec(oembed)[2];

		// Iben: Create new iframe item
		iframeItems.push(`${startBlockFrame}${url}${endBlockFrame}`);
	});

	// Iben: Loop over all oembed items
	oembedItems.forEach((oembed, index) => {
		result = result.replace(oembed, iframeItems[index]);
	});

	// Iben: Return the result
	return result;
}

export const exportForTesting = {
	startBlockFrame,
	endBlockFrame
};
