import { Injectable } from '@angular/core';
import { NgxI18nRootService } from '@studiohyperdrive/ngx-i18n';

import { AbstractLanguageProviderService } from '@vlaio/shared/core';
import { Language } from '@vlaio/shared/types';

@Injectable()
export class LanguageProviderService extends AbstractLanguageProviderService {
	constructor(private readonly i18nRootService: NgxI18nRootService) {
		super();
	}

	public get currentLanguage(): Language {
		return this.i18nRootService.currentLanguage as Language;
	}
}
