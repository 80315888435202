// Route Guard: Authentication
// ---
// Shield unauthenticated users from accessing routes guarded by this Route Guard.

// Imports
// -------------------------------------------------------------------------- /
import { inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, UrlTree, CanActivateChildFn } from '@angular/router';
import { NgxI18nRootService } from '@studiohyperdrive/ngx-i18n';

import { BrowserService } from '@vlaio/shared/core';
import { environment } from 'environments';

export const DirectedLoginGuard: CanActivateChildFn = (route: ActivatedRouteSnapshot): boolean | UrlTree => {
	// Iben: Fetch all injectables
	const router: Router = inject(Router);
	const i18nRootService: NgxI18nRootService = inject(NgxI18nRootService);
	const browserService: BrowserService = inject(BrowserService);

	const hasHint = route.queryParamMap.has('login_hint');

	if (hasHint) {
		browserService.runInBrowser(({ browserWindow }) => {
			const url = environment.api.protocol + '://' + environment.api.hostname;
			const hint = route.queryParamMap.get('login_hint');
			if (isValidHint(hint)) {
				browserWindow.location.href = url + environment.acmidm.directedLoginPath + '?login_hint=' + hint;
				return true;
			}
		});
	}

	router.navigate(['/', i18nRootService.currentLanguage]);

	return false;
};

const isValidHint = (base64Hint: string): boolean => {
	const hintJson = atob(base64Hint);
	const isString = Object.prototype.toString.call(hintJson) === '[object String]';
	if (!isString) {
		return false;
	}

	try {
		const data = JSON.parse(hintJson);
		const allowedCaps = ['EA', 'GID', 'LB'];
		if (data['code_hint'] && !isValidCompanyNr(data['code_hint'])) {
			return false;
		}
		if (data['via_hint'] && !isValidCompanyNr(data['via_hint'])) {
			return false;
		}
		if (data['cap_hint'] && !allowedCaps.includes(data['cap_hint'])) {
			return false;
		}
		const allowedKeys = ['cap_hint', 'code_hint', 'via_hint'];
		const hasInvalidKey = !Object.keys(data).every((e) => allowedKeys.includes(e));
		if (hasInvalidKey) {
			return false;
		}
	} catch (e) {
		return false;
	}

	return true;
};

const isValidCompanyNr = (nr: string): boolean => {
	const companyNrRegex = /(0[0-9]{9})+/i;
	return companyNrRegex.test(nr);
};
