import { PageEntity, PageNavItemEntity, PageNavItemResultEntity, PageResultEntity, Pages } from '@vlaio/shared/types';

export const PageNavItemResultEntityMock: PageNavItemResultEntity = {
	titel: 'Hoe krul je je snor',
	beschrijving: 'Snorren',
	'@id': Pages.About
};
export const PageNavItemEntityMock: PageNavItemEntity = {
	title: 'Hoe krul je je snor',
	description: 'Snorren',
	id: Pages.About
};
export const PageResultEntityMock: PageResultEntity = {
	titel: 'Hoe krul je je snor',
	tekst: 'Met snorrenwax',
	'@id': 'www.krulsnor.be'
};
export const PageEntityMock: PageEntity = {
	title: 'Hoe krul je je snor',
	text: 'Met snorrenwax',
	id: 'www.krulsnor.be',
	parentId: Pages.About
};
