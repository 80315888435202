<button
	class="c-hamburger c-menu__hamburger u-outline"
	[title]="i18nKeys.Hamburger.Title | translate"
	[class.is-active]="isMenuOpen"
>
	<span class="c-hamburger__inner" aria-hidden="true">
		<span class="c-hamburger__top-bar"></span>
		<span class="c-hamburger__middle-bar c-hamburger__middle-bar--left-diagonal"></span>
		<span class="c-hamburger__middle-bar c-hamburger__middle-bar--right-diagonal"></span>
		<span class="c-hamburger__bottom-bar"></span>
	</span>
</button>
