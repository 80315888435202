import { Pipe, PipeTransform } from '@angular/core';

/**
 * Formats a SSN string to the correct SSN format
 *
 * @param value - The unformatted SSN string
 */
export const formatInsz = (value: string) => {
	const insz = value.replace(/\D/g, '');

	if (insz.length !== 11) {
		return '';
	}

	return `${insz.substring(0, 2)}.${insz.substring(2, 4)}.${insz.substring(4, 6)}-${insz.substring(
		6,
		9
	)}.${insz.substring(9, 11)}`;
};

@Pipe({
	name: 'insz',
	standalone: true
})
export class InszPipe implements PipeTransform {
	/**
	 * Formats a SSN string to the correct SSN format
	 *
	 * @param value - The unformatted SSN string
	 */
	transform(value: string): string {
		if (typeof value !== 'string') {
			return '';
		}

		return formatInsz(value);
	}
}
