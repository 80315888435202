import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { NaceBelEntity } from '../../../data';

@Component({
	selector: 'vlaio-nacebel-title',
	templateUrl: './nacebel-title.component.html',
	styleUrls: ['./nacebel-title.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class NacebelTitleComponent {
	@Input() public code: NaceBelEntity;

	@HostBinding('class.c-nacebel-title--selectable')
	public get isSelectable(): boolean {
		return this.code.selectable;
	}
}
