import { Component } from '@angular/core';

// Iben: As long as the application is not Signal based, we do not put this on OnPush
@Component({
	selector: 'vlaio-content-wrapper',
	templateUrl: './content-wrapper.component.html',
	styleUrl: './content-wrapper.component.scss',
	standalone: true
})
export class VlaioContentWrapperComponent {}
