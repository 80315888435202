import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgxI18nRootService } from '@studiohyperdrive/ngx-i18n';

import { LoadingText } from '../../constants';

@Component({
	selector: 'vlaio-loading-page',
	templateUrl: './loading.page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgOptimizedImage]
})
export class LoadingPageComponent {
	// Iben: This page is used as a loading state for the application. This means that translations are not loaded yet, so we cannot load these translations
	// like we do with other translations. It is therefore needed to keep these hardcoded.
	public loadingText = LoadingText[this.i18nRootService.currentLanguage];

	@Input() public imageSrc: string;

	constructor(private readonly i18nRootService: NgxI18nRootService) {}
}
