<div class="c-maintenance">
	<div class="c-hero">
		<div class="c-hero__cutoff"></div>
		<div class="c-hero__visual"></div>
		<div class="c-container">
			<div class="l-grid">
				<div class="l-grid__col l-grid__col--6@viewport-9 l-grid__col--12@viewport-7">
					<div class="c-hero-about-block">
						<h1 cypressTag="Status.Title">
							{{ title | translate }}
						</h1>

						<p cypressTag="Status.Description">
							{{ label | translate }}
						</p>

						<div class="c-hero-about-block__ctas">
							<vlaio-button
								cypressTag="Status.Button"
								[title]="action | translate"
								(click)="retryStatus()"
							>
								{{ action | translate }}
							</vlaio-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="c-carousel"></div>
