import { CompanyActivityEntity, CompanyActivityResultEntity, CompanyEntity, CompanyResultEntity } from '../interfaces';

import { CompanyLinkEntityMock, CompanyLinkResultEntityMock } from './company-links.mock';

export const CompanyActivityResultEntityMock: CompanyActivityResultEntity = {
	begin: '2008-01-01',
	code: '46.349',
	groep: '001',
	naam: 'Groothandel in dranken, algemeen assortiment',
	type: 'Hoofdactiviteit',
	versie: '2008'
};
export const CompanyActivityEntityMock: CompanyActivityEntity = {
	start: '2008-01-01',
	code: '46.349',
	group: '001',
	name: 'Groothandel in dranken, algemeen assortiment',
	type: 'Hoofdactiviteit',
	version: '2008'
};

export const CompanyResultEntityMock: CompanyResultEntity = {
	ondernemingsnummer: {
		nummer: '0123456789'
	},
	namen: {
		commercieleNaam: 'string',
		maatschappelijkeNaam: 'string',
		afgekorteNaam: 'string'
	},
	maatschappelijkeZetel: {
		coordinaten: {
			lon: 111,
			lat: 111
		},
		straat: 'string',
		nummer: 'string',
		bus: 'string',
		postcode: 'string',
		gemeente: 'string',
		land: 'string'
	},
	inschrijving: 'string',
	start: 'string',
	rechtstoestand: 'string',
	vennootschapsvorm: 'string',
	activiteiten: [CompanyActivityResultEntityMock],
	vestigingen: [
		{
			vestigingseenheidsnummer: {
				nummer: 'string'
			},
			namen: {
				commercieleNaam: 'string',
				maatschappelijkeNaam: 'string',
				afgekorteNaam: 'string'
			},
			adres: {
				coordinaten: {
					lon: 111,
					lat: 111
				},
				straat: 'string',
				nummer: 'string',
				bus: 'string',
				postcode: 'string',
				gemeente: 'string',
				land: 'string'
			}
		}
	],
	ondernemingslinks: {
		'@id': 'Ondernemingslinks',
		'@type': 'OndernemingsLinks',
		elementen: [CompanyLinkResultEntityMock]
	}
};

export const CompanyEntityMock: CompanyEntity = {
	number: '0123456789',
	names: {
		commercial: 'string',
		public: 'string',
		short: 'string'
	},
	registeredOffice: {
		street: 'string',
		number: 'string',
		box: 'string',
		zip: 'string',
		municipality: 'string',
		country: 'string',
		coordinates: {
			long: 111,
			lat: 111
		},
		isWalloons: false
	},
	registrationDate: 'string',
	startDate: 'string',
	legalStatus: 'string',
	form: 'string',
	branches: [
		{
			number: 'string',
			names: {
				commercial: 'string',
				public: 'string',
				short: 'string'
			},
			address: {
				coordinates: {
					long: 111,
					lat: 111
				},
				street: 'string',
				number: 'string',
				zip: 'string',
				municipality: 'string',
				box: 'string',
				country: 'string',
				isWalloons: false
			}
		}
	],
	activities: [CompanyActivityEntityMock],
	links: [CompanyLinkEntityMock]
};
