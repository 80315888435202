import { NgxI18nTranslationLoaderGuard, provideWithTranslations } from '@studiohyperdrive/ngx-i18n';

import { AppRoutePaths, VlaioRoutes } from '@vlaio/shared/route-paths';

import { AboutTranslationLoader } from './translation.loader';

export const AboutRoutes: VlaioRoutes = [
	provideWithTranslations(
		{
			path: AppRoutePaths.Empty,
			loadComponent: () => import('./pages/about/about.component').then((m) => m.AboutPageComponent),
			canActivate: [NgxI18nTranslationLoaderGuard]
		},
		AboutTranslationLoader
	)
];
