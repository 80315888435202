import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { FacetsApiService, FacetsEndpointInjectionToken, FacetsStoreAssetsInjectionToken } from './abstracts';
import { FacetsStoreAssets } from './facets.store';
import { FacetsService } from './services';

@NgModule({
	imports: [StoreModule]
})
export class SharedFacetsDataModule {
	/**
	 * Sets up the facets module
	 *
	 * @static
	 * @param endpoint - The endpoint where we wish to get the facets from
	 * @param slice - The slice in which we wish to save the facets
	 * @param storeAssets - The store assets needed
	 */
	static forChild(endpoint: string, slice: string, storeAssets: FacetsStoreAssets) {
		return {
			ngModule: SharedFacetsDataModule,
			providers: [
				...StoreModule.forFeature(slice, storeAssets.reducers).providers,
				// Iben: Provide the endpoint
				{
					provide: FacetsEndpointInjectionToken,
					useValue: endpoint
				},
				// Iben: Provide the store assets
				{
					provide: FacetsStoreAssetsInjectionToken,
					useValue: storeAssets
				},
				FacetsService,
				FacetsApiService
			]
		};
	}
}
