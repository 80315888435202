import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { NgxTooltipDirective } from '@studiohyperdrive/ngx-inform';

import { HasFeaturePipe } from '@vlaio/shared/features';

@Component({
	selector: 'vlaio-product-tag',
	templateUrl: './product-tag.component.html',
	styleUrl: './product-tag.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [HasFeaturePipe, NgxTooltipDirective, NgTemplateOutlet]
})
export class ProductTagComponent {
	@HostBinding('class') private readonly rootClass = 'c-product-tag';

	@Input() public description: string;
}
