import { merge } from '@studiohyperdrive/utils';

import { PageNavItemEntity, PageNavItemResultEntity } from '@vlaio/shared/types';

/**
 * Maps the result entity of the Page api service to a frontend-friendly format.
 *
 * @param value The result entity, gotten from the API
 * @param parent An optional result entity, gotten from the API
 * @returns The translated entity.
 */
export function convertPageNavItem(
	value: PageNavItemResultEntity,
	parent?: PageNavItemResultEntity
): PageNavItemEntity {
	return merge<PageNavItemEntity>(
		{
			title: value.titel,
			description: value.beschrijving,
			id: value['@id']
		},
		['items', value.elementen?.length ? value.elementen.map((item) => convertPageNavItem(item, value)) : undefined],
		['parent', parent ? { id: parent['@id'], title: parent.titel } : undefined]
	);
}
