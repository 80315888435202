import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxCookieService } from '@studiohyperdrive/ngx-cookies';
import { NgxModalService } from '@studiohyperdrive/ngx-inform';
import { finalize, switchMap, take, tap } from 'rxjs';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { VlaioIconComponent } from '@vlaio/shared/ui/common';

import { VlaioVideoModalComponent } from '../video-modal/video-modal.component';

import { VlaioCookieConsentTranslationsEntity, VlaioVideoDataEntity } from './video-button.types';

function getImageUrl(videoId: string): VlaioVideoDataEntity {
	return {
		videoId,
		thumbnail: `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`,
		video: `https://www.youtube.com/embed/${videoId}`
	};
}

@Component({
	selector: 'vlaio-video-button',
	templateUrl: './video-button.component.html',
	styleUrl: './video-button.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [VlaioIconComponent, CypressTagDirective]
})
export class VlaioVideoButtonComponent {
	/**
	 * The translations of the cookie consent disclaimer.
	 */
	@Input({ required: true }) public translations: VlaioCookieConsentTranslationsEntity;
	/**
	 * The YouTube video.
	 */
	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input({ required: true, transform: getImageUrl, alias: 'videoId' }) public videoData: VlaioVideoDataEntity;
	/**
	 * Whether the modal should open by default.
	 */
	@Input() public set openModal(shouldOpen: boolean) {
		if (!shouldOpen) {
			return;
		}
		// Wouter: Open the modal;
		this.handleButtonClick();
		// Wouter: Emit the modal has programmatically been opened.
		this.modalOpenedOnInit.emit();
	}

	/**
	 * Emits when the modal has been opened programmatically.
	 */
	@Output() public readonly modalOpenedOnInit: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private readonly modalService: NgxModalService,
		private readonly cookieService: NgxCookieService,
		private readonly sanitizer: DomSanitizer
	) {}

	/**
	 * Handle the opening of the modal, along with intercepting its output events.
	 */
	public handleButtonClick() {
		// Wouter: Keep track wether the modal should reopen after closing.
		let shouldReopen: boolean = true;

		// Wouter: Open the modal
		this.cookieService
			// Iben: Check if the marketing cookies are accepted
			.hasAcceptedCategory('marketing')
			.pipe(
				take(1),
				switchMap((hasCookieConsent) => {
					// Wouter: Open the modal
					return this.modalService.open({
						component: VlaioVideoModalComponent,
						role: 'dialog',
						label: this.translations.modalLabel,
						data: {
							hasCookieConsent: hasCookieConsent,
							videoData: {
								...this.videoData,
								video: this.sanitizer.bypassSecurityTrustResourceUrl(this.videoData.video as string)
							},
							translations: this.translations
						}
					});
				}),
				tap((value) => {
					// Wouter: handle the cookie accepting
					if (value === 'Cookies') {
						this.cookieService.acceptCategory('marketing');
						return;
					}

					shouldReopen = false;
				}),
				finalize(() => {
					if (shouldReopen) {
						// Wouter: Calling the function again to reopen the modal results in a subscription within a subscription.
						// As the take(1) operator is used, the subscription will be completed after the first emission so this is not a problem.
						this.handleButtonClick();
					}
				})
			)
			.subscribe();
	}
}
