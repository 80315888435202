import { CallToActionEnum, CallToActionResultEnum, ProductEntity, ProductEntityResult } from '@vlaio/shared/types';

export const offeredProductsMock: ProductEntity = {
	id: 'test',
	name: 'Ruwbouw duaal',
	description:
		'Duaal leren of alternerend leren wil zeggen dat jongeren de schoolbanken afwisselen met een activiteit op de werkvloer. Op deze manier wordt de theorie en de praktijk op ideale wijze gecombineerd. ',
	link: 'www.google.be',
	actions: [
		{
			name: 'Meer informatie',
			action: CallToActionEnum.MoreInfo,
			link: 'www.google.be',
			annotations: []
		}
	],
	partners: [
		{
			name: 'Agenstschap Innoveren & Ondernemen',
			url: 'www.google.be'
		}
	],
	infoLinks: [
		{
			name: 'ZWED',
			link: 'https://www.youtube.com/channel/UCVZACzeGq540FxRMPKkYxsw'
		}
	],
	tags: [],
	theme: 'Infrastructuur en materiaal',
	ipdcProduct: {
		'@type': 'IpdcProduct',
		productId: '2853',
		regulations: {
			'@type': 'TekstVerzameling',
			elements: [
				{
					'@type': 'Tekst',
					text: '<p>Dit is de regelgeving</p>\n'
				}
			]
		},
		exceptions: {
			'@type': 'TekstVerzameling',
			elements: [
				{
					'@type': 'Tekst',
					text: '<p>Dit zijn de uitzonderingen</p>\n'
				}
			]
		},
		terms: {
			'@type': 'TekstVerzameling',
			elements: [
				{
					'@type': 'Tekst',
					text: '<p>Dit zijn de voorwaarden</p>\n'
				}
			]
		},
		procedures: {
			'@type': 'TekstVerzameling',
			elements: [
				{
					'@type': 'Tekst',
					text: '<p>Dit is de beschrijving van de procedure</p>\n'
				}
			]
		},
		costs: {
			'@type': 'TekstVerzameling',
			elements: [
				{
					'@type': 'Tekst',
					text: '<p>Dit is de beschrijving van de kosten</p>\n'
				}
			]
		},
		financialBenifits: {
			'@type': 'TekstVerzameling',
			elements: [
				{
					'@type': 'Tekst',
					text: '<p>Dit zijn de Financiële voordelen</p>\n<table><thead><tr><th>\n<h4>Financiële voordelen</h4>\n</th>\n</tr></thead><tbody><tr><td> </td>\n</tr></tbody></table>'
				}
			]
		},
		contactInfo: {
			'@type': 'ContactgegevensVerzameling',
			elements: []
		}
	}
};

export const offeredProductsResultMock: ProductEntityResult = {
	'@id': 'test',
	'@type': 'Product',
	id: 'test',
	naam: 'Ruwbouw duaal',
	omschrijving:
		'Duaal leren of alternerend leren wil zeggen dat jongeren de schoolbanken afwisselen met een activiteit op de werkvloer. Op deze manier wordt de theorie en de praktijk op ideale wijze gecombineerd. ',
	meerInfoLink: 'www.google.be',
	gebruikersacties: {
		elementen: [
			{
				naam: 'Meer informatie',
				doelVanDeActie: CallToActionResultEnum.MeerInfoBekijken,
				actieLink: 'www.google.be',
				annotaties: []
			}
		]
	},
	partners: [
		{
			'@id': 'test',
			'@type': 'test',
			naam: 'Agenstschap Innoveren & Ondernemen',
			url: 'www.google.be'
		}
	],
	infoLinks: {
		'@id': 'infoLinks',
		'@type': 'mock',
		elementen: [
			{
				naam: 'ZWED',
				url: 'https://www.youtube.com/channel/UCVZACzeGq540FxRMPKkYxsw'
			}
		]
	},
	thema: 'Infrastructuur en materiaal',
	ipdcProduct: {
		'@type': 'IpdcProduct',
		productID: '2853',
		regelgeving: {
			'@type': 'TekstVerzameling',
			elementen: [
				{
					'@type': 'Tekst',
					tekst: '\u003Cp\u003EDit is de regelgeving\u003C/p\u003E\n'
				}
			]
		},
		uitzonderingen: {
			'@type': 'TekstVerzameling',
			elementen: [
				{
					'@type': 'Tekst',
					tekst: '\u003Cp\u003EDit zijn de uitzonderingen\u003C/p\u003E\n'
				}
			]
		},
		voorwaarden: {
			'@type': 'TekstVerzameling',
			elementen: [
				{
					'@type': 'Tekst',
					tekst: '\u003Cp\u003EDit zijn de voorwaarden\u003C/p\u003E\n'
				}
			]
		},
		procedures: {
			'@type': 'TekstVerzameling',
			elementen: [
				{
					'@type': 'Tekst',
					tekst: '\u003Cp\u003EDit is de beschrijving van de procedure\u003C/p\u003E\n'
				}
			]
		},
		kosten: {
			'@type': 'TekstVerzameling',
			elementen: [
				{
					'@type': 'Tekst',
					tekst: '\u003Cp\u003EDit is de beschrijving van de kosten\u003C/p\u003E\n'
				}
			]
		},
		financieleVoordelen: {
			'@type': 'TekstVerzameling',
			elementen: [
				{
					'@type': 'Tekst',
					tekst: '\u003Cp\u003EDit zijn de Financi\u00eble voordelen\u003C/p\u003E\n\u003Ctable\u003E\u003Cthead\u003E\u003Ctr\u003E\u003Cth\u003E\n\u003Ch4\u003EFinanci\u00eble voordelen\u003C/h4\u003E\n\u003C/th\u003E\n\u003C/tr\u003E\u003C/thead\u003E\u003Ctbody\u003E\u003Ctr\u003E\u003Ctd\u003E \u003C/td\u003E\n\u003C/tr\u003E\u003C/tbody\u003E\u003C/table\u003E'
				}
			]
		},
		contactgegevens: {
			'@type': 'ContactgegevensVerzameling',
			elementen: []
		}
	}
};

export const OfferedProductsApiResultMock = {
	volgendePagina: 'https://aio-dev-cms.district01.be/api/aangeboden-producten-pagina?_format=json&index=2',
	inhoud: {
		elementen: [{ product: offeredProductsResultMock }]
	},
	paginatie: {
		limiet: 25,
		totaalAantal: 50,
		index: 1
	}
};
