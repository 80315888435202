import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';

import { Optional } from '@vlaio/shared/types';
import { VlaioToastEntity, VlaioToastComponent } from '@vlaio/shared/ui/common';

/**
 * The ToastService allows you to easily open a toast in the style of VLAIO.
 *
 * @example ```
 * this.ToastService.openFromComponent({
 *		data: {
 *			text: 'Example text',
 *			type: 'Positive'
 *		},
 *		duration: 2000
 *	});
 * ```
 */
@Injectable({
	providedIn: 'root'
})
export class ToastService {
	private readonly DEFAULT_SNACKBAR_CONFIG: MatSnackBarConfig<VlaioToastEntity> = {
		direction: 'ltr',
		duration: 6000,
		horizontalPosition: 'right',
		politeness: 'polite',
		verticalPosition: 'top'
	};

	private readonly DEFAULT_SNACKBAR_DATA_CONFIG: Optional<VlaioToastEntity, 'text'> = {
		type: 'positive',
		showIcon: true
	};

	constructor(private readonly matSnackBarService: MatSnackBar) {}

	/**
	 * The openFromComponent method will call the openFromComponent on the MatSnackBar instance with the provided arguments.
	 *
	 * @param component
	 * @param config
	 */
	public openFromComponent(config: VlaioToastEntity, component?: ComponentType<unknown>): MatSnackBarRef<unknown> {
		// Wouter: Get duration if provided
		const { duration: configDuration, ...customConfig } = config;

		// Wouter: open the toast
		return this.matSnackBarService.openFromComponent(component || VlaioToastComponent, {
			...this.DEFAULT_SNACKBAR_CONFIG,
			...config,
			// Wouter: Nullish coalescing to account for `0` as a valid value. The value of `0` will remove the internal timer.
			// Default to `0` if the type is Negative to keep toast visible until user interaction
			duration: configDuration ?? (customConfig?.type === 'negative' ? 0 : this.DEFAULT_SNACKBAR_CONFIG.duration),
			data: {
				...this.DEFAULT_SNACKBAR_DATA_CONFIG,
				// Wouter: Allow text and other config to override defaults. This must remain last
				...customConfig
			},
			panelClass: ['c-vlaio-toast', `toast-${customConfig.type || this.DEFAULT_SNACKBAR_DATA_CONFIG.type}`]
		});
	}

	/**
	 * dismiss
	 *
	 * The dismiss method will call the dismiss on the MatSnackBar instance.
	 */
	public dismiss(): void {
		this.matSnackBarService.dismiss();
	}
}
