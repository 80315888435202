import { PartnerEntity, PartnerLevelEnum, PartnerResultEntity, PartnersByLevelEntity } from '../interfaces';

/**
 * The Dutch version of a single partner mock.
 */
export const PartnerResultMock: PartnerResultEntity = {
	naam: ' Agentschap Innoveren & Ondernemen',
	id: 'aio',
	aantalProducten: 7,
	website: 'www.snor.be',
	niveau: PartnerLevelEnum.Belgian,
	niveauLabel: 'Organisatie',
	isBeschikbaarInAanbevelingenOpMaat: false,
	isBeschikbaarInHetAanbod: true,
	isBeschikbaarInLopendeAanvragen: true
};

/**
 * A single partner mock.
 */
export const PartnerMock: PartnerEntity = {
	name: ' Agentschap Innoveren & Ondernemen',
	id: 'aio',
	amountOfProducts: 7,
	url: 'www.snor.be',
	level: PartnerLevelEnum.Belgian,
	levelLabel: 'Organisatie',
	isAvailableIn: {
		cases: true,
		recommendations: false,
		products: true
	},
	isAtLeastAvailableInOne: true
};

export const PartnersByLevelMock: PartnersByLevelEntity = {
	[PartnerLevelEnum.Belgian]: [PartnerMock]
};
