import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';

import { MetaService } from '../services/meta.service';

/**
 * Sets provided metaData on current HTML document
 *
 * @param route - Route with provided meta data
 */
export const MetaGuard: CanActivateFn = (route: ActivatedRouteSnapshot): boolean => {
	// Iben: Fetch the injectables
	const metaService: MetaService = inject(MetaService);

	// Iben: Get the metadata settings and add them if they exist
	const metaSettings = route.data?.meta;

	if (metaSettings) {
		metaService.updateMetaDataWithTranslations(metaSettings).subscribe();
	}

	// Iben: Allow the router to continue
	return true;
};
