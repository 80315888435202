import { marker } from '@ngneat/transloco-keys-manager/marker';

import { sharedI18nKeys } from '@vlaio/shared/i18n';

export const I18nKeys = {
	...sharedI18nKeys,
	Nacebel: {
		SearchPlaceholder: marker('nacebel.search-placeholder')
	}
};
