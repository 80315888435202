export enum EloketStoreSlice {
	About = 'about',
	Cases = 'cases',
	Company = 'company',
	Dashboard = 'dashboard',
	EBox = 'e-box',
	Executors = 'executors',
	ExecutorsWizard = 'executors-wizard',
	Faq = 'faq',
	Notifications = 'notifications',
	Pages = 'pages',
	Partners = 'partners',
	Permits = 'permits',
	Products = 'products',
	Recommendations = 'recommendations',
	Sanctions = 'sanctions',
	SharedWizard = 'shared-wizard',
	SharedMandatesExecutors = 'shared-mandates-executors',
	Subsidies = 'subsidies',
	Survey = 'survey',
	Toast = 'toast',
	User = 'user',
	Inbox = 'inbox'
}
