import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CypressTagDirective } from '@vlaio/cypress/core';

import { VlaioIconComponent } from '../../icon/icon.component';
import { VlaioLinkAbstractComponent } from '../shared';

/**
 * A component that renders an external link. The navigation is done by opening the link in a new tab.
 * It always adds an icon to the link.
 */
@Component({
	selector: 'vlaio-external-link',
	templateUrl: './external-link.component.html',
	styleUrls: ['../shared/shared-link.style.scss', './external-link.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [TranslateModule, VlaioIconComponent, NgClass, CypressTagDirective]
})
export class VlaioExternalLinkComponent extends VlaioLinkAbstractComponent {}
