import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

/**
 * Check whether or not the route we want to navigate to a child of the wizard route is.
 *
 * @param currentRoute The current route on which the guard was called.
 * @param nextState The next route to compare the current route to.
 * @returns A boolean whether or not the condition is met.
 */
export const isWizardSubroute = (currentRoute: ActivatedRouteSnapshot, nextState: RouterStateSnapshot): boolean => {
	if (!nextState || !currentRoute) {
		return false;
	}

	// Wouter: Take the URL and split on every '/' indicating a subroute.
	// Ex: 'nl/loket/mandaten/uitvoerders/wizard/voeg-uitvoerders-toe'
	const nextPathSegments: string[] = nextState.url.split('/');

	// Wouter: if any of the wizard subroutes equal the last part of the destination
	// route, we navigate within the wizard, which is allowed
	return !!currentRoute.parent.parent.routeConfig.children.find((child) => {
		return child.path === nextPathSegments[nextPathSegments.length - 1];
	});
};
