<vlaio-banner [title]="(page$ | async)?.title" />

<vlaio-breadcrumb class="c-container c-container--spacing u-margin-top"></vlaio-breadcrumb>

<div class="c-container c-container--spacing u-margin-top-xl">
	<div>
		<vlaio-content [loading]="loading$ | async">
			<vlaio-page [showTitle]="false" [scrollToAnchor]="anchor$ | async" [page]="page$ | async"></vlaio-page>
		</vlaio-content>
	</div>
</div>
