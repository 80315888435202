import { Directive, ElementRef, Inject, Input, OnDestroy, Optional, Renderer2 } from '@angular/core';

import { GTagCategory, GTagCategoryKey, GtagType, GtagTypeKey } from '../interfaces';
import { GtagDirectiveLabelProviderService, GTagService } from '../services';

import { GtagDirectiveEvent, GtagDirectiveEventToken } from './gtag-directive.token';

/**
 * A Google Analytics tracking directive that will track the event based on the data you provide.
 */
@Directive({
	standalone: true,
	selector: '[gtagEvent]'
})
export class GtagEventDirective implements OnDestroy {
	private listener;
	/**
	 * The category of the GTag event
	 */
	@Input() public gtagCategory: GTagCategoryKey;

	/**
	 * The type of the GTag event
	 */
	@Input() public gtagType: GtagTypeKey;

	/**
	 * The label of the GTag event
	 */
	@Input() public gtagLabel: string;

	/**
	 * Whether or not we want to prevent tracking
	 */
	@Input() public preventTracking: boolean = false;

	constructor(
		@Inject(GtagDirectiveEventToken) private readonly token: GtagDirectiveEvent,
		private readonly gtagService: GTagService,
		private renderer: Renderer2,
		private elementRef: ElementRef,
		@Optional() private readonly gtagDirectiveLabelProvider: GtagDirectiveLabelProviderService
	) {
		// Iben: Setup a listener through a token so that we can dynamically listen to events
		this.listener = this.renderer.listen(this.elementRef.nativeElement, token || 'click', (event) =>
			this.trackData(event)
		);
	}

	// Iben: Detatch the listener
	ngOnDestroy() {
		this.listener();
	}

	/**
	 * Track the data for GA
	 *
	 * @param  event - A HTML event
	 */
	private trackData(event: any) {
		// Iben: If we are tracking a change event and we have a label provider, use the label provider to fetch the label needed for GA
		if (this.token === 'change' && this.gtagDirectiveLabelProvider) {
			this.gtagLabel = this.gtagDirectiveLabelProvider.getSelectLabel(event.target.value);
		}

		if (!this.preventTracking) {
			// Iben: Track the event based on the provided category, type and label
			this.gtagService.trackEvent(
				GtagType[this.gtagType],
				GTagCategory[this.gtagCategory],
				this.gtagLabel || 'Klik'
			);
		}
	}
}
