@if (('RecommendationReason' | hasFeature) && description) {
	<span class="has-Tooltip" ngxTooltipPosition="above" [ngxTooltip]="description">
		<ng-template [ngTemplateOutlet]="content" />
	</span>
} @else {
	<span>
		<ng-template [ngTemplateOutlet]="content" />
	</span>
}

<ng-template #content>
	<ng-content></ng-content>
</ng-template>
