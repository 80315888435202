import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { VlaioDownloadingPipe } from '@vlaio/shared/utils';

import { VlaioIconComponent } from '../../icon';
import { VlaioEventLinkComponent } from '../event-link/event-link.component';
import { LinkStyleType } from '../shared';

@Component({
	selector: 'vlaio-download-link',
	template: `
		<vlaio-event-link [linkStyle]="linkStyle" (linkClicked)="linkClicked.emit()">
			<ng-content> </ng-content>

			<vlaio-icon class="primary-color" [icon]="(fileId | downloading) ? 'fa-spinner fa-spin' : 'fa-download'" />
		</vlaio-event-link>
	`,
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [VlaioEventLinkComponent, VlaioDownloadingPipe, VlaioIconComponent]
})
export class VlaioDownloadLinkComponent {
	/**
	 * The id of the file that is downloading
	 */
	@Input({ required: true }) public fileId: string;

	/**
	 * The style of the link.
	 *
	 * Default value is `styled`.
	 */
	@Input() public linkStyle: LinkStyleType = 'styled';

	/**
	 * Emits when the link is clicked.
	 */
	@Output() public linkClicked: EventEmitter<void> = new EventEmitter<void>();
}
