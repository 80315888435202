/**
 * Will turn any date string into an ISO and strip the time
 *
 * @param date - Date string to parse
 *
 * @returns date in format `YYYY-MM-DD` or `null` if an error occured
 */
export function getFormatDate(date: string): string | null {
	if (typeof date !== 'string') {
		return null;
	}
	try {
		return new Date(date).toISOString().split('T')[0];
	} catch (err: unknown) {
		// Wouter: if date param is not a valid date string
		return null;
	}
}
