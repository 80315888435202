import { Component } from '@angular/core';
import { NgxModalAbstractComponent } from '@studiohyperdrive/ngx-inform';
import { FocusClickDirective } from '@studiohyperdrive/ngx-utils';

import { CypressTagDirective } from '@vlaio/cypress/core';
import {
	VlaioIconComponent,
	VlaioDisclaimerComponent,
	VlaioButtonComponent,
	VlaioExternalLinkComponent
} from '@vlaio/shared/ui/common';

import { VlaioVideoModalDataEntity } from './video-modal.types';

@Component({
	selector: 'vlaio-video-modal',
	templateUrl: './video-modal.component.html',
	styleUrl: './video-modal.component.scss',
	standalone: true,
	imports: [
		VlaioIconComponent,
		VlaioDisclaimerComponent,
		VlaioButtonComponent,
		FocusClickDirective,
		VlaioExternalLinkComponent,
		CypressTagDirective
	],
	host: { class: 'c-modal-container' }
})
export class VlaioVideoModalComponent extends NgxModalAbstractComponent<
	'Cookies' | 'Close',
	VlaioVideoModalDataEntity
> {}
