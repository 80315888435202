import { FacetEntity, NonRefinableFacetSubjectEntity } from '@vlaio/shared/types';

/**
 * Filter out all the facets that have no results and are unchecked
 *
 * @export
 * @param facets - An array of facets
 */
export function filterFacetsOnAmountChecked(facets: FacetEntity[]): FacetEntity[] {
	const result = [];

	// Iben: Loop over all facets
	for (const facet of facets) {
		// Iben: In case the facet is not refinable, we can directly filter the items and sort the items
		if (!facet.isRefinable) {
			result.push({
				...facet,
				items: facet['items']
					.filter((item: NonRefinableFacetSubjectEntity) => item.checked || item.amount > 0)
					.sort((a, b) => {
						// Iben: If both are checked, we sort by name
						if (!a['checked'] && !b['checked']) {
							return;
						} else if (a['checked'] === b['checked']) {
							return a.name.localeCompare(b.name);
						}

						// Iben: Check the checked state
						return a['checked'] ? -1 : 1;
					})
			});

			continue;
		}

		// Iben: In case the facet is refinable, we filter the refinements
		const items = [];

		for (const subFacet of facet.items) {
			const filteredSubFacet = {
				...subFacet,
				refinements: subFacet['refinements'].filter(
					(item: NonRefinableFacetSubjectEntity) => item.checked || item.amount > 0
				)
			};

			// Iben: In case there are no refinements for the subfacet, we don't add it to the items array
			if (filteredSubFacet.refinements.length > 0) {
				items.push(filteredSubFacet);
			}
		}

		result.push({
			...facet,
			items
		});
	}

	return result;
}
