import { Injectable } from '@angular/core';
import { NgxI18nService } from '@studiohyperdrive/ngx-i18n';
import { ObservableString } from '@studiohyperdrive/rxjs-utils';

import { AbstractTranslationProviderService } from '@vlaio/shared/core';

@Injectable()
export class TranslationProviderService extends AbstractTranslationProviderService {
	constructor(private readonly i18nService: NgxI18nService) {
		super();
	}

	public getTranslation(label: string): ObservableString {
		return this.i18nService.getTranslationObservable(label);
	}
}
