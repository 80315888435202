import { ELoketRoutePaths, MandatesRoutePaths } from '@vlaio/shared/route-paths';

/**
 * Checks if the router url is allowed to bypass any of the user based guards
 *
 * @param routerUrl - The provided url
 */
export const bypassGuard = (routerUrl: string): boolean => {
	// Iben: A list of all urls we'll allow to bypass the guards as the guard on the url will handle the flow
	const allowedUrls: string[] = [`${ELoketRoutePaths.Mandates}/${MandatesRoutePaths.Validation}`];

	// Iben: Check if the provided url is part of the list
	return allowedUrls.some((url) => routerUrl.includes(url));
};
