import { inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ApplicationMenuLink, GlobalHeaderClient, ProfileConfig } from '@govflanders/vl-widget-global-header-types';
import { first, from, fromEvent, Observable, of, retry, switchMap, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { BrowserService } from './browser.service';

@Injectable({
	providedIn: 'root'
})
export class InjectAcmComponentsService {
	/**
	 * Inject the required services
	 */
	private rendererFactory: RendererFactory2 = inject(RendererFactory2);
	private renderer: Renderer2 = this.rendererFactory.createRenderer(null, null);
	private browserService: BrowserService = inject(BrowserService);

	/**
	 * initHeader
	 *
	 * This method initializes the VO Global Header widget and sets optional applicationMenuLinks.
	 *
	 * @param selector{string} - The selector of the element where the header widget should be mounted.
	 * @param config{object} - The configuration object
	 * @param config.url{string} - The URL of the widget (e.g. 'https://<environment>.widgets.burgerprofiel.vlaanderen.be/')
	 * @param config.id{string} - The ID of the widget (e.g. '7502b557-5d49-4ab3-9995-168718b81be5')
	 * @param config.profile{ProfileConfig} - The profile configuration object
	 * @param links{ApplicationMenuLink[]} - Optional application menu links
	 *
	 * @returns Observable<GlobalHeaderClient> - The GlobalHeaderClient object will be returned to allow for custom implementations that are not supported out of the box, see: https://test.widgets.burgerprofiel.dev-vlaanderen.be/docs/global-header/ for more information.
	 */
	public initHeader({
		selector,
		config,
		links
	}: {
		selector: string;
		config: { url: string; id: string; profile: ProfileConfig };
		links?: ApplicationMenuLink[];
	}): Observable<GlobalHeaderClient> {
		// Denis: The following code should only run in the browser.
		return this.browserService.runInBrowser(({ browserDocument, browserWindow }) => {
			// Denis: Create a new script tag
			const script = this.renderer.createElement('script');

			// Denis: Set up the script to load the header widget
			script.src = config.url + 'api/v2/widget/' + config.id + '/entry';
			script.type = 'text/javascript';

			// Denis: Append the script to the DOM to load it.
			this.renderer.appendChild(browserDocument.head, script);

			return fromEvent(script, 'load').pipe(
				first(),
				switchMap(() => {
					// Denis: Mount the header widget
					const headerEl: HTMLElement = browserDocument.querySelector(selector);

					if (typeof headerEl === 'undefined' || headerEl === null) {
						return throwError('Global Header: targetElement not found');
					}

					return of(headerEl);
				}),
				retry({
					count: 5,
					delay: 100
				}),
				switchMap((headerEl: HTMLElement) => {
					// Denis: Get the GlobalHeaderClient object
					const headerClient: GlobalHeaderClient = browserWindow.globalHeaderClient;

					return from(headerClient.mount(headerEl));
				}),
				switchMap(() => {
					// Denis: If there are no links, fallback to the default links
					if (!Array.isArray(links) || !links.length) {
						return of(null);
					}

					// Denis: Get the GlobalHeaderClient object
					const headerClient: GlobalHeaderClient = browserWindow.globalHeaderClient;

					// Denis: Set the optional application menu links
					return from(headerClient.accessMenu.setApplicationMenuLinks(links));
				}),
				switchMap(() => {
					// Denis: Get the GlobalHeaderClient object
					const headerClient: GlobalHeaderClient = browserWindow.globalHeaderClient;

					// Denis: Set the provided ProfileConfig
					return headerClient.accessMenu.setProfile(config.profile);
				}),
				map(() => browserWindow.globalHeaderClient)
			);
		});
	}
}
