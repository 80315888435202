import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';

import { BrowserService } from '@vlaio/shared/core';
import { isValidHint } from '@vlaio/shared/utils';
import { environment } from 'environments';

/**
 * A guard to handle the login hint flow
 *
 * @param route - The current route state
 */
export const LoginHintGuard: CanActivateFn = (route: ActivatedRouteSnapshot): boolean => {
	const browserService: BrowserService = inject(BrowserService);

	// Iben: Check if we have a login hint
	const hasHint = route.queryParamMap.has('login_hint');

	// Iben: If there is no hint, we continue. If the user navigates to a route where they have to be authenticated,
	// the authentication guard will prevent them from going forward
	if (!hasHint) {
		return true;
	}

	// Iben: get hint
	const hint = route.queryParamMap.get('login_hint');

	// Iben: Early exit in case the login hint is not valid
	if (!isValidHint(hint)) {
		return true;
	}

	// Iben: Redirect to login hint
	browserService.runInBrowser(({ browserWindow }) => {
		browserWindow.location.href = `${environment.api.protocol}://${environment.api.hostname}${environment.acmidm.directedLoginPath}?login_hint=${hint}`;
	});

	// Iben: Return true because we want to continue
	return true;
};
