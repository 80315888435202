import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot } from '@angular/router';
import { NgxI18nService } from '@studiohyperdrive/ngx-i18n';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { of } from 'rxjs';

import { isWizardSubroute } from '../helpers';
import { ExitWizardModalI18nKeysEntity } from '../interfaces';
import { ExitWizardService } from '../services';

const getInjectData = () => {
	const i18nService: NgxI18nService = inject(NgxI18nService);
	const exitService: ExitWizardService = inject(ExitWizardService);

	return {
		i18nService,
		exitService
	};
};

export const canExitWizard: CanDeactivateFn<unknown> = (
	component: unknown,
	currentRoute: ActivatedRouteSnapshot,
	currentState: RouterStateSnapshot,
	nextState: RouterStateSnapshot
): ObservableBoolean => {
	const { i18nService, exitService } = getInjectData();

	// Wouter: Get translations
	const i18nRouteData = currentRoute.data.i18nKeys;
	const i18nValues: ExitWizardModalI18nKeysEntity = {
		title: i18nService.getTranslation(i18nRouteData.title),
		description: i18nService.getTranslation(i18nRouteData.description),
		cancel: i18nService.getTranslation(i18nRouteData.cancel),
		exit: i18nService.getTranslation(i18nRouteData.exit),
		modalLabel: i18nService.getTranslation(i18nRouteData.modalLabel)
	};

	// Wouter: If we navigate withing the wizard, we allow navigation.
	if (isWizardSubroute(currentRoute, nextState)) {
		return of(true);
	}

	// Wouter: In case we navigate outside of the wizard, we will let the
	// ExitModalService know so they can take the appropriate action.
	return exitService.handleModal(i18nValues, currentRoute.data.exitWizardComponent);
};
