import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { StoreSlice } from '@vlaio/shared/store';

import { reducers } from './notifications.store';
import { NotificationsApiService, NotificationsService } from './services';

@NgModule({
	// TODO: Remove HTTPClientModule when API spec is ready
	imports: [StoreModule.forFeature(StoreSlice.Notifications, reducers)],
	providers: [NotificationsApiService, NotificationsService]
})
export class NotificationsDataModule {}
