import { EnvironmentProviders, importProvidersFrom, NgModule, Provider } from '@angular/core';

/**
 * Create a data provider from the given providers.
 *
 * @param providers The providers to create a data provider from.
 * @returns An array of providers to be used in standalone applications.
 */
export const createDataProvider =
	(...providers: any[]): (() => (Provider | EnvironmentProviders)[]) =>
	() => {
		return providers;
	};

/**
 * Replace the `@NgModule` decorator with this method to convert it to a standalone data provider.
 *
 * @usageNotes
 * ### Before
 * ```
 * @NgModule({
 *    imports: [StoreModule.forFeature(StoreSlice.Survey, reducers)],
 *       providers: [SurveyApiService, SurveyService]
 *   })
 * ```
 * ### After
 * ```
 * const provideSurveyData = createDataProviderFromModule({
 *     imports: [StoreModule.forFeature(StoreSlice.Survey, reducers)],
 *     providers: [SurveyApiService, SurveyService]
 * });
 * ```
 *
 * @param module The NgModule configuration to convert to a data provider.
 * @returns An array of providers in which the module's imports are wrapped in the `importProvidersFrom` function.
 */
export const createDataProviderFromModule =
	(module: NgModule): (() => (Provider | EnvironmentProviders)[]) =>
	() => {
		return [importProvidersFrom(...(module?.imports || [])), ...(module?.providers || [])];
	};
