@if (toolTip) {
	<i
		[ngxTooltip]="toolTip"
		[ngxTooltipPosition]="toolTipPosition"
		[ngxTooltipDisabled]="!toolTip"
		[class]="'u-outline fa-sharp ' + iconType + ' ' + icon"
	></i>
} @else {
	<i [class]="'fa-sharp ' + iconType + ' ' + icon"></i>
}
