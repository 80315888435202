import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HttpInterceptorProviders } from './interceptors';

@NgModule({
	imports: [CommonModule],
	providers: [HttpInterceptorProviders]
})
export class SharedUtilsModule {}
