import { provideWithTranslations, NgxI18nTranslationLoaderGuard } from '@studiohyperdrive/ngx-i18n';

import { AppRoutePaths, RedirectsPaths, VlaioRoutes } from '@vlaio/shared/route-paths';

import { AuthenticationFailedGuard, isAuthenticationSuccessGuard, MaintenanceGuard, OfflineGuard } from './guards';
import { RedirectsTranslationLoader } from './translation.loader';

export const RedirectRoutes: VlaioRoutes = [
	provideWithTranslations(
		{
			path: AppRoutePaths.Empty,
			canActivate: [NgxI18nTranslationLoaderGuard],
			children: [
				{
					path: AppRoutePaths.Empty,
					pathMatch: 'full',
					redirectTo: RedirectsPaths.Maintenance
				},
				{
					path: RedirectsPaths.AuthenticationFailed,
					canActivate: [AuthenticationFailedGuard],
					canDeactivate: [isAuthenticationSuccessGuard],
					loadComponent: () => import('./pages').then((m) => m.AuthenticationFailedPageComponent)
				},
				{
					path: RedirectsPaths.Maintenance,
					canActivate: [MaintenanceGuard],
					loadComponent: () => import('./pages').then((m) => m.MaintenancePageComponent)
				},
				{
					path: RedirectsPaths.NoResources,
					canActivate: [],
					loadComponent: () => import('./pages').then((m) => m.NoResourcesComponent)
				},
				{
					path: RedirectsPaths.Offline,
					canActivate: [OfflineGuard],
					loadComponent: () => import('./pages').then((m) => m.OfflinePageComponent)
				},
				{
					path: RedirectsPaths.DesktopOnly,
					loadComponent: () => import('./pages').then((m) => m.DesktopOnlyComponent)
				}
			]
		},
		RedirectsTranslationLoader
	)
];
