import { Injectable } from '@angular/core';
import { WindowService } from '@studiohyperdrive/ngx-utils';
import { ObservableNumber } from '@studiohyperdrive/rxjs-utils';
import { getParser } from 'bowser';
import browserUpdate from 'browser-update';

import { BrowserUpdateMessageEntity } from '../interfaces';

@Injectable({
	providedIn: 'root'
})
export class BrowserService {
	constructor(private readonly windowService: WindowService) {}

	/**
	 * Get the current scroll position
	 */
	public getCurrentScrollPosition(): number {
		// Iben: Early exit if we're not in the browser
		if (!this.isBrowser) {
			return;
		}

		// Iben: Return the scroll position
		return this.windowService.window.scrollY;
	}

	/**
	 * Scroll to the provided position
	 *
	 * @param offset - The provided position to scroll to relative from the top
	 */
	public scrollToPosition(offset: number) {
		// Iben: Early exit if we're not in the browser
		if (this.isBrowser) {
			return;
		}

		// Iben: Scroll to the position
		this.windowService.window.scrollTo({ top: offset });
	}

	/**
	 * Scroll to the top of the page
	 */
	public scrollToTop() {
		// Iben: Early exit if we're not in the browser
		if (!this.isBrowser) {
			return;
		}

		// Iben: Scroll to the top
		this.windowService.scrollTo(0);
	}

	/**
	 * Scroll to the element with the provided id.
	 *
	 * @param id - The id that needs to be scrolled to. The element that needs to be scrolled to, requires
	 * a set tabindex.
	 */
	public scrollToId(id: string): void {
		const element = this.document.getElementById(id);

		if (this.isBrowser && element) {
			element.scrollIntoView({ behavior: 'smooth' });
			element.focus();
		}
	}

	/**
	 * Run a provided function only when we're in the browser and not in a server side rendered application
	 *
	 * @param action - Function we want to run in the browser
	 */
	public runInBrowser(action: (data: { browserWindow: Window; browserDocument: Document }) => void) {
		if (this.isBrowser) {
			action({ browserWindow: this.window, browserDocument: this.document });
		} else {
			console.warn('Browser depended function has not run.');
		}
	}

	/**
	 * Whether we're in the browser or on the server
	 */
	public get isBrowser(): boolean {
		return this.windowService.isBrowser();
	}

	/**
	 * Returns the window object
	 */
	private get window() {
		return this.windowService.window;
	}

	/**
	 * Returns the document object
	 */
	private get document() {
		return this.windowService.document;
	}

	/**
	 * Return the width of the browser window
	 */
	public get width$(): ObservableNumber {
		return this.windowService.width$;
	}

	/**
	 * Sets up the browser warning mechanic so users with older browsers are notified
	 *
	 * @param warning - The text we wish to show in the warning
	 * @param url - The url we wish to redirect to when clicking on the Update button
	 */
	public handleBrowserVersion(warning: BrowserUpdateMessageEntity, url: string): void {
		this.runInBrowser(() => {
			// Iben: We want every user to be max 3 versions behind
			// Wouter: The toast uses the parent selector, so FF needs to be up to date
			browserUpdate({
				required: {
					e: -3,
					c: -3,
					f: 0,
					s: -3
				},
				// Iben: Remind the user again in 30 days
				reminderClosed: 720,
				text: {
					msg: warning.title,
					bupdate: warning.update,
					bignore: warning.ignore
				},
				test: localStorage.getItem('showBrowserUpdate')
					? Boolean(JSON.parse(localStorage.getItem('showBrowserUpdate')))
					: false,
				style: 'bottom',
				url,
				newwindow: false
			});
		});
	}

	/**
	 * Returns the name of the browser and it's version
	 */
	public getBrowserVersion(): string {
		// Iben: If we're on the server, we return server
		if (!this.isBrowser) {
			return 'Server';
		}

		// Iben: Return the browser and its version
		const browser = getParser(this.window.navigator.userAgent);

		return `${browser.getBrowserName()} ${browser.getBrowserVersion()}`;
	}
}
