import { Injectable } from '@angular/core';

@Injectable()
export class GtagDirectiveLabelProviderService {
	private gtagSelectKey: string;
	/**
	 * An optional key to rewrite the id key from the provided data
	 */
	private gtagSelectIdKey: string = 'id';
	/**
	 * The provided data we need to search through to find the actual label to send to GA
	 */
	private gtagSelectData: unknown[];

	/**
	 * Gets the GTAG label based on the selected value
	 *
	 * @param value - The selected value
	 */
	public getSelectLabel(value: string): string {
		if (this.gtagSelectData && value) {
			// Iben: Find the item of which we want to send a label to GA based on the matching value and matching key
			const result = this.gtagSelectData.find((item) => item[this.gtagSelectIdKey] === value);

			// Iben: Update the host directive so it has a label to send to GA
			return this.gtagSelectKey ? result[this.gtagSelectKey] : result;
		}

		return '';
	}

	/**
	 * Sets the required configuration for the provider service
	 *
	 * @param configuration - Configuration for the provider service
	 */
	public setSelectData(configuration: { key?: string; idKey?: string; data: unknown[] }) {
		this.gtagSelectKey = configuration.key;
		this.gtagSelectIdKey = configuration.idKey || 'id';
		this.gtagSelectData = configuration.data;
	}
}
