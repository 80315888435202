import { Injectable, OnDestroy } from '@angular/core';
import { ObservableBlob, ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { FileSaverService } from 'ngx-filesaver';
import { BehaviorSubject, concatMap, finalize, map, of, Subject, take, takeUntil, tap } from 'rxjs';

import { VlaioDownloadEvent, VlaioDownloadRequest } from '../../types';

/**
 * A shared service that will save a file to the system and hold track of the currently downloading files
 */
@Injectable({ providedIn: 'root' })
export class VlaioDownloadService implements OnDestroy {
	/**
	 * A subject to hold the destroyed state of the service
	 */
	private destroyedSubject: Subject<void> = new Subject();

	/**
	 * A subject to hold the currently downloading items
	 */
	private readonly downloadingSubject: BehaviorSubject<Record<string, boolean>> = new BehaviorSubject({});

	/**
	 * A subject to hold events to update the downloading subject
	 */
	private readonly updateDownloadingSubject: Subject<VlaioDownloadEvent> = new Subject();

	constructor(private readonly fileSaverService: FileSaverService) {
		// Iben: Listen to newly starting/ending downloads
		this.updateDownloadingSubject
			.pipe(
				// Iben: Concat so that each event is handled one by one to prevent the subject from updating at the same time
				concatMap((value) => {
					// Iben: Update the downloading subject
					this.downloadingSubject.next({
						...this.downloadingSubject.getValue(),
						[value.id]: value.downloading
					});

					// Iben: Return the value and immediately close it for the concatMap
					return of(value).pipe(take(1));
				}),
				takeUntil(this.destroyedSubject)
			)
			.subscribe();
	}

	/**
	 * Save an BLOB request to the file system and keep track of the downloading state
	 *
	 * @param data - The file we wish to download
	 * @param request - Extra data to track the download
	 */
	public download(data: ObservableBlob, request: VlaioDownloadRequest): ObservableBlob {
		// Iben: Mark the download as started
		this.updateDownloadingSubject.next({
			id: request.id,
			downloading: true
		});

		return data.pipe(
			// Iben: Save the file after downloading it
			tap((result) => {
				this.fileSaverService.save(result.blob, `${request.filename}.${result.fileType}`);
			}),
			finalize(() => {
				// Iben: Mark the file as downloaded
				this.updateDownloadingSubject.next({
					id: request.id,
					downloading: false
				});
			}),
			// Iben: Stop the download when a user closes the tab
			takeUntil(this.destroyedSubject)
		);
	}

	/**
	 * Returns a downloading Observable based on the provided id
	 *
	 * @param id - The id of the file we are downloading
	 */
	public isDownloading(id: string): ObservableBoolean {
		return this.downloadingSubject.pipe(
			map((value) => {
				return Boolean(value[id]);
			}),
			takeUntil(this.destroyedSubject)
		);
	}

	/**
	 * Complete the downloading subject
	 */
	ngOnDestroy(): void {
		this.destroyedSubject.next();
		this.destroyedSubject.complete();
	}
}
