import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { VlaioIconComponent } from '../icon';

@Component({
	selector: 'vlaio-accordion-title',
	templateUrl: './accordion-title.component.html',
	styleUrl: './accordion-title.component.scss',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [VlaioIconComponent]
})
export class VlaioAccordionTitleComponent {
	/**
	 * Whether the accordion item is open
	 */
	@Input() public open: boolean = false;
}
