<!-- Wouter: Add a border at the top of the component -->
<hr />

<h4 cypressTag="Shared.PartnerDetail.Name">
	{{ partner }}

	@for (item of contactItems?.serviceNames || []; track item) {
		- {{ item }}
	}
</h4>

@for (item of contactItems?.personNames || []; track item) {
	<p>
		{{ item }}
	</p>
}

@for (item of contactItems?.addresses || []; track item) {
	<p>
		<vlaio-address format="one-line" cypressTag="Shared.PartnerDetail.Address" [address]="item" />
	</p>
}

@if (contactItems?.phoneNumbers?.length || contactItems?.emailAddresses?.length) {
	<p>
		@for (item of contactItems?.phoneNumbers || []; track item) {
			<!-- Wouter: To make StoryBook work, we must add a title property here. Remove it when done with the story. -->
			<vlaio-contact-link contactType="tel" cypressTag="Shared.PartnerDetail.Phone" [link]="item" />
		}

		@for (item of contactItems?.emailAddresses || []; track item) {
			<vlaio-contact-link
				contactType="mail"
				cypressTag="Shared.PartnerDetail.Mail"
				[mailData]="mailData"
				[link]="item"
			/>
		}
	</p>
}

@if (contactItems?.websites) {
	@for (item of contactItems.websites; track item) {
		<p>
			<vlaio-contact-link
				class="contact-website"
				cypressTag="Shared.PartnerDetail.Website"
				contactType="web"
				[link]="item.url"
				[title]="i18nKeys.Links.Contact.Web | translate: { link: item?.text || item.url }"
			/>
		</p>
	}
}
