<details *mediaQuery="mediaQueries.MediaQueryMax.Desktop" class="c-filters-details" #details [cypressTag]="cypressTag">
	<summary class="u-outline" [title]="title">
		<span>
			<span class="icon-filter"></span>

			<span class="c-filters-details__button-label"> {{ i18nKeys.Actions.Filter | translate }} </span>

			<span class="c-filters-details__button__arrow icon-arrow-down"></span>
		</span>
	</summary>

	<div class="c-filters-details__content">
		<ng-container *ngTemplateOutlet="contentTmpl"></ng-container>
	</div>
</details>

<ng-container *mediaQuery="mediaQueries.MediaQueryMin.Desktop">
	<ng-container *ngTemplateOutlet="contentTmpl"></ng-container>
</ng-container>
