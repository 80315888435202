import { ChangeDetectorRef, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { BrowserService } from '@vlaio/shared/core';

@Directive({
	selector: '[isBrowser]',
	standalone: true
})
export class isBrowserDirective {
	constructor(
		private readonly viewContainer: ViewContainerRef,
		private readonly templateRef: TemplateRef<unknown>,
		private readonly browserService: BrowserService,
		private readonly cdRef: ChangeDetectorRef
	) {}

	/**
	 * Whether we wish to render a item only when in the browser
	 *
	 * @memberof isBrowserDirective
	 */
	@Input({ required: true }) public set isBrowser(shouldBeInBrowser: boolean) {
		// Iben: Handle the template
		this.handleTemplate(shouldBeInBrowser);
	}

	/**
	 * Handle the template rendering based on the provided input
	 *
	 * @param shouldBeInBrowser - Whether we wish to render an item only when in the browser
	 */
	private handleTemplate(shouldBeInBrowser: boolean) {
		// Iben: Render the item if we're in the correct condition
		if (
			(this.browserService.isBrowser && shouldBeInBrowser) ||
			(!shouldBeInBrowser && !this.browserService.isBrowser)
		) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			// Iben: Clear the view in case we don't meet the condition
			this.viewContainer.clear();
		}

		// Iben: We trigger a change detection cycle to make sure that onPush components also work with this directive
		this.cdRef.detectChanges();
	}
}
