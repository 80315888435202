import { ScreenSize } from '@vlaio/cypress/shared';

// Wouter: Add the extra pixel to make sure no mediaqueries share a same screen
// size as the MediaQueryMax
export const MediaQueryMin = Object.freeze({
	MobileSmall: `(min-width: ${ScreenSize.MobileSmall + 1}px)`,
	Mobile: `(min-width: ${ScreenSize.Mobile + 1}px)`,
	Tablet: `(min-width: ${ScreenSize.Tablet + 1}px)`,
	TabletWide: `(min-width: ${ScreenSize.TabletWide + 1}px)`,
	Desktop: `(min-width: ${ScreenSize.Desktop + 1}px)`,
	DesktopWide: `(min-width: ${ScreenSize.DesktopWide + 1}px)`,
	DesktopWideExtended: `(min-width: ${ScreenSize.DesktopWideExtended + 1}px)`,
	DesktopUltraWide: `(min-width: ${ScreenSize.DesktopUltraWide + 1}px)`,
	Desktop2k: `(min-width: ${ScreenSize.Desktop2k + 1}px)`
});

export const MediaQueryMax = Object.freeze({
	MobileSmall: `(max-width: ${ScreenSize.MobileSmall}px)`,
	Mobile: `(max-width: ${ScreenSize.Mobile}px)`,
	Tablet: `(max-width: ${ScreenSize.Tablet}px)`,
	TabletWide: `(max-width: ${ScreenSize.TabletWide}px)`,
	Desktop: `(max-width: ${ScreenSize.Desktop}px)`,
	DesktopWide: `(max-width: ${ScreenSize.DesktopWide}px)`,
	DesktopWideExtended: `(max-width: ${ScreenSize.DesktopWideExtended}px)`,
	DesktopUltraWide: `(max-width: ${ScreenSize.DesktopUltraWide}px)`,
	Desktop2k: `(min-width: ${ScreenSize.Desktop2k}px)`
});
