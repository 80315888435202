import { Injectable } from '@angular/core';

import { AbstractScriptsProviderService } from '@vlaio/shared/core';

@Injectable()
export class ScriptsProviderService extends AbstractScriptsProviderService {
	public get scripts(): Record<'name' | 'url', string>[] {
		return [
			{
				name: 'Widget Script Burgerprofiel Prod',
				url: 'https://prod.widgets.burgerprofiel.vlaanderen.be/api/v1/node_modules/@govflanders/vl-widget-polyfill/dist/index.js'
			},
			{
				name: 'Widget Script Burgerprofiel TNI',
				url: 'https://tni.widgets.burgerprofiel.dev-vlaanderen.be/api/v1/node_modules/@govflanders/vl-widget-polyfill/dist/index.js'
			}
			// Wouter: This is temporarily removed, see ELO-810
			// {
			// 	name: 'Hotjar',
			// 	url: 'assets/scripts/hotjar.js'
			// }
		];
	}
	public get gaScripts(): Record<'initScript' | 'gaScript', string> {
		return {
			gaScript: 'https://www.googletagmanager.com/gtag/js',
			initScript: 'assets/scripts/gtag.js'
		};
	}
}
