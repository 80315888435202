<span class="checkbox-wrapper">
	<input
		class="u-outline"
		#input
		type="checkbox"
		cypressTag="General.Input.Checkbox"
		[class.indeterminate]="isPartialChecked"
		[id]="id"
		[checked]="isChecked"
		[disabled]="disabled"
		[title]="title || i18nKeys.Facet.Checkbox.Title | translate: { label }"
		(keydown.enter)="enterPressed()"
		(click)="inputClicked.emit(input.checked)"
		(change)="onChange($any($event).target.checked)"
		(blur)="onTouched()"
	/>

	@if (!isPartialChecked) {
		<vlaio-icon iconType="fa-regular" icon="fa-check" />
	}
</span>

<label [attr.for]="(labelClicked | hasObservers) ? '' : id" [title]="label" (click)="labelClicked.emit()">
	<ng-content></ng-content>
</label>
