import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';

import { environment } from 'environments';

@Injectable({
	providedIn: 'root'
})
export class SessionService {
	// Dependency injection
	constructor() {
		// Create a sessionUUID for identifying anonymous users.
		this.uuid = this.getSessionItem('uuid');

		if (!this.uuid) {
			this.uuid = UUID.UUID();
			this.setSessionItem('uuid', this.uuid);
		}
	}

	private STORAGE_PREFIX = 'vlaio';

	private uuid: string;

	public setSessionItem = (key: string, value) => {
		if (sessionStorage) {
			try {
				sessionStorage.setItem(this.STORAGE_PREFIX + '.' + key, value);
			} catch (e) {
				if (e.code === 22) {
					sessionStorage.clear();
				}
			}
		}
	};

	public getSessionItem = (key: string) => {
		if (sessionStorage) {
			try {
				return sessionStorage.getItem(this.STORAGE_PREFIX + '.' + key);
			} catch (e) {
				if (e.code === 22) {
					sessionStorage.clear();
				}
			}
		}
	};

	// User UUID
	// ---
	get userUUID() {
		return this.uuid;
	}

	// Get the local domain
	getLocalDomain = () => {
		return environment.domain + '/';
	};

	// GeolocationService
	getLocation = () => {
		return new Promise(function (resolve, reject) {
			if ('geolocation' in navigator) {
				navigator.geolocation.getCurrentPosition(
					(position) => {
						resolve(position);
					},
					() => {
						reject();
					}
				);
			} else {
				reject();
			}
		});
	};

	getACMIDMDomain = () => {
		return environment.acmidm.protocol + '://' + environment.acmidm.hostname;
	};

	get api() {
		return environment.api.fullPath;
	}
}
