import { inject, Injectable, Renderer2 } from '@angular/core';

import { environment } from '~environment';

import { ScriptsEntity } from '../interfaces';

import { BrowserService } from './browser.service';

@Injectable({ providedIn: 'root' })
export class ScriptsService {
	/**
	 * Creates an instance of ScriptsService.
	 */
	private readonly browserService: BrowserService = inject(BrowserService);

	/**
	 * Returns a list of scripts we wish to attach to the dom
	 */
	private readonly scripts: ScriptsEntity[] = [
		{
			name: 'FontAwesome Custom Icon Kit',
			url: 'https://kit.fontawesome.com/043aef604d.js'
		}
	];

	/**
	 * Attaches all scripts to the dom when the dom is available (only in browser)
	 *
	 * @param renderer - Renderer used to attach the scripts to the dom
	 * @memberof ScriptsService
	 */
	public attachScriptsToDom(renderer: Renderer2) {
		// Iben: Loop over all scripts we need to add
		this.browserService.runInBrowser(({ browserDocument }) => {
			this.scripts.forEach((item) => {
				// Wouter: Skip loading the script if the environments doesn't match
				if (
					(item.env && !environment.production && item.env === 'prod') ||
					(item.env && environment.production && item.env === 'tni')
				) {
					return;
				}

				// Iben: Create a new script tag
				const script = renderer.createElement('script');
				script.type = 'text/javascript';
				script.src = item.url;

				// Iben: Log when the script was loaded correctly and log potential errors
				script.onload = () => {
					console.log(`Script ${item.name} was loaded dynamically`);
				};
				script.onerror = (err) => {
					console.error(`Script ${item.name} was not loaded. Check the error below for more info.`);
					console.dir(err);
				};

				// Iben: Attach script to the dom
				renderer.appendChild(browserDocument.head, script);
			});
		});
	}

	/**
	 * Attaches the questionnaire script to the dom and launches it
	 *
	 * @param renderer - The renderer
	 */
	public attachQuestionnaireScriptToDom(renderer: Renderer2) {
		// Iben: Add the script when we're in the browser
		this.browserService.runInBrowser(({ browserDocument }) => {
			// Iben: Create a new script tag
			const script = renderer.createElement('script');
			script.type = 'text/javascript';
			//TODO: Iben: Add the url to the script
			script.src = 'assets/scripts/questionnaire.js';

			// Iben: Log when the script was loaded correctly and log potential errors
			script.onload = () => {
				console.log(`Questionnaire script was loaded dynamically`);
			};
			script.onerror = (err) => {
				console.error(`The questionnaire script was not loaded. Check the error below for more info.`);
				console.dir(err);
			};

			// Iben: Attach script to the dom
			renderer.appendChild(browserDocument.body, script);
		});
	}
}
