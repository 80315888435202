import { HttpParams } from '@angular/common/http';
import { inject } from '@angular/core';
import { ObservableArray } from '@studiohyperdrive/rxjs-utils';
import { isEmpty } from 'lodash';
import { take, switchMap, map } from 'rxjs';

import { VlaioHttpClientService } from '@vlaio/shared/core';
import { FacetFilter, FacetEntity, FacetsResult } from '@vlaio/shared/types';
import { UserService } from '@vlaio/shared/user';
import { convertFacetFilterToFacetFilterResult, convertFacetResultToFacet } from '@vlaio/shared/utils';

import { FacetsEndpointInjectionToken } from '../abstracts';

export class FacetsApiService {
	/**
	 * The UserService to fetch the active login state
	 */
	private readonly userService: UserService = inject(UserService);

	/**
	 * The VLAIOHttpClient used to fetch data from the API
	 */
	private readonly httpClient: VlaioHttpClientService = inject(VlaioHttpClientService);

	/**
	 * The endpoint provided in the forChild method
	 */
	private readonly endpoint: string = inject(FacetsEndpointInjectionToken);

	/**
	 * Fetches the facets from the API
	 *
	 * @param filters - The selected filters
	 * @param searchQuery - An optional search query
	 */
	public getFacets(filters?: FacetFilter, searchQuery?: string): ObservableArray<FacetEntity> {
		// Iben: Fetch the current user to check whether they're authenticated
		return this.userService.user$.pipe(
			take(1),
			switchMap((user) => {
				// Iben: Set the authentication state
				const isAuthenticated = Boolean(user);

				// Iben: Convert the params to a backend friendly format
				const params = new HttpParams({
					fromObject: {
						...(!isEmpty(filters)
							? { filters: JSON.stringify(convertFacetFilterToFacetFilterResult(filters)) }
							: {}),
						...(searchQuery ? { zoek: searchQuery } : {})
					}
				});

				// Iben: Fetch the facets
				return this.httpClient.get<FacetsResult>(this.endpoint, params, isAuthenticated);
			}),
			map(({ elementen }) => elementen.map((facet) => convertFacetResultToFacet(facet)))
		);
	}
}
