import { EnvironmentProviders, Provider, importProvidersFrom } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { StoreSlice } from '@vlaio/shared/store';

import { UserResolver } from './resolvers';
import { UserApiService, UserService } from './services';
import { reducers } from './user.store';

export function provideUserData(): (Provider | EnvironmentProviders)[] {
	return [
		importProvidersFrom(StoreModule.forFeature(StoreSlice.User, reducers)),
		UserService,
		UserResolver,
		UserApiService
	];
}
