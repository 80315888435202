import { Pipe, PipeTransform } from '@angular/core';
import { NgxTableSortDirection } from '@studiohyperdrive/ngx-table';

@Pipe({
	name: 'sortIcon',
	standalone: true
})
export class SortIconPipe implements PipeTransform {
	public transform(sortDirection: NgxTableSortDirection | undefined) {
		// Iben: If no sort direction was provided, we return the default icon
		if (!sortDirection) {
			return '';
		}

		// Iben: If a sort direction was provided, we return the necessary icon
		return sortDirection === NgxTableSortDirection.DESCENDING ? 'is-descending' : 'is-ascending';
	}
}
