import { StoreModule } from '@ngrx/store';

import { createDataProviderFromModule } from '@vlaio/shared/core';
import { StoreSlice } from '@vlaio/shared/store';

import { reducers } from './notifications.store';
import { NotificationsApiService, NotificationsService } from './services';

export const provideNotificationsData = createDataProviderFromModule({
	imports: [StoreModule.forFeature(StoreSlice.Notifications, reducers)],
	providers: [NotificationsApiService, NotificationsService]
});
