import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';

import { BrowserService } from '../services';
/**
 * A guard that will prevent a module from being loaded if the user is not on a browser
 */
export const BrowserGuard: CanMatchFn = (): boolean => {
	// Iben: Fetch injectables
	const browserService: BrowserService = inject(BrowserService);

	// Iben: Only allow to module to be loaded when we're in the browser
	return browserService.isBrowser;
};
