import { animation, style, animate, keyframes } from '@angular/animations';

import { ANIMATION_DEFAULT_TIMING } from '../animations.enums';

export function fadeInAnimation() {
	return animation(
		animate(
			'{{ timing }}ms {{ delay }}ms',
			keyframes([
				style({
					opacity: 0
				}),
				style({
					opacity: 1
				})
			])
		),
		{ params: { timing: ANIMATION_DEFAULT_TIMING, delay: 0 } }
	);
}

export function fadeOutAnimation() {
	return animation(
		animate(
			'{{ timing }}ms {{ delay }}ms',
			keyframes([
				style({
					opacity: 1
				}),
				style({
					opacity: 0
				})
			])
		),
		{ params: { timing: ANIMATION_DEFAULT_TIMING, delay: 0 } }
	);
}
