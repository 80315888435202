import * as clean from 'obj-clean';

import { AddressEntity, AddressResultEntity } from '@vlaio/shared/types';

// Iben: A map for all the Walloons provinces
const walloonsMap = {
	walloonBrabant: [1300, 1499],
	// Iben: Contains Liège, Namur, Hanait and Luxembourg
	walloons: [4000, 7999]
};

export function convertAddressResultToAddress(result: Partial<AddressResultEntity>): AddressEntity {
	// Iben: Add an early exit for when the address does not exist
	if (!result) {
		return;
	}

	return clean({
		street: result.straat,
		number: result.nummer,
		zip: result.postcode,
		municipality: result.gemeente,
		...(result.bus ? { box: result.bus } : {}),
		...(result.land ? { country: result.land } : {}),
		isWalloons: isWalloons(result.postcode)
	});
}

const isWalloons = (zip: string): boolean => {
	// Iben: Convert the zip code to a number
	const zipCode = Number(zip);

	// Iben: Early exit for when the zip code is not a number
	if (isNaN(zipCode)) {
		return false;
	}

	// Iben: Check if the zip code is part of the walloonsMap
	let isWalloons = false;

	// Iben: Loop over the zipCodes using the for of loop so we can break if we met the first condition
	for (const zipCodes of Object.values(walloonsMap)) {
		isWalloons = zipCode >= zipCodes[0] && zipCode <= zipCodes[1];

		if (isWalloons) {
			break;
		}
	}

	return isWalloons;
};
