import { inject } from '@angular/core';
import {
	CanActivateFn,
	ActivatedRouteSnapshot,
	Router,
	RouterStateSnapshot,
	createUrlTreeFromSnapshot
} from '@angular/router';
import { NgxI18nService } from '@studiohyperdrive/ngx-i18n';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { of, tap } from 'rxjs';

import { ToastService } from '@vlaio/shared/ui/toast';

import { UserCompanyPropertyEntity, UserService } from '../../data';
import { I18nKeys } from '../../i18n';
import { bypassGuard } from '../helpers';

/**
 * Guard that checks if the logged in user has the correct company role. If they don't have the correct role, they get redirected.
 *
 * @param {ActivatedRouteSnapshot} routeSnapshot
 * @return {ObservableBoolean}
 */
export const HasCompanyRoleGuard: CanActivateFn = (
	routeSnapshot: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): ObservableBoolean => {
	// Wouter: Inject the necessary services
	const userService: UserService = inject(UserService);
	const router: Router = inject(Router);
	const companyRoles: UserCompanyPropertyEntity[] = routeSnapshot.data?.companyRoles || [];
	const shouldHaveAllCompanyRoles = routeSnapshot.data?.shouldHaveAllCompanyRoles;
	const i18nService: NgxI18nService = inject(NgxI18nService);
	const toastService: ToastService = inject(ToastService);

	// Wouter: If the url is left empty, the user is redirected to the dashboard
	const redirectTo: string[] = routeSnapshot.data?.redirect || ['../'];

	// Iben: If the provided url is part of the allowed urls, we continue
	if (bypassGuard(state.url)) {
		return of(true);
	}

	// Benoit: Return whether the user has the correct company role(s).
	return userService.userHasCompanyProperties(companyRoles, shouldHaveAllCompanyRoles).pipe(
		tap((hasCompanyRole) => {
			// Benoit: If the user doesn't have the correct company role, they get redirected to the redirect url
			if (!hasCompanyRole) {
				router.navigateByUrl(createUrlTreeFromSnapshot(routeSnapshot, redirectTo)).then(() => {
					// Wouter: Open a toast that the user is not allowed access.
					toastService.openFromComponent({
						type: 'negative',
						text: i18nService.getTranslation(I18nKeys.User.Fallback.Notification)
					});
				});
			}
		})
	);
};
