import { NgModule } from '@angular/core';

import { MediaQueryDirective, isBrowserDirective } from './directives';
import { ScreenSizeService } from './services/screen-size.service';

@NgModule({
	imports: [MediaQueryDirective, isBrowserDirective],
	providers: [ScreenSizeService],
	exports: [MediaQueryDirective, isBrowserDirective]
})
export class DeviceModule {}
