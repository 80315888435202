import { Pipe, PipeTransform } from '@angular/core';

import { CompanyAddressRecord, CompanyEntity } from '../../data';
import { getCompanyAddressRecord } from '../../data/utils';

@Pipe({
	name: 'companyAddressRecord',
	standalone: true
})
export class CompanyAddressRecordPipe implements PipeTransform {
	/**
	 * Converts a company to a record of addresses by branch number
	 *
	 * @param  value - The provided company
	 */
	public transform(value: CompanyEntity): CompanyAddressRecord {
		// Iben: If no value was provided, return an empty object
		if (!value) {
			return {};
		}

		// Iben: Return the record
		return getCompanyAddressRecord(value);
	}
}
