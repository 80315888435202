import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export abstract class OnDestroyComponent implements OnDestroy {
	protected readonly destroyed$ = new Subject();

	// Lifecycle Methods
	// ------------------------------------------------------------------------- /
	public ngOnDestroy(): void {
		this.destroyed$.next(undefined);
		this.destroyed$.complete();
	}
}
