import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgxModalAbstractComponent } from '@studiohyperdrive/ngx-inform';
import { of, take, tap } from 'rxjs';

import { VlaioLoaderComponent } from '@vlaio/shared/ui/common';

@Component({
	selector: 'vlaio-loading-modal',
	standalone: true,
	template: `
		<p>{{ data }}</p>
		<vlaio-loader />
	`,
	imports: [VlaioLoaderComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: { class: 'c-modal-container' },
	styleUrl: './loading-modal.component.scss'
})
export class VlaioLoadingModalComponent extends NgxModalAbstractComponent<'close', string> implements OnInit {
	public ngOnInit(): void {
		// Wouter: Emit to close immediately. The config has autoClose to `false`, so the modal will stay visible
		of(null)
			.pipe(
				take(1),
				tap(() => {
					this.action.emit('close');
				})
			)
			.subscribe();
	}
}
