import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TrackingApiService } from './tracking.api.service';

@Injectable()
export class TrackingService {
	constructor(private readonly apiService: TrackingApiService) {}

	/**
	 * Track an event in the backend
	 *
	 * @param event - Name of the event
	 */
	trackEvent(event: string): Observable<void> {
		return this.apiService.trackEvent(event);
	}
}
