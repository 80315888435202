import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { ELoketEndpoints } from '@vlaio/shared/endpoints';
import { createFacetStoreAssets, SharedFacetsDataModule } from '@vlaio/shared/facets';
import { StoreSlice } from '@vlaio/shared/store';

import { ProductApiService, ProductService } from './services';
import { reducers } from './store';

@NgModule({
	imports: [
		StoreModule.forFeature(StoreSlice.Products, reducers),
		SharedFacetsDataModule.forChild(
			ELoketEndpoints.Products.Facets(),
			`${StoreSlice.Products}-facets`,
			createFacetStoreAssets(`${StoreSlice.Products}-facets`)
		)
	],

	providers: [ProductService, ProductApiService]
})
export class ProductDataModule {}
