import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxI18nRootService } from '@studiohyperdrive/ngx-i18n';
import { pluckOr, ObservableString, ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { isArray } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';

import { AuthenticationService } from '@vlaio/shared/authentication';
import { spotlightProductsCallbackQueryParam, spotlightProductsQueryParam } from '@vlaio/shared/cookies';
import { VlaioHttpClientService } from '@vlaio/shared/core';
import { GTagService } from '@vlaio/shared/gtag';
import { AppRoutePaths, ELoketRoutePaths } from '@vlaio/shared/route-paths';
import { TrackingService } from '@vlaio/shared/tracking';
import { UserService } from '@vlaio/shared/user';

@Injectable()
export class SpotlightService {
	/**
	 * Amount of time the products in the spotlight will be visible, currently 4 hours
	 */
	private readonly productSpotlightExpirationTime = 14400;

	/**
	 * Subject to house the currently set spotlight products
	 */
	private readonly spotlightProductsSubject = new BehaviorSubject<string>('');

	/**
	 * Subject to hold whether the spotlight product could not be found
	 */
	private readonly spotlightProductNotFoundSubject = new BehaviorSubject<boolean>(false);

	/**
	 * The spotlight cookie
	 */
	private readonly spotlightProductsCookie = 'product-in-de-kijker';

	/**
	 * Currently set spotlight products
	 */
	public readonly spotlightProducts$: ObservableString = this.spotlightProductsSubject.asObservable();

	/**
	 * Whether the spotlight product could not be found
	 */
	public readonly spotlightProductsError$: ObservableBoolean = this.spotlightProductNotFoundSubject.asObservable();

	constructor(
		private readonly userService: UserService,
		private readonly activatedRoute: ActivatedRoute,
		private readonly router: Router,
		private readonly authenticationService: AuthenticationService,
		private readonly httpClientService: VlaioHttpClientService,
		private readonly gtagService: GTagService,
		private readonly trackingService: TrackingService,
		private readonly i18nRootService: NgxI18nRootService
	) {}

	/**
	 * Handles the spotlight products based on the logged in state of the user
	 *
	 * @return {*}  {Observable<any>}
	 * @memberof SpotlightService
	 */
	public handleSpotlightProduct(): Observable<any> {
		return combineLatest([
			this.userService.user$,
			this.activatedRoute.queryParams.pipe(
				// Iben: Only continue if there are params
				filter<any>(Boolean),
				// Iben: Get the query param we want
				pluckOr(spotlightProductsQueryParam, spotlightProductsCallbackQueryParam),
				filter<any>(Boolean)
			)
		]).pipe(
			switchMap(([user, spotlightProduct]) => {
				// Iben: If the user is not logged in, we redirect the user to the login flow with a callback with the product
				if (!Boolean(user)) {
					// Iben: We first navigate to the empty route. This makes sure that if the user decides to return back to the application without uploading
					// they are not stuck in an infinite loop to the login page
					this.router.navigate(['/', this.i18nRootService.currentLanguage]);

					// Iben: Login the user
					return this.authenticationService.login({
						customCallBack: { url: `/${this.i18nRootService.currentLanguage}/${AppRoutePaths.Loket}` },
						spotlightProducts: spotlightProduct
					});
				}

				// Iben: If the user is logged in, we perform the spotlight API call and redirect to the dashboard
				this.router.navigate([
					this.i18nRootService.currentLanguage,
					AppRoutePaths.Loket,
					ELoketRoutePaths.Dashboard
				]);

				// Iben: Check if the spotlight product is an array and parse it if necessary
				const waarde = spotlightProduct.includes('[') ? JSON.parse(spotlightProduct) : spotlightProduct;
				const geldigheid = this.productSpotlightExpirationTime;

				// Iben: Track the dashboard link
				this.gtagService.trackSpotlightProduct('Product ingeladen', isArray(waarde) ? waarde : [waarde]);

				// Iben: Set the notFoundSubject to false
				this.spotlightProductNotFoundSubject.next(false);

				return this.httpClientService
					.put(`gebruikerscontext/${this.spotlightProductsCookie}`, { waarde, geldigheid })
					.pipe(
						tap(() => {
							// Iben: Let anyone responsible for fetching the spotlight products know that new spotlight products can be fetched
							this.spotlightProductsSubject.next(spotlightProduct);
						}),
						switchMap(() => this.trackingService.trackEvent('dashboard_link')),
						// Iben: Ignore the error as it shouldn't stop users in their flow
						catchError(() => {
							// Iben: Set the notFoundSubject to true when it errors
							this.spotlightProductNotFoundSubject.next(true);

							return of();
						})
					);
			})
		);
	}
}
