import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ObservableArray, ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppRoutePaths } from '@vlaio/shared/route-paths';

@Injectable({
	providedIn: 'root'
})
export class NavigationService {
	/**
	 * A subject to keep track of the navigation history, so that we can manually pop the state.
	 */
	private readonly navigationHistorySubject$ = new BehaviorSubject<string[]>([]);

	/**
	 * A subject to keep track of whether we're on the restricted part of the e-loket.
	 *
	 * Default value is `true`, otherwise rerendering a restricted page will cause a flicker of the submenu, for example.
	 * This has no visible effect on the public part of the e-loket, so this is a clean solution for now.
	 */
	private readonly isRestrictedEloketSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	/**
	 * The navigation history of the application
	 */
	public readonly navigationHistory$: ObservableArray<string> = this.navigationHistorySubject$.asObservable();

	/**
	 * A signal to keep track of whether we're on the restricted part of the e-loket.
	 */
	public readonly isRestrictedEloket$: ObservableBoolean = this.isRestrictedEloketSubject.asObservable();

	constructor(
		private readonly router: Router,
		private readonly location: Location
	) {
		// Iben: Track the history of the navigation
		this.router.events
			.pipe(
				tap((event) => {
					// Iben: Only dispatch the end events
					if (event instanceof NavigationEnd) {
						this.navigationHistorySubject$.next([
							...this.navigationHistorySubject$.getValue(),
							event.urlAfterRedirects
						]);

						// Wouter: Keep track of the restricted part of the e-loket
						this.isRestrictedEloketSubject.next(
							event.urlAfterRedirects.includes('/' + AppRoutePaths.Loket + '/')
						);
					}
				})
			)
			.subscribe();
	}

	/**
	 * Navigate back into the navigation history
	 *
	 * @param amountOfSteps - The amount of steps we wish to return, default one.
	 */
	back(amountOfSteps: number = 1): void {
		// Iben: Get the current history minus the current page
		const history = this.navigationHistorySubject$.getValue();
		history.pop();

		// Iben: If we have no history we use the browser history
		if (history.length === 0) {
			this.location.back();
		} else {
			// Iben: Navigate back in the navigation history of the application
			this.router.navigateByUrl(history[history.length - amountOfSteps]);
		}
	}
}
