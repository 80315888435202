import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';

import { MetaService } from '@vlaio/shared/core';

/**
 * Pass routes in the `data` of a route if they should **not** be indexed.
 *
 * EX 1
 * ``` Typescript
 * data: {
 * 	preventIndex: {
 * 		routes: ['loket'] // All routes with `loket` will receive a `noindex` meta-tag
 * 	}
 * }
 * ```
 * EX 2
 * ``` Typescript
 * data: {
 * 	preventIndex: {
 * 		preventAll: true // Will override `routes` property if provided as well. `noindex` tag will be set on all children
 * 	}
 * }
 * ```
 * @returns `true` so that the guard will always allow passage
 */

export const IndexGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
	// Iben: Fetch all injectables
	const metaService: MetaService = inject(MetaService);

	/**
	 * Prevent all child routes from being crawled. This will block all children,
	 * thereby overriding the `routes` property.
	 */
	const preventAllRoutes: boolean = route.data?.preventIndex?.preventAll || false;
	/**
	 * Prevent only specified routes. This is inferior to the `preventAll` property
	 * which will override this setting.
	 */
	const routesToPrevent: string[] = route.data?.preventIndex?.routes || [];

	// Wouter: Remove tag as a safety to prevent double tags
	metaService.removeTagByName('robots');

	// Wouter: Allow the crawler if no data is provided
	if (!preventAllRoutes && !routesToPrevent.length) {
		return true;
	}

	// Wouter: Prevent crawler if preventAll is provided
	if (preventAllRoutes) {
		metaService.addTag('robots', 'noindex');
		return true;
	}

	// Wouter: Loop over the routes to check if the router contains the path passed in `routes`
	for (const route of routesToPrevent) {
		if (route && state.url.includes(route)) {
			// Wouter: Prevent crawler if provided route is in URL
			metaService.addTag('robots', 'noindex');
			return true;
		}
	}

	// Wouter: Allow crawler if routesToPrevent were not included in URL
	return true;
};
