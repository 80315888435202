import { merge } from '@studiohyperdrive/utils';

import {
	PermitsResultEntity,
	PermitsSummaryResultEntity,
	RenewablePermitsResultEntity
} from '../interfaces/permits-result.interface';
import { PermitsEntity, PermitsSummaryEntity, RenewablePermitsEntity } from '../interfaces/permits.interface';

/**
 * Transform the permit from a result entity with Dutch keys
 *
 * @param permit The permit result entity
 */
export const convertPermit = (permit: PermitsResultEntity): PermitsEntity => {
	if (!permit || !Object.keys(permit).length) {
		return;
	}

	return {
		// Wouter: Since a productId could appear multiple times but a reference is unique, we use that as id.
		id: permit.Referentie,
		assignedBy: convertPermitAssignedBy(permit.ToegekendDoor),
		assignedTo: permit.ToegekendAan,
		createdAt: permit.AangemaaktOp,
		// Wouter: The description is sometimes just whitespaces on the test env.
		description: permit.Omschrijving.trim(),
		product: permit.ProductNaam || permit.ProductId,
		isPublic: permit.IsPubliek,
		reference: permit.Referentie,
		referenceNumber: permit.ReferentieNummer,
		referralUrl: permit.DoorverwijzingUrl,
		isNew: permit.isOngelezen,
		status: merge<PermitsEntity['status']>(
			{
				endDate: permit.Status.EindDatum,
				fileNumber: permit.Status.DossierNummer,
				recentChangeDate: permit.Status.WijzigingsDatum,
				renewalDate: permit.Status.HernieuwingsDatum,
				startDate: permit.Status.StartDatum
			},
			['renewalUrl', permit.Status.HernieuwingsUrl]
		),
		title: permit.Titel
	};
};

/**
 * Convert the assignedBy from the Dutch keys to a frontend friendly format.
 */
export const convertPermitAssignedBy = (
	assignedBy: PermitsResultEntity['ToegekendDoor']
): PermitsEntity['assignedBy'] => {
	if (!assignedBy || !Object.keys(assignedBy).length) {
		return;
	}

	return merge<PermitsEntity['assignedBy']>(
		{
			name: assignedBy.Naam,
			organizationCode: assignedBy.OrganisatieCode
		},
		['city', assignedBy.Gemeente],
		['contactform', assignedBy.Contactformulier],
		['email', assignedBy.Email],
		['phone', assignedBy.Telefoon],
		['postalCode', assignedBy.Postcode],
		['streetAndNumber', assignedBy.StraatEnHuisnummer],
		['website', assignedBy.Website]
	);
};

/**
 * Transform the summary from the Dutch keys to a fontend friendly format.
 */
export const convertPermitSummary = (summary: PermitsSummaryResultEntity): PermitsSummaryEntity => {
	if (!summary || !Object.keys(summary).length) {
		return;
	}

	return {
		lastChangeDateLink: summary.mijnSamenvatting.linkNaarToelatingenGesorteerdOpLaasteWijzigingsdatum,
		newPermitsCount: summary.mijnSamenvatting.aantalNieuweToelatingenSindsLaasteKeerAangemeld,
		renewalLink: summary.mijnSamenvatting.linkNaarTeHernieuwenToelatingen,
		totalPermitsCount: summary.mijnSamenvatting.totaalAantalToelatingen,
		permits: summary.mijnToelatingen.elementen.map(convertPermit),
		renewablePermits: summary.mijnTeHernieuwenToelatingen.elementen.map(convertRenewablePermit)
	};
};

/**
 * Transform the summary from the Dutch keys to a frontend friendly format.
 */
export const convertRenewablePermit = (permit: RenewablePermitsResultEntity): RenewablePermitsEntity => {
	if (!permit || !Object.keys(permit).length) {
		return;
	}

	// Iben: Omit product and add requestedBy
	const { product, ...result } = {
		...convertPermit(permit),
		requestedBy: permit.AangevraagdDoor
	};

	return result;
};
