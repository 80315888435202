import {
	AbstractLanguageProviderService,
	AbstractScriptsProviderService,
	AbstractStatusProviderService,
	AbstractTranslationProviderService
} from '@vlaio/shared/core';

import { LanguageProviderService } from './language-provider.service';
import { ScriptsProviderService } from './scripts-provider.service';
import { StatusProviderService } from './status-provider.service';
import { TranslationProviderService } from './translation-provider.service';

export * from './spotlight.service';

// Iben: This list of services provide specific implementations for the shared core services
export const providerServices = [
	{
		provide: AbstractScriptsProviderService,
		useClass: ScriptsProviderService
	},
	{
		provide: AbstractStatusProviderService,
		useClass: StatusProviderService
	},
	{
		provide: AbstractLanguageProviderService,
		useClass: LanguageProviderService
	},
	{
		provide: AbstractTranslationProviderService,
		useClass: TranslationProviderService
	}
];
