const MandateEndpointUrl = {
	Overview: 'mandaatrelaties',
	Executors: 'uitvoerders',
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	RemoveExecutors: 'uitvoerders',
	// Wouter: Endpoint to accept changes made
	Accept: 'goedkeuren'
} as const;

const GlobalMandateEndpointUrl = {
	Products: 'producten-pagina',
	Facets: 'producten/facets',
	Executors: 'mijn-uitvoerders',
	AddExecutors: 'uitvoerders-toevoegen',
	// Wouter: Endpoint to fetch mandates that have changes
	ToAccept: 'dashboard'
} as const;

const MandateBaseEndpointUrl = {
	Mandates: 'mandaten',
	Taker: 'mandaatnemer',
	Giver: 'mandaatgever'
} as const;

// type MandateUserEndpointType = 'Giver' | 'Taker';
// type MandateAssetsEndpointType = 'Global' | 'Individual';

// interface MandateEndPointRecord {
// 	global: Record<keyof typeof GlobalMandateEndpointUrl, EndpointFunctionEntity>;
// 	flow: Record<keyof typeof MandateEndpointUrl, Record<MandateUserEndpointType, EndpointFunctionEntity>>;
// }

export const MandateEndpoints = {
	flow: {
		Overview: {
			Giver: (mandateId?: string) => {
				if (mandateId) {
					return `${MandateBaseEndpointUrl.Mandates}/${MandateBaseEndpointUrl.Giver}/${MandateEndpointUrl.Overview}/${mandateId}`;
				}
				return `${MandateBaseEndpointUrl.Mandates}/${MandateBaseEndpointUrl.Giver}/${MandateEndpointUrl.Overview}`;
			},
			Taker: (mandateId?: string) => {
				if (mandateId) {
					return `${MandateBaseEndpointUrl.Mandates}/${MandateBaseEndpointUrl.Taker}/${MandateEndpointUrl.Overview}/${mandateId}`;
				}
				return `${MandateBaseEndpointUrl.Mandates}/${MandateBaseEndpointUrl.Taker}/${MandateEndpointUrl.Overview}`;
			}
		},
		Executors: {
			Giver: (mandateId: string) =>
				`${MandateBaseEndpointUrl.Mandates}/${MandateBaseEndpointUrl.Giver}/${MandateEndpointUrl.Overview}/${mandateId}/${MandateEndpointUrl.Executors}`,
			Taker: (mandateId: string) =>
				`${MandateBaseEndpointUrl.Mandates}/${MandateBaseEndpointUrl.Taker}/${MandateEndpointUrl.Overview}/${mandateId}/${MandateEndpointUrl.Executors}`
		},
		RemoveExecutors: {
			Giver: (mandateId: string) =>
				`${MandateBaseEndpointUrl.Mandates}/${MandateBaseEndpointUrl.Giver}/${MandateEndpointUrl.Overview}/${MandateEndpointUrl.Executors}/${mandateId}`,
			Taker: (mandateId: string) =>
				`${MandateBaseEndpointUrl.Mandates}/${MandateBaseEndpointUrl.Taker}/${MandateEndpointUrl.Overview}/${MandateEndpointUrl.Executors}/${mandateId}`
		},
		Accept: {
			Giver: () =>
				`${MandateBaseEndpointUrl.Mandates}/${MandateBaseEndpointUrl.Giver}/${MandateEndpointUrl.Overview}/${MandateEndpointUrl.Accept}`,
			Taker: () =>
				`${MandateBaseEndpointUrl.Mandates}/${MandateBaseEndpointUrl.Taker}/${MandateEndpointUrl.Overview}/${MandateEndpointUrl.Accept}`
		}
	},
	global: {
		Products: () => `${MandateBaseEndpointUrl.Mandates}/${GlobalMandateEndpointUrl.Products}`,
		Facets: () => `${MandateBaseEndpointUrl.Mandates}/${GlobalMandateEndpointUrl.Facets}`,
		Executors: () =>
			`${MandateBaseEndpointUrl.Mandates}/${MandateBaseEndpointUrl.Taker}/${GlobalMandateEndpointUrl.Executors}`,
		AddExecutors: (mandateId: string) =>
			`${MandateBaseEndpointUrl.Mandates}/${MandateBaseEndpointUrl.Taker}/mandaatrelaties/${mandateId}/${MandateEndpointUrl.Executors}`,
		ToAccept: () => `${MandateBaseEndpointUrl.Mandates}/${GlobalMandateEndpointUrl.ToAccept}`
	}
} as const;
