import { StoreModule } from '@ngrx/store';

import { createDataProviderFromModule } from '@vlaio/shared/core';
import { StoreSlice } from '@vlaio/shared/store';

import { reducers } from './permits.store';
import { PermitsApiService } from './services';
import { PermitsService } from './services/permits.service';

export const providePermitsData = createDataProviderFromModule({
	imports: [StoreModule.forFeature(StoreSlice.Permits, reducers)],
	providers: [PermitsService, PermitsApiService]
});
