<a
	class="u-outline u-link__underline"
	target="_blank"
	rel="noreferrer"
	[id]="linkId"
	[href]="link"
	[title]="title ? title : (i18nKeys.Links.External | translate: { title: link })"
	[ngClass]="{ 'font-bold primary-color': linkStyle === 'styled' }"
	[cypressTag]="linkCypressTag"
	(click)="linkClicked.emit()"
>
	<ng-content></ng-content>

	<vlaio-icon class="external-icon" icon="fa-up-right-from-square" />
</a>
