import { HttpRequest, HttpEvent, HttpHandlerFn } from '@angular/common/http';
import { UUID } from 'angular2-uuid';
import { Observable } from 'rxjs';

import { environment } from '~environment';

export function defaultHeadersInterceptor(req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> {
	// Wouter: Add headers to each call made to our API.
	if (!req.url.startsWith(environment.api.fullPath)) {
		return next(req);
	}

	// Wouter: Add the X-Correlation-ID to the headers of the request
	// Wouter: Add the ngsw-bypass header to the headers of the request so that no API response is cached by the service worker
	const defaultRes = req.clone({
		headers: req.headers.append('X-Correlation-ID', UUID.UUID()).append('ngsw-bypass', 'true')
	});

	return next(defaultRes);
}
