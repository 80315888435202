<vlaio-event-link class="focus-nav" (linkClicked)="scrollToCookies()">
	{{ i18nKeys.SkipLinks.Cookies | translate | uppercase }}
</vlaio-event-link>

<vlaio-event-link class="focus-nav" (linkClicked)="scrollToMain()">
	{{ i18nKeys.SkipLinks.MainContent | translate | uppercase }}
</vlaio-event-link>

<vlaio-event-link class="focus-nav" (linkClicked)="scrollToFooter()">
	{{ i18nKeys.SkipLinks.Footer | translate | uppercase }}
</vlaio-event-link>
