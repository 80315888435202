import { marker } from '@ngneat/transloco-keys-manager/marker';

import { sharedI18nKeys } from '@vlaio/shared/i18n';

export const SharedPWAi18nKeys = {
	...sharedI18nKeys,
	PWA: {
		Ios: {
			Title: marker('pwa.ios.title'),
			Description: marker('pwa.ios.description'),
			CTA: marker('pwa.ios.cta')
		},
		Android: {
			Title: marker('pwa.android.title'),
			Description: marker('pwa.android.description'),
			CTA: marker('pwa.android.cta'),
			Installed: marker('pwa.android.installed'),
			NoChromeDescription: marker('pwa.android.no-chrome-description')
		},
		Browser: {
			Title: marker('pwa.browser.title'),
			Description: marker('pwa.browser.description')
		},
		QrCode: {
			Title: marker('pwa.qrcode.title')
		}
	}
};
