import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { NgxI18nRootService } from '@studiohyperdrive/ngx-i18n';
import { NgxMediaQueryService } from '@studiohyperdrive/ngx-utils';
import { tap } from 'rxjs';

import { AppRoutePaths, RedirectsPaths } from '@vlaio/shared/route-paths';

export const DesktopOnlyGuard: CanActivateFn = () => {
	// Iben: Fetch the injectable services
	const mediaService: NgxMediaQueryService = inject(NgxMediaQueryService);
	const i18nRootService: NgxI18nRootService = inject(NgxI18nRootService);
	const router: Router = inject(Router);

	// Iben: Listen to the matching query
	return mediaService.getMatchingQuery$('atTablet').pipe(
		tap((isNotMobile) => {
			// Iben: Early exit if we're not on mobile
			if (isNotMobile) {
				return;
			}

			// Iben: Reroute to mobile
			router.navigate([
				'/',
				i18nRootService.currentLanguage,
				AppRoutePaths.Redirects,
				RedirectsPaths.DesktopOnly
			]);
		})
	);
};
