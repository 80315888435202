import { Directive, Input, OnChanges } from '@angular/core';

import { GtagDirectiveLabelProviderService } from '../services';

import { GtagDirectiveEventToken } from './gtag-directive.token';
import { GtagEventDirective } from './gtag-event.directive';

/**
 * A Google Analytics tracking directive that will track the click event based on the data you provide, specifically made for select boxes.
 * Use this instead of the gtagClick directive, as that does not work with select options in Chrome.
 */
@Directive({
	standalone: true,
	selector: '[gtagSelect]',
	hostDirectives: [
		{
			directive: GtagEventDirective,
			inputs: ['preventTracking', 'gtagCategory', 'gtagType']
		}
	],
	providers: [
		// Iben: Provide the change event so it will handle the tracking on change instead of on click
		{ provide: GtagDirectiveEventToken, useValue: 'change' },
		GtagDirectiveLabelProviderService
	]
})
export class GtagSelectDirective implements OnChanges {
	/**
	 * An optional key to fetch a specific property from the selected value instead of the selected value
	 */
	@Input() public gtagSelectKey: string;
	/**
	 * An optional key to rewrite the id key from the provided data
	 */
	@Input() public gtagSelectIdKey: string = 'id';
	/**
	 * The provided data we need to search through to find the actual label to send to GA
	 */
	@Input() public gtagSelectData: unknown[];

	constructor(private readonly gtagDirectiveLabelProvider: GtagDirectiveLabelProviderService) {}

	ngOnChanges() {
		// Iben: Set the needed data for the GTagDirectiveLabelProvider so it can send the correct label to GA
		this.gtagDirectiveLabelProvider.setSelectData({
			key: this.gtagSelectKey,
			idKey: this.gtagSelectIdKey,
			data: this.gtagSelectData
		});
	}
}
