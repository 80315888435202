<details #details>
	<summary (click)="toggleDetails(details)">
		<span
			[ngClass]="{
				'icon-arrow-right': !isOpen,
				'icon-arrow-down': isOpen
			}"
		></span>

		<ng-content select="[label]"></ng-content>
	</summary>

	<ng-content select="[content]"> </ng-content>
</details>
