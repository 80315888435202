import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { Subject, takeUntil, tap } from 'rxjs';

import { VlaioDownloadService } from '../../services';

/**
 * A pipe that takes an id and will return whether there's currently a file downloading with said id
 */
@Pipe({
	name: 'downloading',
	pure: false,
	standalone: true
})
export class VlaioDownloadingPipe implements PipeTransform, OnDestroy {
	/**
	 * Subject to hold the destroyed state of the current observable
	 */
	private destroyedSubject: Subject<void>;

	/**
	 * Whether the VlaioDownloadService is downloading a document with the proposed id
	 */
	private isDownloading: boolean = false;

	/**
	 * Instance of the change detector ref, implemented like this according to the async pipe implementation
	 * https://github.com/angular/angular/blob/main/packages/common/src/pipes/async_pipe.ts
	 */
	private changeDetectorRef: ChangeDetectorRef | null;

	constructor(
		private readonly downloadService: VlaioDownloadService,
		private readonly cdRef: ChangeDetectorRef
	) {
		// Iben: Use instance of cdRef like this to prevent memory leaks (see Angular async Pipe implementation)
		this.changeDetectorRef = cdRef;
	}

	ngOnDestroy(): void {
		// Iben: Call the dispose when the component is destroyed so we have no running subscriptions left
		this.dispose();

		// Iben: Clear instance of cdRef like this to prevent memory leaks (see Angular async Pipe implementation)
		this.changeDetectorRef = null;
	}

	/**
	 * Returns whether or not a document is downloading in the VlaioDownloadService
	 *
	 * @param id - The id of the document
	 */
	transform(id: string): boolean {
		this.subscribe(this.downloadService.isDownloading(id));

		return this.isDownloading;
	}

	/**
	 * Handles the changeDetection, latest value and dispose of the isDownloading observable
	 *
	 * @param observable - The isDownloading observable
	 */
	private subscribe(observable: ObservableBoolean) {
		// Iben: Dispose the current subscription
		this.dispose();

		// Iben: Create a new destroyed subject to handle the destruction when needed
		this.destroyedSubject = new Subject();

		observable
			.pipe(
				tap((value) => {
					// Iben: Update the latest value when it a new value is provided
					this.isDownloading = value;

					// Iben: Mark the component as ready for check
					this.changeDetectorRef?.markForCheck();
				}),
				takeUntil(this.destroyedSubject)
			)
			.subscribe();
	}

	/**
	 * Dispose of the feature observable when existing
	 */
	private dispose() {
		// Iben: In case there's a destroyed, we have an observable and we destroy the subscription and reset the observable
		if (this.destroyedSubject) {
			this.destroyedSubject.next();
			this.destroyedSubject.complete();
		}
	}
}
