import { Pipe, PipeTransform } from '@angular/core';

/**
 * A pipe to transform the first and last name of a person into a single string
 */
@Pipe({
	name: 'name',
	standalone: true
})
export class NamePipe implements PipeTransform {
	transform(value: { firstName?: string; name?: string }): string {
		// Iben: Handle incorrect input
		if (!value || typeof value !== 'object') {
			return '';
		}

		// Iben: Convert the object to the name of the actor
		return `${value.firstName ? value.firstName + ' ' : ''}${value.name ? value.name : ''}`.trim();
	}
}
