import { Observable } from 'rxjs';

import { AppStatusEntity } from '../interfaces';

/**
 * Provides an observable with the application status for its client
 */
export abstract class AbstractStatusProviderService {
	/**
	 * Returns the application status
	 */
	public abstract getApplicationStatus(): Observable<AppStatusEntity>;
}
