import { ObservableString } from '@studiohyperdrive/rxjs-utils';

/**
 * Provides a translations to the client
 */
export abstract class AbstractTranslationProviderService {
	/**
	 * Returns a translations to the client
	 */
	public abstract getTranslation(label: string): ObservableString;
}
