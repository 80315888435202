import { BooleanRecord } from '@studiohyperdrive/rxjs-utils';

export const createLoadingRecord = (keys: string[], loading: boolean): BooleanRecord => {
	return [...keys].reduce((previous: BooleanRecord, current: string) => {
		return {
			...previous,
			[current]: loading
		};
	}, {}) as BooleanRecord;
};
