import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';

import { SaveOnExitComponent } from './save-on-exit.component.abstract';

export abstract class SaveOnExitAbstractService {
	/**
	 * Take action when the component is dirty
	 */
	public abstract handleDirtyState: (component: SaveOnExitComponent) => ObservableBoolean;
}
