import { Component } from '@angular/core';
import { NgxModalAbstractComponent } from '@studiohyperdrive/ngx-inform';

import { ExitWizardModalI18nKeysEntity } from '@vlaio/shared/core';

import { VlaioButtonComponent } from '../buttons';

@Component({
	selector: 'vlaio-exit-wizard-modal',
	templateUrl: './exit-wizard-modal.component.html',
	standalone: true,
	imports: [VlaioButtonComponent],
	host: { class: 'c-modal-container' }
})
export class VlaioExitWizardModalComponent extends NgxModalAbstractComponent<
	'Proceed' | 'Cancel',
	ExitWizardModalI18nKeysEntity
> {}
