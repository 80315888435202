import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxFormsErrorAbstractComponent } from '@studiohyperdrive/ngx-forms';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { VlaioDisclaimerComponent } from '@vlaio/shared/ui/common';

@Component({
	selector: 'vlaio-form-error',
	templateUrl: './form-error.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [VlaioDisclaimerComponent, CypressTagDirective, TranslateModule]
})
export class VlaioFormErrorComponent extends NgxFormsErrorAbstractComponent {}
