import {
	HttpContext,
	HttpContextToken,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const CONFIRMATION_TOKEN = new HttpContextToken<string>(() => null);

export function setConfirmationToken(token: string = null) {
	return new HttpContext().set(CONFIRMATION_TOKEN, token);
}

@Injectable()
export class ConfirmationTokenInterceptor implements HttpInterceptor {
	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const token = request.context.get(CONFIRMATION_TOKEN);

		if (!token) {
			return next.handle(request);
		}

		const ReqWithConfirmationTokenHeader = request.clone({
			headers: request.headers.set('Bevestigingstoken', token)
		});

		return next.handle(ReqWithConfirmationTokenHeader);
	}
}
