import { Directive, Input } from '@angular/core';

import { I18nKeys } from '../../../i18n';

import { LinkStyleType } from './link-style.types';

/**
 * The abstract class for the link component.
 */
@Directive()
export class VlaioLinkAbstractComponent {
	/**
	 * The translation keys.
	 */
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;

	/**
	 * The link to navigate to.
	 */
	@Input() public link: string | string[];

	/**
	 * The style of the link.
	 *
	 * Default value is `styled`.
	 */
	@Input() public linkStyle: LinkStyleType = 'styled';

	/**
	 * The title of the link.
	 * This is needed for accessibility.
	 */
	@Input() public title: string = '';

	/**
	 * An ID to pass to the a-tag.
	 * This may be used for accessibility purposes.
	 */
	@Input() public linkId: string;
}
