import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { NgTemplateOutlet } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	HostBinding,
	inject,
	Input,
	signal,
	TemplateRef,
	ViewChild,
	WritableSignal
} from '@angular/core';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { ProfileConfig } from '@govflanders/vl-widget-global-header-types';
import { filter, switchMap } from 'rxjs';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { AuthenticationService } from '@vlaio/shared/authentication';
import { InjectAcmComponentsService } from '@vlaio/shared/core';
import { HasFeatureDirective } from '@vlaio/shared/features';
import { LanguageSwitcherComponent } from '@vlaio/shared/i18n';
import { MediaQueryMax, MediaQueryMin } from '@vlaio/shared/types';
import { MediaQueryDirective } from '@vlaio/shared/ui/device';
import { UserEntity, UserService } from '@vlaio/shared/user';
import { environment } from '~environment';

import { VlaioMenuItemEntity } from '../../types';
import { VlaioHamburgerComponent } from '../hamburger/hamburger.component';

@Component({
	selector: 'vlaio-menu',
	templateUrl: 'menu.component.html',
	styleUrl: './menu.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		RouterLinkActive,
		RouterLink,
		NgTemplateOutlet,
		CypressTagDirective,
		MediaQueryDirective,
		CdkMenuTrigger,
		CdkMenu,
		CdkMenuItem,
		HasFeatureDirective,
		VlaioHamburgerComponent,
		LanguageSwitcherComponent
	]
})
export class VlaioMenuComponent implements AfterViewInit {
	/**
	 * A string of classes we wish to apply to the menu
	 */
	@HostBinding('class')
	@Input()
	public classes: string = '';

	/**
	 * An array of items we wish to display in the menu
	 */
	@Input() public menuItems: VlaioMenuItemEntity[] = [];

	/**
	 * Whether or not the submenu should be shown.
	 */
	@Input({ required: true }) public shouldShowSubmenu: boolean;

	/**
	 * Prevent the menu from setting an active class on the currently active menu item.
	 *
	 * Default value is set to 'false'.
	 */
	@Input() public showActiveItem: boolean = false;

	/**
	 * Prevent the menu from collapsing on mobile devices.
	 *
	 * Default value is set to 'false'.
	 */
	@HostBinding('class.prevent-collapse')
	@Input()
	public preventCollapse: boolean = false;

	/**
	 * A template for content of the a tag
	 */
	@ContentChild('itemTmpl', { static: false })
	public itemTemplate: TemplateRef<any>;

	/**
	 * The reference to the hamburger component.
	 */
	@ViewChild('hamburger') public hamburger: VlaioHamburgerComponent;

	/**
	 * The media queries for the menu.
	 */
	public mediaQueries = { MediaQueryMax, MediaQueryMin };

	/**
	 * Whether the menu is open.
	 */
	public isMenuOpen: WritableSignal<boolean> = signal(false);

	/**
	 * Inject the required services
	 */
	private readonly userService: UserService = inject(UserService);
	private readonly authService: AuthenticationService = inject(AuthenticationService);
	private readonly acmService: InjectAcmComponentsService = inject(InjectAcmComponentsService);

	/**
	 * The ngAfterViewInit method will initialize the header widget.
	 */
	public ngAfterViewInit(): void {
		this.userService.loading$
			.pipe(
				filter((loading: boolean) => !loading),
				switchMap(() => this.userService.user$),
				switchMap((user: UserEntity) => {
					return this.acmService.initHeader({
						selector: '#c-header__block__acm-header',
						config: {
							url: environment.acmidm.widgetsUrl,
							id: environment.acmidm.headerWidgetId,
							profile: this.parseGlobalHeaderConfig(user)
						}
					});
				})
			)
			.subscribe();
	}

	/**
	 * Handles the menu change
	 *
	 * @param isOpen - A boolean value that indicates whether the menu is open
	 */
	public handleMenuChange(isOpen: boolean): void {
		this.isMenuOpen.update(() => isOpen);
	}

	/**
	 * The parseGlobalHeaderConfig method will parse the user data to the profile config.
	 *
	 * @param user{UserEntity}
	 * @returns ProfileConfig
	 * @private
	 */
	private parseGlobalHeaderConfig(user: UserEntity): ProfileConfig {
		const url = environment.api.protocol + '://' + environment.api.hostname;

		return {
			active: Boolean(user),
			loginUrl: `${url}${environment.acmidm.loginPath}${this.authService.getLocalRedirectUrl('?')}`,
			logoutUrl: `${url}${environment.acmidm.logoutPath}${this.authService.getLocalRedirectUrl('?')}`,
			switchCapacityUrl: `${url}${environment.acmidm.switchPath}${this.authService.getLocalRedirectUrl('?')}`,
			// Denis: If the user is logged in, set the user data in the profile config
			...(user
				? {
						idpData: {
							user: {
								firstName: user.firstName,
								name: user.name
							},
							// Denis: If the user is logged in for an organization, set the organization data in the profile config
							...(user?.company
								? {
										activeIdentity: {
											onBehalfOf: {
												code: user.targetCode,
												name: user.company.name,
												identifier: user.company.number
											}
										}
									}
								: {})
						}
					}
				: {})
			// TODO: Denis: The interfaces have a small error that will be fixed, cast the interface for now until fixed
		} as ProfileConfig;
	}
}
