import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { StoreService, dispatchDataToStore } from '@studiohyperdrive/ngx-store';
import {
	catchAndCallThrough,
	fetchIf,
	ObservableArray,
	ObservableRecord,
	ObservableString
} from '@studiohyperdrive/rxjs-utils';
import { isEmpty } from 'lodash';
import { Observable, Subject, combineLatest, of, throwError } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { FeatureService } from '@vlaio/shared/features';
import { UserEntity, UserService } from '@vlaio/shared/user';

import {
	CompanyActivitiesByBranchRecord,
	CompanyAddressEntity,
	CompanyBranchEntity,
	CompanyEntity,
	PartialCompanySearchEntity
} from '../../data/interfaces';
import { actions, CompanyStoreSliceType, selectors } from '../company.store';
import { getCompanyAddressRecord } from '../utils';

import { CompanyApiService } from './company.api.service';
@Injectable()
export class CompanyService extends StoreService<CompanyStoreSliceType> {
	/**
	 * Subject to trigger the fetching of company details.
	 */
	private readonly fetchCompaniesSubject: Subject<string> = new Subject();

	/**
	 * The branches of the company of the current user
	 */
	public readonly userCompanyBranches$: ObservableArray<CompanyBranchEntity> = this.state.userCompany$.pipe(
		filter<CompanyEntity>(Boolean),
		map((company) => company.branches)
	);

	/**
	 * A record of every company branch including the main location that exists of the address as
	 * value with the branch number as key.
	 */
	public readonly userCompanyBranchesByNumber$: ObservableRecord<CompanyAddressEntity> = this.state.userCompany$.pipe(
		filter<CompanyEntity>(Boolean),
		map(getCompanyAddressRecord)
	);

	/**
	 * A record of activities by branch
	 */
	public readonly activitiesByBranchRecord$: Observable<CompanyActivitiesByBranchRecord> =
		this.state.activities$.pipe(
			// Iben: Map activities to record for easy access
			map((items) => {
				const result = {};

				items.forEach(({ branch, activities }) => {
					result[branch] = activities;
				});

				return result;
			})
		);

	/**
	 * The name of the company
	 */
	public readonly companyName$: ObservableString = this.state.userCompany$.pipe(
		map((company) => company?.names?.public)
	);

	constructor(
		public readonly store: Store,
		private readonly apiService: CompanyApiService,
		private readonly userService: UserService,
		private readonly featureService: FeatureService,
		private readonly destroyRef: DestroyRef
	) {
		super(store, selectors);

		// Wouter: When a new company number should be fetched, we start the process
		this.fetchCompaniesSubject
			.pipe(
				concatMap((companyNumber) =>
					fetchIf(
						this.state.companyDetails$,
						// Wouter: If the company is already in the store, we return it
						(companies) => companies && companies[companyNumber],
						(companies) =>
							dispatchDataToStore(
								actions.companyDetails,
								// Wouter: If the company is not in the store, we fetch it from the api
								this.getCompanyFromApi(companyNumber).pipe(
									map((company) => {
										// Wouter: Update the store with the current companyNumber and its details
										return {
											...(companies || []),
											[companyNumber]: company
										};
									}),
									catchError(() => {
										return of({
											...(companies || []),
											[companyNumber]: {
												error: true
											}
										});
									})
								),
								this.store
							).pipe(
								// Wouter: Take one to close the stream, as the concatMap will only go the next value when the previous is completed
								take(1),
								map((data) => data[companyNumber])
							)
					)
				),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe();
	}

	/**
	 * Fetches and returns the company of the current user
	 */
	public getUserCompany(): Observable<CompanyEntity> {
		return this.userService.user$.pipe(
			filter<UserEntity>((user) => Boolean(user && user.company)),
			switchMap(({ company }) =>
				dispatchDataToStore(actions.userCompany, this.apiService.getCompany(company.number), this.store)
			)
		);
	}

	/**
	 * Fetches and returns a list of companies matching the provided search filters
	 *
	 * @param filters - The provided search filters
	 * @param showError - The provided search filters
	 */
	public searchCompanies(
		filters: PartialCompanySearchEntity,
		showError: boolean = false
	): ObservableArray<CompanyEntity> {
		return dispatchDataToStore(
			actions.searchResults,
			isEmpty(filters)
				? of([])
				: this.apiService.searchCompanies(filters).pipe(
						// Iben: Show the error when needed
						catchError((error) => {
							if (!showError) {
								return of([]);
							}

							throwError(error);
						})
					),
			this.store
		);
	}

	/**
	 * Set the current company filters
	 *
	 * @param payload
	 */
	public setCompanyFilters(payload: PartialCompanySearchEntity) {
		this.store.dispatch(actions.filters.set({ payload }));
	}

	/**
	 * Get the branch activities for a company's branch
	 *
	 * @param company - The number of the company
	 * @param branch - The number of the branch
	 */
	public getCompanyBranchActivities(company: string, branch: string) {
		return dispatchDataToStore(
			actions.activities,
			this.apiService.getCompanyBranchActivities(company, branch).pipe(
				map((activities) => {
					return { branch: `${company}-${branch}`, activities };
				})
			),
			this.store,
			'add'
		);
	}

	/**
	 * Get the full data of a company
	 *
	 * @param number - The number of the company
	 */
	public getCompany(number: string): Observable<CompanyEntity> {
		return fetchIf(
			this.state.companies$,
			(companies) => companies.find((item) => item.number === number),
			() => dispatchDataToStore(actions.companies, this.getCompanyFromApi(number), this.store, 'add').pipe()
		).pipe(
			catchAndCallThrough(() => {
				// Wouter: Set loading to false
				this.store.dispatch(actions.companies.loading({ payload: false }));
				// Wouter: In case of an error, we set the error to true
				this.store.dispatch(
					actions.companies.add({
						payload: {
							number,
							activities: [],
							branches: [],
							form: undefined,
							legalStatus: undefined,
							names: undefined,
							registrationDate: undefined,
							startDate: undefined,
							error: true
						}
					})
				);
			}, 'continue'),
			// Wouter: The fetchIf doesn't really play nice with the loading state. This should be checked.
			tap(() => this.store.dispatch(actions.companies.loading({ payload: false })))
		);
	}

	/**
	 * Set the id of the currently selected company
	 *
	 * @param id - Id the of the currently selected company
	 */
	public setCurrentCompanyId(id: string): ObservableString {
		return this.state.detailId$.pipe(
			take(1),
			tap((current) => {
				// Iben: In case the ids are the same we reset
				const payload = current === id ? '' : id;
				this.store.dispatch(actions.detailId.set({ payload }));
			})
		);
	}

	/**
	 * Clear the list of searched companies
	 */
	public clearSearchedCompanies() {
		this.store.dispatch(actions.searchResults.set({ payload: [] }));
	}

	/**
	 * Fetches and stores the company detail in a record for the mobile view
	 *
	 * @param number - The number of the company
	 */
	public getCompanyDetail(number: string): void {
		this.fetchCompaniesSubject.next(number);
	}

	/**
	 * Add a fake branch to the company. This is used for testing purposes.
	 *
	 * @param fakeBranch The data of the fake branch to add
	 * @deprecated This should be removed
	 */
	public addFakeBranch(fakeBranch: CompanyBranchEntity) {
		return dispatchDataToStore(
			actions.userCompany,
			this.state.userCompany$.pipe(
				take(1),
				map((company) => {
					return {
						...company,
						branches: [...company.branches, fakeBranch]
					};
				})
			),
			this.store,
			'set'
		);
	}

	/**
	 * Fetches the company from the api, including the permits in case the permits feature flag is activated
	 *
	 * @param number - The number of the company
	 */
	private getCompanyFromApi(number: string): Observable<CompanyEntity> {
		// Iben: Check if the permits feature is active
		return this.featureService.hasFeature('Permits').pipe(
			switchMap((hasPermits) => {
				// Iben: If the permits are not active, return the company without the permits
				if (!hasPermits) {
					return this.apiService.getCompany(number);
				}

				// Iben: If the permits are active, fetch both the company and the permits
				return combineLatest([
					this.apiService.getCompany(number),
					// Iben: In case the permits would fail, we still continue as it should be non breaking
					this.apiService.getCompanyPermits(number).pipe(catchError(() => of([])))
				]).pipe(
					map(([company, permits]) => {
						return { ...company, permits, error: false };
					})
				);
			})
		);
	}
}
