import { Pipe, PipeTransform } from '@angular/core';
import { NgxCookieService } from '@studiohyperdrive/ngx-cookies';

/**
 * This pipe is used to get the value of a cookie stored in the user session.
 *
 * ---
 *
 * @example When the cookie **is not** a stringified object.
 * *Eg: userName: Jane*
 * ```HTML
 * <!-- Renders either `Jane` or nothing if the cookie was empty -->
 * <p *ngIf="'userName' | hasCookie as userName">
 *   {{ userName }}
 * </p>
 * ```
 *
 * ---
 *
 * @example When the cookie **is** a stringified object.
 * *Eg: user: { firstName: John, lastName: Doe }*
 * ```HTML
 * <!-- Renders either `John` or nothing if the cookie was empty -->
 * <p *ngIf="'user' | hasCookie : 'firstName' as firstName">
 *   {{ firstName }}
 * </p>
 * ```
 */
@Pipe({
	name: 'hasCookie',
	standalone: true
})
export class HasCookiePipe implements PipeTransform {
	constructor(private readonly cookieService: NgxCookieService) {}

	public transform(cookieKey: string, objectKey?: string): string {
		if (objectKey) {
			return JSON.parse(this.cookieService.getCookie(cookieKey) || '{}')[objectKey] ?? undefined;
		}

		return this.cookieService.getCookie(cookieKey) ?? undefined;
	}
}
