import { Injectable } from '@angular/core';
import { merge } from '@studiohyperdrive/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { VlaioHttpClientService } from '@vlaio/shared/core';
import { ELoketEndpoints } from '@vlaio/shared/endpoints';
import { VlaioResult } from '@vlaio/shared/types';

import { PartnerEntity, PartnerLevelEnum, PartnerResultEntity, PartnersByLevelEntity } from '../interfaces';

@Injectable()
export class PartnersApiService {
	constructor(private readonly http: VlaioHttpClientService) {}

	/**
	 * Fetch all the partners from the backend and map them to a fontend friendly format.
	 */
	public getPartners(): Observable<PartnersByLevelEntity> {
		return this.http.get<VlaioResult<PartnerResultEntity>>(ELoketEndpoints.Partners.GetPartners()).pipe(
			map(({ elementen }) =>
				elementen.map(({ naam, id, aantalProducten, website, niveau, niveauLabel, ...remaining }) => {
					return merge<PartnerEntity>(
						{
							name: naam,
							id,
							amountOfProducts: aantalProducten,
							url: website,
							level: niveau as PartnerLevelEnum,
							levelLabel: niveauLabel,
							isAvailableIn: {
								cases: remaining.isBeschikbaarInLopendeAanvragen,
								products: remaining.isBeschikbaarInHetAanbod,
								recommendations: remaining.isBeschikbaarInAanbevelingenOpMaat
							},
							isAtLeastAvailableInOne:
								remaining.isBeschikbaarInAanbevelingenOpMaat ||
								remaining.isBeschikbaarInHetAanbod ||
								remaining.isBeschikbaarInLopendeAanvragen
						},
						['isCaseMonitoring', remaining.isDossieropvolging],
						['isDigital', remaining.isDigitaleAanvraag],
						['isHeaderIntegration', remaining.isHeaderIntegration]
					);
				})
			),
			map((partners: PartnerEntity[]) =>
				// Wouter: Map to record with level as key and partners as value
				partners.reduce(
					(prev, curr) => ({
						...prev,
						[curr.level]: [...(prev[curr.level] || []), curr]
					}),
					{} as PartnersByLevelEntity
				)
			)
		);
	}
}
