import {
	RecommendationRating,
	RecommendationRatingResult,
	RecommendationStrength,
	RecommendationStrengthResult
} from './recommended-products';

export const recommendationStrengthEnumMap = new Map<RecommendationStrengthResult, RecommendationStrength>([
	[RecommendationStrengthResult['Eerder niet'], RecommendationStrength.NOT],
	[RecommendationStrengthResult['Eerder wel'], RecommendationStrength.POTENTIAL],
	[RecommendationStrengthResult['Zeker wel'], RecommendationStrength.SURE]
]);
export const recommendationRatingEnumMap = new Map<RecommendationRatingResult, RecommendationRating>([
	[RecommendationRatingResult.Negatief, RecommendationRating.NEGATIVE],
	[RecommendationRatingResult.Positief, RecommendationRating.POSITIVE],
	[RecommendationRatingResult.Neutraal, RecommendationRating.NEUTRAL]
]);

export const reverseRecommendationRatingEnumMap = new Map<RecommendationRating, RecommendationRatingResult>([
	[RecommendationRating.NEGATIVE, RecommendationRatingResult.Negatief],
	[RecommendationRating.POSITIVE, RecommendationRatingResult.Positief],
	[RecommendationRating.NEUTRAL, RecommendationRatingResult.Neutraal]
]);
