import { FacetEntity, FacetResult, FacetSubjectItemEntity, FacetSubjectResultItem } from '@vlaio/shared/types';

/**
 * Convert a backend facet to a frontend facet
 *
 * @param facetResult - A backend facet to convert
 */
export function convertFacetResultToFacet(facetResult: FacetResult): FacetEntity {
	const items = [...facetResult.onderwerpen].map((onderwerp) => convertFacetSubjectResultToFacetSubject(onderwerp));
	return {
		name: facetResult.naam,
		items,
		id: facetResult.id,
		isRefinable: items.some((subject) => Boolean(subject['refinements']))
	};
}

/**
 * Converts a backend facet subject to a frontend facet subject
 *
 * @param subjectResult - A backend subject to convert
 */
function convertFacetSubjectResultToFacetSubject(subjectResult: FacetSubjectResultItem): FacetSubjectItemEntity {
	// Iben: Get the basic information
	const base = {
		name: subjectResult.naam,
		id: subjectResult.id
	};

	// Iben: check if we have refinements (using [] notation due typing)
	const refinements = subjectResult['verfijningen'];

	if (refinements) {
		// Iben: recursively convert all refinements
		return {
			...base,
			refinements: [...refinements].map((refinement) => convertFacetSubjectResultToFacetSubject(refinement))
		};
	} else {
		return {
			...base,
			amount: subjectResult['aantal'],
			checked: subjectResult['aangevinkt']
		};
	}
}
