import { LowerCasePipe, UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { HasFeatureDirective } from '@vlaio/shared/features';
import { AppRoutePaths, PagesRoutePaths } from '@vlaio/shared/route-paths';
import { PageNavItemEntity } from '@vlaio/shared/types';
import {
	VlaioExternalLinkComponent,
	VlaioInternalLinkComponent,
	VlaioEventLinkComponent
} from '@vlaio/shared/ui/common';

import { I18nKeys } from '../../../i18n';

@Component({
	selector: 'vlaio-footer',
	templateUrl: './footer.component.html',
	styleUrl: './footer.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CypressTagDirective,
		HasFeatureDirective,
		RouterLink,
		LowerCasePipe,
		UpperCasePipe,
		TranslateModule,
		VlaioInternalLinkComponent,
		VlaioExternalLinkComponent,
		VlaioEventLinkComponent
	]
})
export class FooterComponent {
	/**
	 * The current language
	 */
	@Input() language: string = 'nl';

	/**
	 * A list of items to be shown in the footer
	 */
	@Input() navItems: PageNavItemEntity[] = [];

	/**
	 * An event when we clicked the cookies link
	 */
	@Output() cookiesClicked: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * The paths of the application
	 */
	public readonly paths = { ...AppRoutePaths, ...PagesRoutePaths };

	/**
	 * The i18n keys
	 */
	public readonly i18nKey = I18nKeys;
}
