import { inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { NgxI18nRootService } from '@studiohyperdrive/ngx-i18n';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AuthenticationService } from '@vlaio/shared/authentication/auth';
import { AppRoutePaths, RedirectsPaths } from '@vlaio/shared/route-paths';

import { UserService } from '../../data';
import { bypassGuard, getAuthFailType } from '../helpers';

export const IsAuthenticatedGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): ObservableBoolean => {
	// Iben: Fetch all injectables
	const router: Router = inject(Router);
	const userService: UserService = inject(UserService);
	const i18nRootService: NgxI18nRootService = inject(NgxI18nRootService);
	const authService: AuthenticationService = inject(AuthenticationService);

	return combineLatest([
		userService.user$,
		// Iben: We only allow this to emit once the refreshUser call is ready
		userService.loading$.pipe(filter((loading) => !loading))
	]).pipe(
		map(([user]) => {
			// Iben: If the provided url is part of the allowed urls, we continue
			if (bypassGuard(state.url)) {
				return true;
			}

			// Iben: Continue if user is logged in
			if (user) {
				return true;
			}

			const failType = getAuthFailType(route.queryParams);

			if (failType) {
				authService.setAuthenticationFailed(failType);
				router.navigate([
					'/',
					i18nRootService.currentLanguage,
					AppRoutePaths.Redirects,
					RedirectsPaths.AuthenticationFailed
				]);

				return false;
			}

			// Iben: Route to main page in case the user isn't logged in
			router.navigate(['', i18nRootService.currentLanguage]);
			return false;
		})
	);
};
