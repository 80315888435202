import { merge } from '@studiohyperdrive/utils';

import {
	UserCompanyEntity,
	UserCompanyEntityResult,
	UserEntity,
	UserMandateInfoEntity,
	UserMandateInfoEntityResult,
	UserSessionEntity,
	UserSessionEntityResult
} from '../interfaces';

/**
 * Converts the user session to a frontend friendly interface
 *
 * @param session - The user session from the API
 */
export const convertUserSession = (session: UserSessionEntityResult): UserSessionEntity => {
	return merge<UserSessionEntity>(
		{
			cookie: {
				expiresAt: session.cookie.vervaltijdstip
			},
			metaData: {
				expiresAt: session.metaGegevens.vervaltijdstip,
				lastLoggedInAt: session.metaGegevens.laatsteAanmelding,
				previousLoggedInAt: session.metaGegevens.vorigeAanmelding,
				lastUsedAt: session.metaGegevens.laatsteGebruikt,
				lifetime: session.metaGegevens.levensduur,
				sessionStartedAt: session.metaGegevens.startSessie
			},
			user: merge<UserEntity>(
				{
					firstName: session.gebruiker.voornaam,
					name: session.gebruiker.familienaam,
					insz: session.gebruiker?.insz,
					isCivilian: session.gebruiker.alsBurger,
					isCompany: session.gebruiker.alsOrganisatie,
					language: session.gebruiker.taal,
					id: session.gebruiker.id,
					targetCode: session.gebruiker.doelgroepCode
				},
				['company', convertUserCompany(session.gebruiker.organisatie)]
			)
		},
		['mandateInfo', convertMandateInfo(session.mandaatInformatie)],
		['features', session.voorzieningen]
	);
};

/**
 * Converts the user company to a frontend friendly interface
 *
 * @param company - The user company from the API
 */
const convertUserCompany = (company: UserCompanyEntityResult | undefined): UserCompanyEntity | undefined => {
	if (!company) {
		return;
	}

	return {
		number: company.nummer,
		name: company.naam,
		isLegalRepresentative: company.isWettelijkVertegenwoordiger || false,
		isMandateAdministrator: company.isMandaatBeheerder || false,
		hasSanctionsAccess: company.isSanctieregisterToegankelijk || false,
		hasSubsidiesAccess: company.isSubsidieregisterToegankelijk || false,
		isMandateExecutor: company.isMandaatUitvoerder || false,
		hasEnforcementAccess: company.isHandhavingsplatformToegankelijk || false
	};
};

/**
 * Converts the user mandate info to a frontend friendly interface
 *
 * @param info - The mandate info from the API
 */
const convertMandateInfo = (info: UserMandateInfoEntityResult | undefined): UserMandateInfoEntity | undefined => {
	if (!info) {
		return undefined;
	}

	return {
		giver: {
			name: info.mandaatGever?.naam,
			companyNumber: info.mandaatGever?.ondernemingsnummer?.nummer
		},
		taker: {
			name: info.mandaatNemer?.naam,
			companyNumber: info.mandaatNemer?.ondernemingsnummer?.nummer
		},
		mandates: (info.mandaten || []).map((mandate) => {
			return {
				id: mandate.id,
				product: mandate.product.id,
				name: mandate.typeNaam,
				accessLevel: mandate.toegangsniveau.naam,
				status: mandate.status.naam,
				isActive: mandate.status.isActief
			};
		})
	};
};
