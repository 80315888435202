import {
	EntityStoreAssets,
	StoreFlowAssets,
	createEntityAdapterStoreAssets,
	createStoreAssets
} from '@studiohyperdrive/ngx-store';

import { StoreSlice } from '@vlaio/shared/store';
import { PageEntity, PageNavDataEntity } from '@vlaio/shared/types';

export interface PageStore extends StoreFlowAssets {
	navigation: EntityStoreAssets<PageNavDataEntity>;
	pages: EntityStoreAssets<PageEntity>;
}

export const { actions, reducers, selectors } = createStoreAssets<PageStore>(StoreSlice.Pages, [
	{
		subSlice: 'navigation',
		generator: createEntityAdapterStoreAssets<PageNavDataEntity>
	},
	{
		subSlice: 'pages',
		generator: createEntityAdapterStoreAssets<PageEntity>
	}
]);
