import { AuthenticationFailedTypes } from '@vlaio/shared/authentication';

export const getAuthFailType = (queryParams: Record<string, string>): AuthenticationFailedTypes | undefined => {
	const { loginThresholdExceeded, remoteAuthSuccess } = queryParams;
	const invalidClaim = queryParams['context[invalid_claim][acr]'];
	let failType: AuthenticationFailedTypes | undefined;

	if (remoteAuthSuccess === 'false') {
		failType = AuthenticationFailedTypes.Failed;
	}

	if (loginThresholdExceeded === 'true') {
		failType = AuthenticationFailedTypes.ThresholdExceeded;
	}

	if (invalidClaim) {
		failType = AuthenticationFailedTypes.Invalid;
	}

	return failType;
};
