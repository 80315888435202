import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { VlaioHttpClientService, VlaioSingleCallService } from '@vlaio/shared/core';
import { ELoketEndpoints } from '@vlaio/shared/endpoints';
import { VlaioResult } from '@vlaio/shared/types';
import { environment } from 'environments';

import {
	AnonymousUserSessionEntity,
	UserContextResultEntity,
	UserContextEntity,
	UserContextKey,
	UserSessionEntity,
	UserSessionEntityResult
} from '../interfaces';
import { convertUserSession } from '../utils';

@Injectable()
export class UserApiService {
	constructor(
		private readonly httpClient: VlaioHttpClientService,
		private readonly singleCallService: VlaioSingleCallService
	) {
		singleCallService.register('user-context', this.getUserContextCall.bind(this));
	}

	/**
	 * Get information on the user and their session
	 */
	public getUserSession(): Observable<UserSessionEntity | AnonymousUserSessionEntity> {
		return this.httpClient.get<UserSessionEntityResult>(ELoketEndpoints.User.UserSession(), {}, true).pipe(
			map((session) => {
				// Iben: Return only the features if we're an anonymous user
				if (session['@type'] === 'AnoniemeGebruiker') {
					return { features: session.voorzieningen };
				}
				// Iben: Convert the user
				return convertUserSession(session);
			})
		);
	}

	/**
	 * Switch company of user
	 */
	public switchCompany(): Observable<void> {
		return this.httpClient.get('vlaio/switch', {}, true);
	}

	/**
	 * Set the user context for a user
	 *
	 * @param key - The key for the user context
	 * @param waarde - The value we want to save in the user context
	 * @param geldigheid - The amount of time the value should be set in the user context
	 */
	public setUserContext(key: UserContextKey, waarde: unknown, geldigheid: number) {
		// Iben: Set the context of the spotlight cookie
		return this.httpClient.put(ELoketEndpoints.User.UserContext(key), { waarde, geldigheid }).pipe(
			map(() => waarde),
			// Iben: Ignore the error as it shouldn't stop users in their flow
			catchError(() => of())
		);
	}

	/**
	 * Returns the user context for a specific key. If no key is provided, it will return all user contexts.
	 *
	 * @template ContextType - The type of the `value` in the UserContextEntity
	 */
	public getUserContext<ContextType = unknown>(): Observable<UserContextEntity<ContextType>[]> {
		return this.singleCallService.call(`user-context`);
	}

	/**
	 * Delete the user context for a specific key
	 *
	 * @param key - The key for which you get the data
	 */
	public deleteUserContext(key: UserContextKey): Observable<void> {
		return this.httpClient.delete<void>(ELoketEndpoints.User.UserContext(key), {}, true).pipe(
			// Iben: Ignore the error as it shouldn't stop users in their flow
			catchError(() => of(undefined))
		);
	}

	public setSasContext(companyNumber: string): Observable<void> {
		const payload = {
			doelgroep: 'EA', // "Economische actoren".
			organisatie: companyNumber,
			brontoepassing: environment.sas.sourceUuid,
			doeltoepassing: environment.sas.targetUuid
		};

		return this.httpClient.directPost(environment.sas.url, payload);
	}

	/**
	 * Fetch the user contexts from the backend.
	 *
	 * @returns The collection of user contexts
	 */
	private getUserContextCall<ContextType = unknown>(): Observable<UserContextEntity<ContextType>[]> {
		return this.httpClient
			.get<VlaioResult<UserContextResultEntity>>(ELoketEndpoints.User.UserContext(), {}, true)
			.pipe(
				map(
					// Wouter: Map the result to a frontend friendly format
					(value) =>
						value.elementen.map((context) => ({
							name: context.naam,
							value: context.waarde as ContextType,
							expirationTime: context.vervaltijdstip
						})) satisfies UserContextEntity<ContextType>[]
				),
				// Iben: Ignore the error as it shouldn't stop users in their flow
				catchError(() => of([] satisfies UserContextEntity<ContextType>[]))
			);
	}
}
