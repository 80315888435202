import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxI18nModule } from '@studiohyperdrive/ngx-i18n';

import { GtagClickDirective } from '@vlaio/shared/gtag';
import { DeviceModule } from '@vlaio/shared/ui/device';

@NgModule({
	imports: [CommonModule, DeviceModule, RouterModule, NgxI18nModule.forChild(), GtagClickDirective]
})
export class SharedUiNavigationModule {}
