import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgxModalAbstractComponent } from '@studiohyperdrive/ngx-inform';

import { VlaioButtonComponent } from '@vlaio/shared/ui/common';

import { ActionModalEntity } from './action-modal.types';

@Component({
	selector: 'vlaio-action-modal',
	templateUrl: './action-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [VlaioButtonComponent],
	host: { class: 'c-modal-container' }
})
export class VlaioActionModalComponent extends NgxModalAbstractComponent<'Proceed' | 'Cancel', ActionModalEntity> {}
