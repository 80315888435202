import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgxTooltipPosition } from '@studiohyperdrive/ngx-inform';

import { VlaioExternalLinkComponent } from '../external-link/external-link.component';
import { VlaioTranslatedLinkAbstractComponent } from '../shared';

/**
 * This component is used as a WebComponent for when used in translation or other string values. Because of that, all inputs are lowercased
 *
 * IMPORTANT: This component should not be used in the application itself
 */
@Component({
	selector: 'vlaio-external-translated-link',
	standalone: true,
	template: `<vlaio-external-link
		[link]="link"
		[linkStyle]="link_style"
		[tooltip]="tooltip"
		[tooltipPosition]="tooltip_position"
		[title]="title"
	>
		<ng-content></ng-content>
	</vlaio-external-link>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [VlaioExternalLinkComponent]
})
export class VlaioExternalTranslatedLinkComponent extends VlaioTranslatedLinkAbstractComponent {
	/**
	 * If provided, this will be shown as a tooltip on hover.
	 */
	@Input() public tooltip: string = '';

	/**
	 * The position of the tooltip.
	 *
	 * Default value is 'right'
	 */
	@Input() public tooltip_position: NgxTooltipPosition = 'right';
}
