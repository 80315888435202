import { Directive, HostBinding, Input } from '@angular/core';
import { get } from 'lodash';

import { CypressTags, CypressTagsPaths } from '@vlaio/cypress/shared';

@Directive({
	selector: '[cypressTag]',
	standalone: true
})
export class CypressTagDirective {
	@HostBinding('attr.data-cy') tag: string;

	/**
	 * Sets the tag for cypress
	 *
	 * @memberof CypressTagDirective
	 */
	@Input() public set cypressTag(tagPath: CypressTagsPaths) {
		// Iben: Fetch a tag based on the path and set is as an attribute
		this.tag = get(CypressTags, tagPath);
	}
}
