import {
	BaseStoreAssets,
	EntityStoreAssets,
	createBaseStoreAssets,
	createEntityAdapterStoreAssets,
	createStoreAssets
} from '@studiohyperdrive/ngx-store';

import { StoreSlice } from '@vlaio/shared/store';

import { PermitsEntity, PermitsSummaryEntity, RenewablePermitsEntity } from './interfaces';

type PermitsStoreSliceType = {
	permits: EntityStoreAssets<PermitsEntity>;
	summary: BaseStoreAssets<PermitsSummaryEntity>;
	renewablePermits: EntityStoreAssets<RenewablePermitsEntity>;
};

export const { selectors, reducers, actions } = createStoreAssets<PermitsStoreSliceType>(StoreSlice.Permits, [
	{
		subSlice: 'permits',
		generator: createEntityAdapterStoreAssets<PermitsEntity>
	},
	{
		subSlice: 'summary',
		generator: createBaseStoreAssets<PermitsSummaryEntity>
	},
	{
		subSlice: 'renewablePermits',
		generator: createEntityAdapterStoreAssets<RenewablePermitsEntity>
	}
]);
