import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BtwPipe } from '@studiohyperdrive/ngx-utils';

import { CypressTagDirective } from '@vlaio/cypress/core';

import { CompanyActivitiesByBranchRecord, CompanyEntity } from '../../../data';
import { sharedCompanyI18nKeys } from '../../../i18n';
import { CompanyActivityComponent } from '../activity/activity.component';

@Component({
	selector: 'vlaio-company-activities',
	templateUrl: './activities.component.html',
	styleUrl: './activities.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CompanyActivityComponent, CypressTagDirective, TranslateModule, BtwPipe]
})
export class CompanyActivitiesComponent {
	/**
	 * The translation keys.
	 */
	public readonly i18nKeys: typeof sharedCompanyI18nKeys.Companies = sharedCompanyI18nKeys.Companies;

	/**
	 * A record of the company activities, ordered by branches.
	 */
	@Input() public activitiesByBranch: CompanyActivitiesByBranchRecord = {};
	/**
	 * The company to display the data for.
	 */
	@Input() public company: CompanyEntity;
	/**
	 * Whether the company branch activities are being fetched.
	 */
	@Input() public loading: boolean = false;
	/**
	 * Whether an error occurred in fetching the branches' activity.
	 */
	@Input() public error: boolean = false;
	/**
	 * Whether the activities should be shown at all.
	 */
	@Input() public showEnterpriseActivities: boolean = true;
	/**
	 * Whether the activities of every branch should be shown.
	 */
	@Input() public showBranchActivities: boolean = true;
	/**
	 * Whether the title of the activities block should be shown.
	 */
	@Input() public showTitle: boolean = true;
	/**
	 * Whether the block of the `maatschappelijke zetel` should be open by default.
	 */
	@Input() public enterpriseOpen = true;

	/**
	 * Emits the branch whose activities should be fetched.
	 */
	@Output() public activitiesRequestedForBranch = new EventEmitter<string>();
}
