import { ErrorHandler, Injectable } from '@angular/core';

import { environment } from 'environments';

import { BrowserService } from './browser.service';

@Injectable()
export class ErrorService implements ErrorHandler {
	constructor(private readonly browserService: BrowserService) {}

	public handleError(error: any): void {
		// Iben: Check if we need to display an error based on the environment
		let logErrors: boolean = environment.logErrors;

		// Iben: Allow the errors to be shown if we set it in the localStorage by hand
		this.browserService.runInBrowser(() => {
			logErrors = Boolean(localStorage.getItem('logErrors')) === true;
		});

		// Iben: Log the actual error in case an error needs to be logged
		if (logErrors) {
			console.error(error);
		}
	}
}
