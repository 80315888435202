import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { NgxTooltipDirective } from '@studiohyperdrive/ngx-inform';

import { HasFeaturePipe } from '@vlaio/shared/features';

@Component({
	selector: 'vlaio-product-tag',
	template: ` @if (('RecommendationReason' | hasFeature) && description) {
			<span class="has-Tooltip" ngxTooltipPosition="above" [ngxTooltip]="description">
				<ng-content></ng-content>
			</span>
		} @else {
			<span>
				<ng-content></ng-content>
			</span>
		}`,
	styleUrl: './product-tag.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [HasFeaturePipe, NgxTooltipDirective]
})
export class ProductTagComponent {
	@HostBinding('class') private readonly rootClass = 'c-product-tag';

	@Input() public description: string;
}
