import { CompanyLinkEntity, CompanyLinkResultEntity } from '../interfaces';

export const CompanyLinkEntityMock: CompanyLinkEntity = {
	uri: 'https://www.youtube.com/channel/UCVZACzeGq540FxRMPKkYxsw',
	title: ' Iben - ZWED',
	limited: false
};

export const CompanyLinkResultEntityMock: CompanyLinkResultEntity = {
	uri: 'https://www.youtube.com/channel/UCVZACzeGq540FxRMPKkYxsw',
	titel: ' Iben - ZWED',
	beperktTotEigenOnderneming: false
};
