import { NotificationSeverity, NotificationResultEntity, Notification } from '../interfaces';

export const NotificationEntityMock: Notification = {
	title: 'test title',
	message: 'test message',
	severity: NotificationSeverity.Info,
	id: 'test id'
};

export const NotificationResultEntityMock: NotificationResultEntity = {
	naam: 'test title',
	bericht: 'test message',
	ernst: NotificationSeverity.Info,
	id: 'test id'
};
