import { Directive } from '@angular/core';

import { GtagDirectiveEventToken } from './gtag-directive.token';
import { GtagEventDirective } from './gtag-event.directive';

/**
 * A Google Analytics tracking directive that will track the click event based on the data you provide.
 */
@Directive({
	standalone: true,
	selector: '[gtagClick]',
	hostDirectives: [
		{
			directive: GtagEventDirective,
			inputs: ['gtagCategory', 'gtagType', 'gtagLabel', 'preventTracking']
		}
	],
	providers: [{ provide: GtagDirectiveEventToken, useValue: 'click' }]
})
export class GtagClickDirective {}
