import { Pipe, PipeTransform } from '@angular/core';

import { IndexedPagination } from '@vlaio/shared/types';

@Pipe({
	name: 'getPagerMax',
	standalone: true
})
export class GetPagerMaxPipe implements PipeTransform {
	/**
	 * Limits an array to a specific value
	 *
	 * @param value - pagination object
	 */
	transform(value: IndexedPagination): number {
		return Math.ceil(value.totalAmount / value.limit);
	}
}
