import { Directive, Output, EventEmitter, OnInit, OnDestroy, ElementRef } from '@angular/core';

import { BrowserService } from '@vlaio/shared/core';

/**
 * This directive is used to detect click events outside of the host element.
 */
@Directive({
	selector: '[vlaioClickAnywhere]',
	standalone: true
})
export class ClickAnywhereDirective implements OnInit, OnDestroy {
	/**
	 * This will be the event handler that is used to detect click events on the document.
	 */
	public eventHandler: () => void;

	/*
	 * This directive is used to detect click events outside of the host element.
	 * It emits a boolean value to the parent component indicating whether the click event was inside (`true`) the host element.
	 */
	@Output() public readonly handleClick: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(
		private readonly elementRef: ElementRef<HTMLElement>,
		private readonly browserService: BrowserService
	) {}

	public ngOnInit(): void {
		this.browserService.runInBrowser(({ browserWindow }) => {
			// This is needed to reference the same method in memory to remove the eventListener on Destroy.
			this.eventHandler = this.onDocumentClick.bind(this);

			browserWindow.addEventListener('click', this.eventHandler, true);
		});
	}

	public ngOnDestroy(): void {
		this.browserService.runInBrowser(({ browserWindow }) => {
			browserWindow.removeEventListener('click', this.eventHandler, true);
		});
	}

	/**
	 * This method is called when a click event is detected on the document.
	 * @param event The click event.
	 */
	private onDocumentClick(event: MouseEvent): void {
		this.elementRef.nativeElement.contains(event.target as Node)
			? this.handleClick.emit(true)
			: this.handleClick.emit(false);
	}
}
