import { CompanyAddressRecord, CompanyEntity } from '../interfaces';

/**
 * Converts a company to a record of addresses by branch number
 *
 * @param  company - The provided company
 */
export const getCompanyAddressRecord = (company: CompanyEntity): CompanyAddressRecord => {
	// Iben: If no company is provided, early exit
	if (!company) {
		return {};
	}

	// Iben: Create a record with the addresses by branch number
	return {
		[company.number]: company.registeredOffice,
		...company.branches.reduce<CompanyAddressRecord>((prev, curr) => ({ ...prev, [curr.number]: curr.address }), {})
	};
};
