import { Injectable, OnDestroy } from '@angular/core';
import { ObservableNumber } from '@studiohyperdrive/rxjs-utils';
import { BehaviorSubject, Subject } from 'rxjs';

import { BrowserService } from '@vlaio/shared/core';

@Injectable({
	providedIn: 'root'
})
export class ScreenSizeService implements OnDestroy {
	private destroyed$ = new Subject();
	private width$ = new BehaviorSubject<number>(0);
	private height$ = new BehaviorSubject<number>(0);

	public get width(): ObservableNumber {
		return this.width$.asObservable();
	}
	public get height(): ObservableNumber {
		return this.height$.asObservable();
	}

	constructor(private readonly browserService: BrowserService) {
		this.browserService.runInBrowser(({ browserWindow }) => {
			browserWindow.addEventListener('resize', this.onWindowResize.bind(this));

			this.width$.next(browserWindow.innerWidth);
			this.height$.next(browserWindow.innerHeight);
		});
	}

	private onWindowResize(event: any): void {
		this.width$.next(event.target.window.innerWidth);
		this.height$.next(event.target.window.innerHeight);
	}

	// Lifecycle Methods
	// ------------------------------------------------------------------------- /
	public ngOnDestroy(): void {
		this.browserService.runInBrowser(({ browserWindow }) => {
			browserWindow.removeEventListener('resize', this.onWindowResize);

			this.destroyed$.next(true);
			this.destroyed$.complete();
		});
	}
}
