import { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

import { VlaioResult } from '@vlaio/shared/types';

/**
 * Pipeable operator to convert a VlaioResult to an array of a VlaioInterface
 *
 * @export
 * @template VlaioId - Id of the VlaioResult
 * @template VlaioResultInterface - The interface of the VlaioResult
 * @template VlaioInterface - The interface of the result
 * @param convertFunction - The convert function
 */
export function convertVlaioResult<VlaioId, VlaioResultInterface, VlaioInterface>(
	convertFunction: Function
): OperatorFunction<VlaioResult<VlaioResultInterface, VlaioId>, VlaioInterface[]> {
	return map<VlaioResult<VlaioResultInterface, VlaioId>, VlaioInterface[]>(({ elementen }) => {
		return elementen.map((item) => convertFunction(item));
	});
}
