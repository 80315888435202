import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { StoreSlice } from '@vlaio/shared/store';

import { reducers } from './forms-data.store';
import { ZipCodeApiService, ZipCodeService } from './services';

@NgModule({
	providers: [ZipCodeApiService, ZipCodeService],
	imports: [StoreModule.forFeature(StoreSlice.Forms, reducers)]
})
export class SharedUiFormsDataModule {}
