import { marker } from '@ngneat/transloco-keys-manager/marker';

import { sharedI18nKeys } from '@vlaio/shared/i18n';

export const sharedCompanyI18nKeys = {
	...sharedI18nKeys,
	Companies: {
		Actions: {
			Search: marker('companies.actions.search')
		},
		Activities: {
			Activities: marker('companies.activities.activities'),
			Kind: marker('companies.activities.kind'),
			NaceBel: marker('companies.activities.nacebel'),
			Activity: marker('companies.activities.activity'),
			Type: marker('companies.activities.type'),
			NoActivities: marker('companies.activities.no-activities'),
			Title: marker('companies.activities.title')
		},
		Branches: {
			Branch: marker('companies.branches.branch'),
			Branches: marker('companies.branches.branches'),
			Address: marker('companies.branches.address'),
			Error: marker('companies.branches.error')
		},
		Info: {
			Public: marker('companies.info.public'),
			CheckYourData: marker('companies.info.check-your-data'),
			ChangeYourData: marker('companies.info.change-your-data'),
			ChangeYourCase: marker('companies.info.change-your-case'),
			Form: marker('companies.info.form'),
			LegalStatus: marker('companies.info.legal-status'),
			StartDate: marker('companies.info.start-date'),
			RegisteredOffice: marker('companies.info.registered-office'),
			RegisteredName: marker('companies.info.registered-name'),
			Number: marker('companies.info.number'),
			UserCompany: marker('companies.info.user-company'),
			CompanyName: marker('companies.info.company-name'),
			Intro: marker('companies.info.intro')
		},
		SearchCompany: {
			Placeholder: marker('companies.search-company.placeholder'),
			ClearButton: marker('companies.search-company.clear-button'),
			SearchButton: {
				Title: marker('companies.search-company.search-button.title'),
				Text: marker('companies.search-company.search-button.text')
			},
			Error: marker('companies.search-company.error')
		}
	}
};
