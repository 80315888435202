import { Component, Input } from '@angular/core';
import { NgxTooltipDirective } from '@studiohyperdrive/ngx-inform';

import { VlaioIconComponent } from '../icon/icon.component';

/**
 * A component that displays a text with a tooltip. The tooltip is displayed when hovering over the text.
 * CSS properties that are `inherit` by default can be applies to the host, eg. `font-style: italic`.
 */
@Component({
	selector: 'vlaio-tooltip-text',
	template: `<p class="u-outline" [ngxTooltip]="tooltip">
		<ng-content />
		<vlaio-icon class="primary-color" icon="fa-circle-info" />
	</p>`,
	styleUrl: './tooltip-text.component.scss',
	standalone: true,
	imports: [VlaioIconComponent, NgxTooltipDirective]
})
export class VlaioTooltipTextComponent {
	/**
	 * The text to display in the tooltip.
	 */
	@Input({ required: true }) public tooltip: string;
}
