import { NaceBelEntity, NacebelResultEntity } from '../interfaces';

export const convertNaceBelResultToNacebel = (activity: NacebelResultEntity): NaceBelEntity => {
	let subActivities: NaceBelEntity[];

	if (activity.subcodes) {
		subActivities = activity.subcodes.map((subActivity) => convertNaceBelResultToNacebel(subActivity));
	}

	return {
		level: activity.niveau,
		code: activity.code,
		id: activity.code,
		label: activity.label,
		selectable: activity.selecteerbaar,
		...(activity.subcodes && { subcodes: subActivities })
	};
};
