import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { VlaioHttpClientService } from '@vlaio/shared/core';
import { ELoketEndpoints } from '@vlaio/shared/endpoints';

@Injectable()
export class TrackingApiService {
	constructor(private readonly http: VlaioHttpClientService) {}

	/**
	 * Track an event in the backend
	 *
	 * @param naam - Name of the event
	 */
	trackEvent(naam: string): Observable<void> {
		return this.http.post(ELoketEndpoints.Tracking.TrackEvent(), { naam });
	}
}
