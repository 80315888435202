import { Injectable } from '@angular/core';
import { ObservableArray } from '@studiohyperdrive/rxjs-utils';
import { merge } from '@studiohyperdrive/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { VlaioHttpClientService } from '@vlaio/shared/core';
import { ELoketEndpoints } from '@vlaio/shared/endpoints';
import {
	PageEntity,
	PageNavItemEntity,
	PageNavItemResultEntity,
	PageResultEntity,
	Pages,
	ParentPageInfoEntity,
	SinglePageEntity,
	VlaioResult
} from '@vlaio/shared/types';
import { convertOEmbedToIFrame } from '@vlaio/shared/utils';

import { convertPageNavItem } from '../utils';

@Injectable()
export class PageApiService {
	constructor(private readonly http: VlaioHttpClientService) {}

	/**
	 * Returns an array of navigation items for the a page
	 *
	 * @param page - ID of the page
	 */
	public getPageNavItems(page: Pages): ObservableArray<PageNavItemEntity> {
		return this.http
			.get<VlaioResult<PageNavItemResultEntity>>(ELoketEndpoints.ContentPages.GetPageNavItems(page))
			.pipe(map(({ elementen }) => (elementen || []).map((item) => convertPageNavItem(item))));
	}

	/**
	 * Return the page with title and text
	 *
	 * @param page - Resource id of the page
	 */
	public getPage(page: string, parentId: Pages, parent?: ParentPageInfoEntity): Observable<PageEntity> {
		return this.http.directGet<PageResultEntity>(page).pipe(
			map((result) => {
				return merge<PageEntity>(
					{
						title: result.titel,
						text: convertOEmbedToIFrame(result.tekst),
						id: result['@id'],
						parentId
					},
					['parent', parent]
				);
			})
		);
	}

	/**
	 * Fetch a content page from the backend.
	 *
	 * @param page - The slug to fetch the correct page.
	 */
	public getSinglePage(page: string): Observable<SinglePageEntity> {
		return this.http.get<PageResultEntity>(ELoketEndpoints.ContentPages.SinglePage(page)).pipe(
			map((result) => {
				return {
					title: result.titel,
					text: convertOEmbedToIFrame(result.tekst),
					id: result['@id']
				};
			})
		);
	}
}
