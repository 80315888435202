import { CdkMenuGroup, CdkMenu, CdkMenuTrigger, CdkMenuItem } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { Component, HostBinding, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxI18nRootService } from '@studiohyperdrive/ngx-i18n';
import { ObservableString } from '@studiohyperdrive/rxjs-utils';

import { Language } from '@vlaio/shared/types';
import { environment } from 'environments';

@Component({
	selector: 'language-switcher',
	standalone: true,
	templateUrl: './language-switcher.component.html',
	styleUrl: './language-switcher.component.scss',
	imports: [CommonModule, RouterModule, CdkMenuItem, CdkMenuTrigger, CdkMenu, CdkMenuGroup]
})
export class LanguageSwitcherComponent {
	/**
	 * Hides the component in case there's only one language available
	 */
	@HostBinding('class.is-hidden') private readonly isHidden: boolean = environment.languages.length === 1;

	/**
	 * The language service
	 */
	private readonly i18nRootService: NgxI18nRootService = inject(NgxI18nRootService);

	/**
	 * The list of available languages
	 */
	public readonly languages: Language[] = environment.languages as Language[];

	/**
	 *  The currently set language
	 */
	public readonly currentLanguage$: ObservableString = this.i18nRootService.currentLanguage$;

	/**
	 * Sets the chosen language
	 * @param language - The chosen language
	 */
	public setLanguage(language: Language): void {
		this.i18nRootService.setCurrentLanguage(language);
	}
}
