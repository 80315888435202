/**
 * Go from an enum value to a human readable string.
 *
 * @param value The string enum value.
 * @returns The same value with an uppercase and spaces instead of underscores
 */
export const transformEnumValue = (value: string): string => {
	// Iben: If no value was provided or the value wasn't a string we return an empty string
	if (!value || !(typeof value === 'string')) {
		return '';
	}

	// Iben: Get rid of all potential underscores
	const result = value.replaceAll('_', ' ');

	// Iben: Uppercase the first letter
	return `${result.charAt(0).toUpperCase()}${result.slice(1).toLowerCase()}`;
};
