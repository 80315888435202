import { Injectable } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { BehaviorSubject, combineLatest, from, throwError } from 'rxjs';
import { catchError, filter, map, startWith, tap } from 'rxjs/operators';

import { AbstractStatusProviderService } from '../abstracts';
import { AppStatusEntity } from '../interfaces';

import { BrowserService } from './browser.service';

@Injectable()
export class StatusService {
	/**
	 * The key we use to save the app status errors in
	 */
	private readonly appStatusErrorKey = 'VLAIO-APP-STATUS-ERROR';

	/**
	 * The subject to hold the status of the application
	 */
	private readonly statusSubject$ = new BehaviorSubject<AppStatusEntity>({
		isActive: true
	});

	/**
	 * Current status of the application
	 */
	public readonly status$: ObservableBoolean = this.statusSubject$
		.asObservable()
		.pipe(map(({ isActive }) => isActive));

	/**
	 * Whether or not a new update of the application is available
	 */
	public readonly updateAvailable$: ObservableBoolean = this.swUpdate.versionUpdates.pipe(
		filter<VersionEvent>((event) => event.type === 'VERSION_READY'),
		map(Boolean)
	);

	/**
	 * Whether the service worker has produced an error
	 */
	public readonly serviceWorkerErrorEvent$: ObservableBoolean = combineLatest([
		this.swUpdate.unrecoverable.pipe(map(Boolean), startWith(false)),
		this.swUpdate.versionUpdates.pipe(
			filter<VersionEvent>((event) => event.type === 'VERSION_INSTALLATION_FAILED'),
			map(Boolean),
			startWith(false)
		)
	]).pipe(
		map<[boolean, boolean], boolean>(
			([unrecoverableState, installationFailed]) => unrecoverableState || installationFailed
		)
	);

	constructor(
		private readonly statusProviderService: AbstractStatusProviderService,
		private readonly swUpdate: SwUpdate,
		private readonly browserService: BrowserService
	) {}

	/**
	 * Updates the application to its latest version
	 */
	public updateApplication(): ObservableBoolean {
		return from(this.swUpdate.activateUpdate()) as ObservableBoolean;
	}

	/**
	 * Get the current status of the application
	 */
	public getAppStatus(onError?: (err: string) => void) {
		// Iben: Check if there was an error in the previous app status call
		this.browserService.runInBrowser(() => {
			const error = localStorage.getItem(this.appStatusErrorKey);

			// Iben: If there was an error, we run the error callback and remove the item
			if (error) {
				onError(error);

				localStorage.removeItem(this.appStatusErrorKey);
			}
		});

		return this.statusProviderService.getApplicationStatus().pipe(
			tap((result) => {
				// Iben: If the call succeeds, the application is available
				this.setStatus(result);
			}),
			catchError((err: Error) => {
				// Iben: If the call fails, the application is unavailable
				this.setStatus({
					isActive: false
				});

				// Iben: Set the error in the local storage, so we can track it next time the application loads
				this.browserService.runInBrowser(() => {
					localStorage.setItem(this.appStatusErrorKey, err.message);
				});

				return throwError(err);
			})
		);
	}

	/**
	 * Set the current status of the application
	 *
	 * @param status - The status of the application
	 */
	public setStatus(status: AppStatusEntity) {
		this.statusSubject$.next(status);
	}

	/**
	 * Returns the status message
	 */
	public get statusMessage(): string {
		return this.statusSubject$.getValue().message;
	}
}
