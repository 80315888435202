import { Injectable } from '@angular/core';
import { NgxStorageService } from '@studiohyperdrive/ngx-utils';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';

@Injectable({ providedIn: 'root' })
export class NavigationBarService {
	constructor(private readonly storageService: NgxStorageService) {}

	/**
	 * Whether or not the navigation bar on e-loket is open or closed
	 */
	public readonly eLoketNavigationOpen$: ObservableBoolean =
		this.storageService.localStorage.getItemObservable('navOpen');

	/**
	 * Toggle whether or not the navigation bar on e-loket is open or not
	 *
	 * @param isOpen - Optional isOpen if we want to set a specific value, else we toggle the current value
	 */
	public toggleELoketNavigationBar(isOpen?: boolean): void {
		// Iben: Toggle the current value or use the provided value
		const value = isOpen === undefined ? !this.storageService.localStorage.getItem('navOpen') : isOpen;

		// Iben: Update the store
		this.storageService.localStorage.setItem('navOpen', value);
	}
}
