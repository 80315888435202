import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgxTooltipDirective } from '@studiohyperdrive/ngx-inform';
import { NgxAbstractTableCellDirective } from '@studiohyperdrive/ngx-table';
import { NgxTourItemDirective } from '@studiohyperdrive/ngx-tour';
import { HasObserversPipe } from '@studiohyperdrive/ngx-utils';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { CypressTagsPaths } from '@vlaio/cypress/shared';

import { VlaioActionCellEvent } from '../../../types';
import { DisclaimerTypes } from '../../disclaimer';
import { VlaioIconComponent } from '../../icon/icon.component';

@Component({
	selector: 'vlaio-action-cell',
	providers: [
		{
			provide: NgxAbstractTableCellDirective,
			useExisting: VlaioActionTableCellComponent
		}
	],
	templateUrl: './action-cell.component.html',
	styleUrl: './action-cell.component.scss',
	standalone: true,
	imports: [
		NgClass,
		CypressTagDirective,
		VlaioIconComponent,
		NgTemplateOutlet,
		HasObserversPipe,
		NgxTourItemDirective,
		NgxTooltipDirective
	]
})
export class VlaioActionTableCellComponent extends NgxAbstractTableCellDirective {
	/**
	 * A template for the header of the cell
	 */
	@ViewChild('headerTmpl', { static: false })
	public headerTemplate: TemplateRef<any>;

	/**
	 * A template for the body of the cell
	 */
	@ViewChild('defaultCellTmpl', { static: false })
	public cellTemplate: TemplateRef<any>;

	/**
	 * The template the render in the cell, if provided
	 */
	@ContentChild('cellTmpl', { static: false })
	public customCellTemplate: TemplateRef<any>;

	/**
	 * @inheritDoc
	 */
	public readonly cellClass: string = 'vlaio-action-cell';

	/**
	 * The icon we wish to show in the cell
	 */
	@Input() public icon: string;

	/**
	 * The title we wish to show in the tooltip of the button
	 */
	@Input() public cellTitle: string;

	/**
	 * An optional icon we can use for the header
	 */
	@Input() public headerIcon: string;

	/**
	 * An optional title for the header button
	 */
	@Input() public headerTitle: string;

	/**
	 * A record to show which button is disabled, by default false
	 */
	@Input() public cellDisabled: Record<string, boolean>;

	/**
	 * The key we use to fetch the disabled status out of the record, by default 'id'
	 */
	@Input() public cellDisabledKey: string = 'id';

	/**
	 * The disabled state of the header
	 */
	@Input() public headerDisabled: boolean;

	/**
	 * The cypress tag for the header button
	 */
	@Input() public headerCypressTag: CypressTagsPaths;

	/**
	 * The cypress tag for the cell button
	 */
	@Input() public cellCypressTag: CypressTagsPaths;

	/**
	 * The tour anchor for the header button
	 */
	@Input() public headerTourItem: string;

	/**
	 * The tour anchor for the cell button
	 */
	@Input() public cellTourItem: string;

	/**
	 * The color style we wish to use for the icon
	 */
	@Input({
		transform: (styleClass: string) => {
			// eslint-disable-next-line @angular-eslint/no-input-rename
			return `is-${styleClass}`;
		}
	})
	public iconStyle: `${DisclaimerTypes}` = DisclaimerTypes.Info;

	/**
	 * For WCAG purposes, we need to provide a description for a modal.
	 * This item triggers the modal, so this should describe it.
	 */
	@Input() public descriptionId: string;

	/**
	 * Whether the header button was clicked. Adding an emitter here will render the header button
	 */
	@Output()
	public readonly headerClicked: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * Whether an individual button was clicked. Emits the row and the index
	 */
	@Output() public readonly cellClicked: EventEmitter<VlaioActionCellEvent> =
		new EventEmitter<VlaioActionCellEvent>();

	/**
	 * Emits the cell clicked event
	 */
	public clickCell(actionCellEvent: VlaioActionCellEvent, event: Event) {
		// Iben: Prevent the table row from opening
		event.stopPropagation();

		// Iben: Emit the data
		this.cellClicked.emit(actionCellEvent);
	}
}
