/**
 * parseLocationString
 *
 * The parseLocationString function will check if the address is provided and then parse it into a string;
 *
 * @param address
 * @returns string
 */
export function parseLocationString(address: {
	street?: string;
	number?: string;
	zip?: string;
	municipality?: string;
	box?: string;
	country?: string;
}): string {
	if (!address) {
		return;
	}

	return `${address.street} ${address.number}${address.box ? address.box : ''}, ${address.zip} ${
		address.municipality
	}`;
}
