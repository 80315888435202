<!-- Wouter: The error is checked first to prevent further loading and content rendering in case of an error -->
@if (!error) {
	@if (!data.length && !loading && !error) {
		@if (emptyTemplate) {
			<ng-template [ngTemplateOutlet]="emptyTemplate"></ng-template>
		} @else if (resetFilters | hasObservers) {
			<vlaio-empty-search-result (searchWithoutFiltersClicked)="resetFilters.emit()" />
		} @else {
			<vlaio-empty-search-result />
		}
	}
	@if ((data.length || loading) && tableTemplate) {
		<ng-template
			*mediaQuery="mediaQueryOverride ? mediaQueryMin[mediaQueryOverride] : mediaQueryMin.Desktop"
			[ngTemplateOutlet]="tableTemplate"
			[ngTemplateOutletContext]="{ $implicit: data, loading: loading && !paging, error: error }"
		>
		</ng-template>
	}
	@if ((data.length || loading) && listItemTemplate) {
		<ng-container *mediaQuery="mediaQueryOverride ? mediaQueryMax[mediaQueryOverride] : mediaQueryMax.Desktop">
			<ng-container *displayContent="{ loading: loading && !paging, error }">
				@for (item of data; track item; let index = $index) {
					@if (item) {
						<div
							class="c-vlaio-table-item u-margin-top"
							tabindex="0"
							inViewport
							(inViewportAction)="inViewportAction.emit({ item, isVisible: $event.visible })"
						>
							<ng-template
								[ngTemplateOutlet]="listItemTemplate"
								[ngTemplateOutletContext]="{ $implicit: item, index }"
							>
							</ng-template>
						</div>
					}
				}
			</ng-container>
		</ng-container>
	}
} @else {
	<vlaio-empty-search-result type="error" />
}

@if (paging) {
	<vlaio-button
		*displayContent="{ loading }"
		class="u-margin-top"
		[title]="i18nKeys.Actions.LoadMore | translate"
		(handleClick)="loadMore.emit()"
	>
		{{ i18nKeys.Actions.LoadMore | translate }}
	</vlaio-button>
}
