import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, tap } from 'rxjs/operators';

import { I18nKeys } from '../../../i18n';

@Component({
	selector: 'vlaio-nacebel-search',
	templateUrl: './nacebel-search.component.html',
	styleUrls: ['./nacebel-search.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ReactiveFormsModule, TranslateModule]
})
export class NacebelSearchComponent implements OnInit {
	public form: FormGroup = new FormGroup({
		search: new FormControl('')
	});

	public readonly i18nKeys = I18nKeys;

	@Output() public searchValue = new EventEmitter();

	ngOnInit(): void {
		this.form.valueChanges
			.pipe(
				debounceTime(300),
				tap(() => {
					this.searchValue.emit(this.form.value.search);
				})
			)
			.subscribe();
	}
}
