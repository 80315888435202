<vlaio-dropdown-search
	[items]="products$ | async"
	[loading]="loading || (loading$ | async)"
	[error]="error$ | async"
	[errorLabel]="i18nKeys.Products.Error | translate"
	[placeholder]="i18nKeys.Products.Actions.Search | translate"
	[emptyLabel]="i18nKeys.Products.Empty | translate"
	[showSelected]="true"
	[selectedMapper]="selectedProductMapper"
	[formControl]="form"
	[cypressTag]="'Offers.Search.Input'"
	(searchValueChanged)="searchProducts($event)"
	(searchInitiated)="setInitialLoading()"
>
	<ng-template #listItemTmpl let-item>
		<span>{{ item.name }}</span>
	</ng-template>
</vlaio-dropdown-search>
