export enum EnvironmentType {
	LOCAL = 'local',
	DEV = 'dev',
	IOM = 'iom',
	TEST = 'test',
	ACCEPTANCE = 'acceptance',
	PRODUCTION = 'production',
	BACKEND = 'backend'
}

export type LoketType = 'eLoket';

export const AvailableLanguages = ['nl', 'en', 'fr'] as const;
