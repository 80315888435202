import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { StoreSlice } from '@vlaio/shared/store';

import { reducers } from './nacebel.store';
import { NacebelApiService, NacebelService } from './services';

@NgModule({
	imports: [StoreModule.forFeature(StoreSlice.NaceBel, reducers)],
	providers: [NacebelApiService, NacebelService]
})
export class NacebelDataModule {}
