import { StoreModule } from '@ngrx/store';

import { createDataProviderFromModule } from '@vlaio/shared/core';
import { StoreSlice } from '@vlaio/shared/store';
import { LeafletService } from '@vlaio/shared/types';

import { reducers } from './company.store';
import { CompanyApiService, CompanyLeafletService, CompanyService } from './services';

export const provideCompanyData = createDataProviderFromModule({
	providers: [
		CompanyService,
		CompanyApiService,
		CompanyLeafletService,
		{ provide: LeafletService, useClass: CompanyLeafletService }
	],
	imports: [StoreModule.forFeature(StoreSlice.Company, reducers)]
});
