import { NacebelSearchEntity, NacebelSearchResultEntity } from '../interfaces';

export const convertNaceBelSearchResultToNacebelSearch = (
	searchResult: NacebelSearchResultEntity
): NacebelSearchEntity => {
	return {
		'@type': searchResult['@type'],
		code: searchResult.code,
		level: searchResult.niveau,
		label: searchResult.label
	};
};
