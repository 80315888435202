import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { ContactItemsEntity } from '@vlaio/shared/types';

import { I18nKeys } from '../../i18n';
import { VlaioAddressComponent } from '../address/address.component';
import { VlaioContactLinkComponent } from '../links';

@Component({
	selector: 'vlaio-partner-detail',
	standalone: true,
	templateUrl: './partner-detail.component.html',
	styleUrl: './partner-detail.component.scss',
	imports: [VlaioContactLinkComponent, VlaioAddressComponent, CypressTagDirective, TranslateModule]
})
export class VlaioPartnerDetailComponent {
	/**
	 * The translation keys.
	 */
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;

	/**
	 * The name of the partner
	 */
	@Input({ required: true }) public partner: string;

	/**
	 * A list of contact items
	 */
	@Input() public contactItems: ContactItemsEntity = {};

	/**
	 * The mail data we wish to provide to e-mail addresses in the contact items
	 */
	@Input() mailData: string;
}
