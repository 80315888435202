import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { StoreSlice } from '@vlaio/shared/store';

import { reducers } from './partners.store';
import { PartnersApiService, PartnersService } from './services';

@NgModule({
	imports: [StoreModule.forFeature(StoreSlice.Partners, reducers)],
	providers: [PartnersApiService, PartnersService]
})
export class PartnersDataModule {}
