import { HttpBackend } from '@angular/common/http';
import { NgxI18nMultiTranslationHttpLoader } from '@studiohyperdrive/ngx-i18n';

export function ELoketTranslationLoader(http: HttpBackend) {
	return new NgxI18nMultiTranslationHttpLoader(http, [
		'./assets/i18n/e-loket/footer/',
		'./assets/i18n/shared/i18n/',
		'./assets/i18n/shared/redirects/',
		'./assets/i18n/shared/ui/common/',
		'./assets/i18n/shared/user/'
	]);
}
