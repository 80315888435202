import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { StoreSlice } from '@vlaio/shared/store';

import { reducers } from './page.store';
import { PageApiService, PageService } from './services';

@NgModule({
	imports: [StoreModule.forFeature(StoreSlice.Pages, reducers)],
	providers: [PageApiService, PageService]
})
export class PagesDataModule {}
