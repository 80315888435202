@if (data.showIcon) {
	<vlaio-icon
		[icon]="icon"
		[ngClass]="{
			'ui-color-red': data.type === 'negative',
			'ui-color-blue': data.type === 'info',
			'ui-color-orange': data.type === 'warning',
			'primary-color': data.type === 'positive'
		}"
	/>
}

<div class="text-wrapper">
	<p [innerHTML]="data.text"></p>

	<p class="remove-text">{{ i18nKeys.Notification.ClickToDelete | translate }}</p>
</div>
