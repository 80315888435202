import { TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { StringRecord } from '@vlaio/shared/types';

import { VlaioIconComponent } from '../../icon/icon.component';
import { VlaioLinkAbstractComponent } from '../shared';

// Iben: Move this to the types if we ever need to export this, until then, keep it here
type VlaioContactType = 'mail' | 'tel' | 'web';

/**
 * This component requires a contactType, which is either `mail`, `tel`, or 'web'. This component will fire
 * an action to open the mail client or the phone app with the provided link.
 */
@Component({
	selector: 'vlaio-contact-link',
	template: ` <a
		class="u-outline u-link__underline"
		href="{{ referenceRecord[contactType] }}{{ link }}{{ mailData || '' }}"
		rel="noopener noreferrer"
		[target]="contactType === 'web' ? '_blank' : '_self'"
		[id]="linkId"
		[title]="title || (i18nKeys.Links.Contact[contactType | titlecase] | translate: { link })"
	>
		<vlaio-icon [icon]="iconRecord[contactType]" />

		<span>{{ link }}</span>

		@if (contactType === 'web') {
			<vlaio-icon class="open-externally" icon="fa-sharp fa-up-right-from-square" />
		}
	</a>`,
	styleUrls: ['../shared/shared-link.style.scss', './contact-link.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [VlaioIconComponent, TranslateModule, TitleCasePipe]
})
export class VlaioContactLinkComponent extends VlaioLinkAbstractComponent {
	/**
	 * A record with the icons of the possible contact methods
	 */
	public readonly iconRecord: StringRecord<VlaioContactType> = {
		mail: 'fa-envelope',
		tel: 'fa-phone',
		web: 'fa-globe'
	};

	/**
	 * A record with the reference of the possible contact methods
	 */
	public readonly referenceRecord: StringRecord<VlaioContactType> = {
		mail: 'mailto:',
		tel: 'tel:',
		web: ''
	};

	/**
	 * The type of contact link we wish to render
	 */
	@Input({ required: true }) public contactType: VlaioContactType;

	/**
	 * The provided link
	 */
	@Input({ required: true }) public link: string;

	/**
	 * Extra data that is passed when contactType is set to 'mail'
	 */
	@Input() public mailData: string;
}
