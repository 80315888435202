import { marker } from '@ngneat/transloco-keys-manager/marker';

import { sharedI18nKeys } from '@vlaio/shared/i18n';

export const I18nKeys = {
	...sharedI18nKeys,
	Permits: {
		Intro: {
			Text: marker('permits.intro.text'),
			Title: marker('permits.intro.title')
		},
		Table: {
			FileName: marker('permits.table.file-name'),
			FileNumber: marker('permits.table.file-number'),
			RecentChange: marker('permits.table.recent-change'),
			OrganisationNumber: marker('permits.table.organisation-number'),
			Visibility: {
				Public: marker('permits.table.visibility.public'),
				Private: marker('permits.table.visibility.private'),
				Title: marker('permits.table.visibility.title')
			},
			Public: marker('permits.table.public'),
			Private: marker('permits.table.private'),
			isRenewable: marker('permits.table.is-renewable')
		},
		Labels: {
			Empty: marker('permits.labels.empty')
		},
		Card: {
			Product: marker('permits.card.product'),
			AssignedBy: marker('permits.card.assigned-by'),
			Address: marker('permits.card.address'),
			Description: marker('permits.card.description'),
			PermitButton: {
				Text: marker('permits.card.permit-button.text'),
				Title: marker('permits.card.permit-button.title'),
				Disabled: marker('permits.card.permit-button.disabled')
			},
			CaseButton: {
				Title: marker('permits.card.case-button.title'),
				Text: marker('permits.card.case-button.text'),
				Disabled: marker('permits.card.case-button.disabled')
			},
			RenewalButton: {
				Title: marker('permits.card.renewal-button.title'),
				Text: marker('permits.card.renewal-button.text')
			},
			Dates: {
				Start: marker('permits.card.dates.start'),
				End: marker('permits.card.dates.end'),
				Renewal: marker('permits.card.dates.renewal'),
				NoRenewal: marker('permits.card.dates.no-renewal')
			}
		}
	}
};
