import { marker } from '@ngneat/transloco-keys-manager/marker';

import { I18nKeys as AboutI18nKeys } from '@vlaio/e-loket/about';
import { sharedI18nKeys } from '@vlaio/shared/i18n';

export const I18nKeys = {
	...sharedI18nKeys,
	...AboutI18nKeys,
	Footer: {
		Svg: {
			Title: marker('footer.svg.title')
		},
		Logo: {
			Title: marker('footer.logo.title'),
			Tagline: marker('footer.logo.tagline')
		},
		Title: marker('footer.title'),
		Subtitle: marker('footer.subtitle'),
		Link: marker('footer.link'),
		Cookies: {
			Title: marker('footer.cookies.title'),
			Text: marker('footer.cookies.text')
		}
	}
};
