import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export abstract class SaveOnExitComponent implements OnDestroy {
	private readonly destroyedSubject$ = new Subject();
	public readonly destroyed$ = this.destroyedSubject$.asObservable();

	/**
	 * Return whether or not the component is dirty
	 */
	public abstract isDirty(): boolean;

	/**
	 * Return whether or not the component is valid
	 */
	public abstract isValid(): boolean;

	ngOnDestroy() {
		this.destroyedSubject$.next(undefined);
		this.destroyedSubject$.complete();
	}
}
