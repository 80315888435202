import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { map } from 'rxjs';

import { AuthenticationService } from '@vlaio/shared/authentication/auth';
import { PreventNavigationGuard } from '@vlaio/shared/types';

/**
 * Redirects the user to the authentication failed page in case the authentication has failed
 */
export const AuthenticationFailedGuard: CanActivateFn = (): ObservableBoolean => {
	// Iben: Fetch all injectables
	const authService: AuthenticationService = inject(AuthenticationService);

	return PreventNavigationGuard(authService.authenticationFailed$.pipe(map(Boolean)));
};
