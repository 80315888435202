<div>
	<div class="c-modal__header">
		<h3 class="c-modal__title">{{ data.title }}</h3>
	</div>

	<div class="c-modal__body">
		<p>
			{{ data.description }}
		</p>
	</div>

	<div class="c-modal__footer">
		<vlaio-button class="c-modal__action" [title]="data.exit" (click)="action.emit('Proceed')">
			{{ data.exit }}
		</vlaio-button>

		<vlaio-button
			class="c-modal__action"
			buttonType="RegularOutline"
			[title]="data.cancel"
			(click)="action.emit('Cancel')"
		>
			{{ data.cancel }}
		</vlaio-button>
	</div>
</div>
