import { FormControl, ValidationErrors } from '@angular/forms';
import * as validateINSZ from 'validate-insz';

/**
 * INSZValidator
 *
 * The INSZValidator will use the validate-insz npm package
 * to validate the giving input and check if it is a valid INSZ number.
 *
 * @param { FormControl } control
 * @returns ValidationErrors
 * @returns null
 */
export function INSZValidator(control: FormControl): ValidationErrors | null {
	if (!control.value) {
		return null;
	}

	const insz = control.value;

	return validateINSZ(insz) ? null : { invalidINSZ: true };
}
