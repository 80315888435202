<vlaio-table-wrapper [data]="data" [noItemStyling]="true">
	<ng-template #tableTmpl>
		<ngx-table [data]="data" [columns]="['group', 'code', 'name', 'type']">
			<ngx-table-cell column="group">
				<ng-template #headerTmpl>{{ i18nKeys.Activities.Kind | translate }}</ng-template>
				<ng-template #cellTmpl let-item>{{ item | activityGroup }}</ng-template>
			</ngx-table-cell>

			<ngx-table-cell column="code">
				<ng-template #headerTmpl>{{ i18nKeys.Activities.NaceBel | translate }}</ng-template>
			</ngx-table-cell>

			<ngx-table-cell column="name">
				<ng-template #headerTmpl>{{ i18nKeys.Activities.Activity | translate }}</ng-template>
			</ngx-table-cell>

			<ngx-table-cell column="type">
				<ng-template #headerTmpl>{{ i18nKeys.Activities.Type | translate }}</ng-template>
				<ng-template #cellTmpl let-row="row">{{ row.type }} {{ row.version }}</ng-template>
			</ngx-table-cell>
		</ngx-table>
	</ng-template>

	<ng-template #listItemTmpl let-item>
		<vlaio-detail-content>
			<section>
				<h5 class="u-margin-bottom">
					{{ item.name }}
				</h5>

				<vlaio-value-tile>
					<ng-container title>
						{{ i18nKeys.Activities.NaceBel | translate }}
					</ng-container>
					<ng-container content>
						{{ item.code }}
					</ng-container>
				</vlaio-value-tile>

				<vlaio-value-tile>
					<ng-container title>
						{{ i18nKeys.Activities.Kind | translate }}
					</ng-container>
					<ng-container content>
						{{ item.group | activityGroup }}
					</ng-container>
				</vlaio-value-tile>

				<vlaio-value-tile>
					<ng-container title>
						{{ i18nKeys.Activities.Type | translate }}
					</ng-container>
					<ng-container content> {{ item.type }} {{ item.version }} </ng-container>
				</vlaio-value-tile>
			</section>
		</vlaio-detail-content>
	</ng-template>
</vlaio-table-wrapper>
