import { Pipe, PipeTransform } from '@angular/core';

import { CompanyEntity } from '../../../data';

@Pipe({
	name: 'companyName',
	standalone: true
})
export class CompanyNamePipe implements PipeTransform {
	public transform(company: CompanyEntity): string {
		if (!company || !company.names || !company.number) {
			return;
		}

		return `${company.names.public || company.names.commercial} (${company.number})`;
	}
}
