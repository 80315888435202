import {
	PermitsResultEntity,
	PermitsSummaryResultEntity,
	RenewablePermitsResultEntity
} from '../interfaces/permits-result.interface';
import { PermitsEntity, PermitsSummaryEntity, RenewablePermitsEntity } from '../interfaces/permits.interface';

/**
 * Transform the permit from a result entity with Dutch keys
 *
 * @param permit The permit result entity
 */
export const convertPermit = (permit: PermitsResultEntity): PermitsEntity => {
	if (!permit || !Object.keys(permit).length) {
		return;
	}

	return {
		// Wouter: Since a productId could appear multiple times but a reference is unique, we use that as id.
		id: permit.Referentie,
		assignedBy: {
			name: permit.ToegekendDoor.Naam,
			organizationCode: permit.ToegekendDoor.OrganisatieCode
		},
		assignedTo: permit.ToegekendAan,
		createdAt: permit.AangemaaktOp,
		description: permit.Omschrijving,
		product: permit.ProductNaam || permit.ProductId,
		isPublic: permit.IsPubliek,
		reference: permit.Referentie,
		referenceNumber: permit.ReferentieNummer,
		referralUrl: permit.DoorverwijzingUrl,
		isNew: permit.isOngelezen,
		status: {
			endDate: permit.Status.EindDatum,
			fileNumber: permit.Status.DossierNummer,
			recentChangeDate: permit.Status.WijzigingsDatum,
			renewalDate: permit.Status.HernieuwingsDatum,
			startDate: permit.Status.StartDatum
		},
		title: permit.Titel
	};
};

/**
 * Transform the summary from the Dutch keys to a fontend friendly format.
 */
export const convertPermitSummary = (summary: PermitsSummaryResultEntity): PermitsSummaryEntity => {
	if (!summary || !Object.keys(summary).length) {
		return;
	}

	return {
		lastChangeDateLink: summary.linkNaarToelatingenGesorteerdOpLaasteWijzigingsdatum,
		newPermitsCount: summary.aantalNieuweToelatingenSindsLaasteKeerAangemeld,
		renewalLink: summary.linkNaarTeHernieuwenToelatingen,
		totalPermitsCount: summary.totaalAantalToelatingen
	};
};

/**
 * Transform the summary from the Dutch keys to a frontend friendly format.
 */
export const convertRenewablePermit = (permit: RenewablePermitsResultEntity): RenewablePermitsEntity => {
	if (!permit || !Object.keys(permit).length) {
		return;
	}

	// Iben: Omit product and add requestedBy
	const { product, ...result } = {
		...convertPermit(permit),
		requestedBy: permit.AangevraagdDoor
	};

	return result;
};
