export enum Features {
	Mandates = 'mandates',
	RecommendationReason = 'recommendation_reason',
	Pwa = 'pwa',
	EBox = 'e-box',
	Duo = 'duo',
	Dav = 'dav',
	OrganisationName = 'organisation_name',
	Sanctions = 'sanctions',
	DosisBeta = 'dosis_beta',
	VmsExecutorPersonData = 'vms_executor_person_data',
	VmsRiskLevel = 'vms_risk_profile',
	VmsSelfRegistration = 'vms_self_registration',
	Permits = 'permits',
	Partners = 'partners',
	About = 'about',
	Products = 'products',
	MyOrganisation = 'my_organisation',
	SearchOrganisations = 'search_organisations',
	Cases = 'cases',
	Questionnaire = 'questionnaire',
	UserStatus = 'user_status',
	Notifications = 'notifications',
	VlaioKBODataSearchCompany = 'vlaio_kbo_data_search_company',
	Multilingual = 'multilingual',
	Subsidies = 'subsidies',
	Enforcement = 'sanction_messages',
	// Sanctie uittreksels
	SanctionExtracts = 'sanction_certificates'
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FeatureResultEntity {}

export type FeatureKeys = keyof typeof Features;
