@if (isLoaded$ | async) {
	<vlaio-skip-links />

	<div class="c-eloket--main-content">
		<vlaio-menu [menuItems]="menuItems$ | async">
			<ng-template #itemTmpl let-item>
				{{ item | translate }}
			</ng-template>
		</vlaio-menu>
		<router-outlet id="main-content" tabindex="-1"></router-outlet>

		<!-- Needs a negative tabindex to be focusable via the <vlaio-skip-links> but not by tabbing -->
		<vlaio-footer
			class="u-outline-none"
			id="footer"
			tabindex="-1"
			[language]="language$ | async"
			[navItems]="footerNavItems$ | async"
			(cookiesClicked)="openCookiesPopup()"
		/>
	</div>
} @else {
	<vlaio-loading-page />
}
