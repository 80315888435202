import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'isNacebelOpen',
	standalone: true
})
export class isNacebelOpenPipe implements PipeTransform {
	transform(value: string, selected: Set<string>) {
		return selected.has(value);
	}
}
