import { StoreModule } from '@ngrx/store';

import { createDataProviderFromModule } from '@vlaio/shared/core';
import { StoreSlice } from '@vlaio/shared/store';

import { reducers } from './page.store';
import { PageApiService, PageService } from './services';

export const providePagesData = createDataProviderFromModule({
	imports: [StoreModule.forFeature(StoreSlice.Pages, reducers)],
	providers: [PageApiService, PageService]
});
