import { ChangeDetectionStrategy, Component } from '@angular/core';

import { VlaioExternalLinkComponent } from '../external-link/external-link.component';
import { VlaioTranslatedLinkAbstractComponent } from '../shared';

/**
 * This component is used as a WebComponent for when used in translation or other string values. Because of that, all inputs are lowercased
 *
 * IMPORTANT: This component should not be used in the application itself
 */
@Component({
	selector: 'vlaio-external-translated-link',
	standalone: true,
	template: `<vlaio-external-link [link]="link" [linkStyle]="link_style" [title]="title">
		<ng-content></ng-content>
	</vlaio-external-link>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [VlaioExternalLinkComponent]
})
export class VlaioExternalTranslatedLinkComponent extends VlaioTranslatedLinkAbstractComponent {}
