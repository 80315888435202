// equal to shared/ui/styles/base/_globals.scss
export enum ScreenSize {
	MobileSmall = 360,
	Mobile = 480,
	Tablet = 680,
	TabletWide = 800,
	Desktop = 1056,
	DesktopWide = 1140,
	DesktopWideExtended = 1312,
	DesktopUltraWide = 1600,
	Desktop2k = 2000
}
