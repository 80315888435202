export interface MandatesCountEntity {
	giverMandates: number;
	takerMandates: number;
}

export enum MandateAccessLevel {
	Maximum = 'Maximaal',
	Default = 'Uitgebreid',
	Limited = 'Beperkt',
	VeryLimited = 'Zeer beperkt'
}

export enum MandateStatus {
	New = 'Nieuw',
	Active = 'Actief',
	Stopped = 'Gestopt',
	Expired = 'Verlopen',
	Approved = 'Goedgekeurd',
	Declined = 'Geweigerd',
	Created = 'Aangemaakt',
	AwaitingApproval = 'Wachten op goedkeuring',
	ToBeApproved = 'Goed te keuren'
}
