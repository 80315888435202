import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
	selector: 'vlaio-app-base-route',
	template: '<router-outlet> </router-outlet>',
	standalone: true,
	imports: [RouterOutlet]
})
export class BaseComponent {}
