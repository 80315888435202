import { Language } from '@vlaio/shared/types';

export enum AppRoutePaths {
	Empty = '',
	ELoket = 'e-loket',
	Loket = 'loket',
	About = 'over-ons',
	Partners = 'deelnemende-partners',
	DirectedLogin = 'directed-login',
	Fallback = '**',
	Language = ':language',
	Offers = 'ons-aanbod',
	Faq = 'veelgestelde-vragen',
	Page = 'pagina',
	// Wouter: This route is slightly different from the Page route, because these are two different pages to be rendered.
	// This needs to be `slightly` different to give the impression the user sees the same component with different data.
	SinglePage = 'paginas',
	Redirects = 'fout',
	PWA = 'pwa',
	Info = 'info',
	UserStatus = 'gebruikerstatus',
	Redesign = 'nieuw'
}

export enum RouteProps {
	Slash = '/',
	Dot = '.'
}

export enum ELoketRoutePaths {
	Cases = 'aanvragen',
	Products = 'aanvraag',
	Recommendations = 'aanbevolen',
	EBox = 'ebox',
	FeedBack = 'feedback',
	Company = 'onderneming',
	Sanctions = 'sanctieregister',
	Search = 'zoeken',
	Dashboard = 'dashboard',
	Refinement = 'verfijnen',
	Mandates = 'mandaten',
	Permits = 'toelatingen',
	Notifications = 'notificaties',
	Subsidies = 'subsidieregister'
}

export enum ProductRoutePaths {
	ProductsStart = 'start',
	ProductsFilter = 'filter',
	ProductsResult = 'results'
}

export enum PagesRoutePaths {
	AboutUsPage = 'over',
	JoinPage = 'aansluiten',
	TermsPage = 'gebruiksvoorwaarden',
	PrivacyPage = 'privacy'
}

export enum CompanyRoutePaths {
	Search = 'zoeken',
	User = 'eigen'
}

export enum AboutRoutePaths {
	AboutPage = ':id'
}

export enum OffersRoutePaths {
	OffersDetail = 'product'
}

export enum RedirectsPaths {
	AuthenticationFailed = 'authenticatie',
	Maintenance = 'onderhoud',
	Offline = 'offline',
	NoResources = 'ontbrekende-onderdelen',
	DesktopOnly = 'niet-mobiel-beschikbaar'
}

export enum PartnerPaths {
	Partner = 'partner',
	About = 'over'
}

export enum InfoPaths {
	RightsManagement = 'rechtenbeheer'
}

export type RoutePaths =
	| AppRoutePaths
	| RouteProps
	| ELoketRoutePaths
	| ProductRoutePaths
	| AboutRoutePaths
	| PagesRoutePaths
	| Language
	| CompanyRoutePaths
	| RedirectsPaths
	| OffersRoutePaths;
