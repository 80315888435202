// Imports
// -------------------------------------------------------------------------- /
import { Directive, Input, HostListener } from '@angular/core';

import { ACMTargetGroups } from '@vlaio/shared/types';

import { AuthenticationService } from '../../data';

// Decorators
// -------------------------------------------------------------------------- /
@Directive({
	selector: '[vlaioUserEIDLogin]',
	standalone: true
})

// Class Definition
// -------------------------------------------------------------------------- /
export class UserEIDLoginDirective {
	@Input() public callback: string;
	@Input() public productId: string;
	@Input() public capHint: `${ACMTargetGroups}`;
	@Input() public codeHint: string;

	constructor(private readonly authService: AuthenticationService) {}

	@HostListener('click', ['$event']) onClick(e) {
		// Iben: Prevent default
		e.preventDefault();
		e.stopPropagation();

		// Iben: Redirect to login page with product callback if needed
		this.authService
			.login({
				customCallBack: this.callback,
				spotlightProducts: this.productId,
				capHint: this.capHint as ACMTargetGroups,
				codeHint: this.codeHint
			})
			.subscribe();
	}
}
